import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Roles from "utils/roles";
import AuthService from "services/AuthService";
import notification from 'utils/notification';

const PrivateRoute = ({ component = null }) => {
  const { t } = useTranslation();

  // watch access token change
  useSelector(state=> state?.authReducer)

  // verify access
  const accessToken = AuthService.getToken();
  if (!accessToken) {
    return <Navigate to="/login" />
  } else {
    const CurrentUser = AuthService.getCurrentUser()
    // check if route is restricted by role
    if (![Roles?.SUPER_ADMIN, Roles?.ADMIN, Roles?.EMPLOYEE].includes(CurrentUser?.role)) {
      AuthService?.logout()?.then(() => {
        notification.error(t(`COMMON.ACCESS_DENIED`));
      });
    }
  }

  return component;
};

export default PrivateRoute;
