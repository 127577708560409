import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import DocTemplateService from "services/DocTemplateService";
import notification from "utils/notification";
import VTabs from "components/tabs/v-tabs";
import Editor from "components/richtext-ediotor/richtext-editor";
import Modal from "components/Modal";
import { Modal as antdModal } from "antd";
import Button from "components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faFileAlt,
  faPlusCircle,
  faExclamationTriangle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import TextField from "components/form-fields/text-fields";
import setLoader from "utils/loader";

const TemplateTabContent = ({
  currentTabContent,
  setCurrentTabContent,
  pageLoader,
  setPageLoader,
}) => {
  const onEditorStateChange = useCallback(
    (textContent) => {
      setCurrentTabContent(textContent);
    },
    [setCurrentTabContent]
  );

  return (
    <>
      <Editor
        defaultValue={currentTabContent}
        onEditorStateChange={onEditorStateChange}
      />
    </>
  );
};

const DocumentTemplateContent = ({ categoryId, pageLoader, setPageLoader }) => {
  const { t } = useTranslation();
  const [currentTabKey, setCurrentTabKey] = useState(0);
  const [currentTabData, setCurrentTabData] = useState(null);
  const [currentTabContent, setCurrentTabContent] = useState("");
  const [showCreateContentModal, setShowCreateModal] = useState(false);
  const [contentTitle, setContentTitle] = useState("");
  const [templateContent, setTemplateContent] = useState([]);

  const getDocTemplateContent = useCallback(() => {
    setPageLoader(true);
    setLoader(true);
    const filterForm = {
      exteriorCategoryIds: categoryId,
    };
    DocTemplateService.getDocTemplateContent(filterForm)
      .then((res) => {
        setPageLoader(false);
        setLoader(false);
        if (res?.data?.content) {
          setTemplateContent(res?.data?.content);
          setCurrentTabData(res?.data?.content?.[`${currentTabKey}`]);
        } else if (parseInt(res.status, 0) === 204) {
          // notification.info(t(`TOASTR.API.DATA_NOT_FOUND`))
        }
      })
      .catch((e) => {
        notification.error(e);
        setPageLoader(false);
        setLoader(false);
      });
  }, [t, categoryId, setPageLoader, setCurrentTabData, currentTabKey]);

  const createDocTemplateContent = useCallback(
    (title) => {
      setPageLoader(true);
      setLoader(true);
      const body = {
        content: "",
        exteriorCategoryId: categoryId,
        isDefault: false,
        title: title,
      };
      if (!(templateContent?.length > 0)) {
        body.isDefault = true;
      }
      DocTemplateService.createDocTemplateContent(body)
        .then((res) => {
          setPageLoader(false);
          setLoader(false);
          if (parseInt(res.status, 0) === 201) {
            notification.success(
              t(`TEMPLATES_PAGE.DOCUMENT_TEMPLATE.MODEL_ADDED`)
            );
            setContentTitle("");
            setShowCreateModal(false);
            getDocTemplateContent();
          } else if (parseInt(res.status, 0) === 204) {
            notification.info(t(`TOASTR.API.DATA_NOT_FOUND`));
          }
        })
        .catch((e) => {
          notification.error(e);
          setPageLoader(false);
          setLoader(false);
        });
    },
    [
      t,
      categoryId,
      setPageLoader,
      setContentTitle,
      getDocTemplateContent,
      templateContent,
    ]
  );

  const updateDocTemplateContent = useCallback(
    (data, content = null, isDefault = null, title = null) => {
      setPageLoader(true);
      setLoader(true);
      const body = {
        exteriorCategoryId: data?.exteriorCategory?.categoryId,
        title: title !== null ? title : data?.templateName,
        content: content !== null ? content : data?.content,
        isDefault: isDefault !== null ? isDefault : data?.isDefault,
      };
      DocTemplateService.updateDocTemplateContent(
        data?.documentTemplateId,
        body
      )
        .then((res) => {
          setPageLoader(false);
          setLoader(false);
          if (parseInt(res.status, 0) === 200) {
            notification.success(
              t(`TEMPLATES_PAGE.DOCUMENT_TEMPLATE.MODEL_UPDATED`)
            );
            getDocTemplateContent();
          } else if (parseInt(res.status, 0) === 204) {
            notification.info(t(`TOASTR.API.DATA_NOT_FOUND`));
          }
        })
        .catch((e) => {
          notification.error(e);
          setPageLoader(false);
          setLoader(false);
        });
    },
    [t, setPageLoader, getDocTemplateContent]
  );

  // Delete Template Title
  const deleteTemplateTitle = (id) => {
    DocTemplateService.deleteDocTemplateContent(id)
      .then((res) => {
        if (parseInt(res.status, 0) === 204) {
          notification.error(t(`TOASTR.API.FAILED_TO_DELETE`));
        } else {
          notification.success(t(`COMMON.DELETE_SUCCESS`));
          getDocTemplateContent();
        }
      })
      .catch((e) => {
        notification.error(t(e));
      });
  };

  useEffect(() => {
    getDocTemplateContent();
  }, [getDocTemplateContent]);

  const OnTabChange = useCallback(
    (key, tabData, previousTabKey, previousTabData) => {
      setCurrentTabKey(key);
      setCurrentTabData(tabData?.contentData);
      // const previousTabContent = currentTabContent;
      setCurrentTabContent("");
      // To enable auto save on tab change, please unslash following code.
      // if(previousTabContent && previousTabData?.contentData && previousTabContent !== previousTabData?.contentData?.content) {
      //   updateDocTemplateContent(previousTabData?.contentData, previousTabContent)
      // }
    },
    [
      currentTabContent,
      setCurrentTabContent,
      setCurrentTabData,
      updateDocTemplateContent,
    ]
  );

  const { confirm } = antdModal;
  const onDeleteClick = (templateTitleId) => {
    confirm({
      title: "Are you sure delete this modal?",
      icon: (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size={"xl"}
          className="float-left mr-4 text-red-600"
        />
      ),
      okText: t(`COMMON.YES`),
      okButtonProps: {
        className:
          "font-medium text-gray-600 hover:text-primary border-gray-300 hover:border-primary rounded",
      },
      okType: "danger",
      cancelText: t(`COMMON.CANCEL`),
      cancelButtonProps: {
        className:
          "font-medium rounded text-white bg-primary border border-primary hover:border-primary",
      },
      className: "rounded",
      onOk() {
        deleteTemplateTitle(templateTitleId);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  const templatesTabs = templateContent?.map((content) => {
    return {
      tabTitle: content?.templateName,
      tabContent: (
        <TemplateTabContent
          currentTabContent={content?.content}
          setCurrentTabContent={setCurrentTabContent}
          pageLoader={pageLoader}
          setPageLoader={setPageLoader}
        />
      ),
      contentData: content,
      icon: (
        <FontAwesomeIcon
          icon={faFileAlt}
          size={"xs"}
          className={"text-white"}
        />
      ),
    };
  });

  const FormModal = () => {
    return (
      <Modal
        title={t(`COMMON.ADD`)}
        content={
          <TextField
            value={contentTitle}
            onChange={(e) => setContentTitle(e?.target?.value)}
            placeholder={t(`COMMON.ADD`)}
          />
        }
        isModalOpen={showCreateContentModal}
        setIsModalOpen={setShowCreateModal}
        onOk={() => {
          createDocTemplateContent(contentTitle);
        }}
        onCancel={() => {
          setContentTitle("");
        }}
        footer={true}
        isLoading={pageLoader}
        disabled={!contentTitle}
      />
    );
  };

  return (
    <>
      <div className="add-template-content-button-div mb-5">
        <Button
          title={t(`COMMON.ADD_NEW`)}
          icon={
            <FontAwesomeIcon
              icon={faPlusCircle}
              className={"text-white text-xl"}
            />
          }
          rounded={true}
          spaceXSmall={true}
          className="ml-auto"
          onClick={() => {
            setShowCreateModal(true);
          }}
        />
        {showCreateContentModal && FormModal()}
      </div>
      <VTabs
        tabsData={templatesTabs}
        onClickLoad={true}
        onTabChange={OnTabChange}
      />
      {templatesTabs?.length > 0 && (
        <div className="footer-template-content-button-div flex justify-center items-center space-x-3 mt-5">
          <Button
            title={t(`COMMON.SAVE`)}
            onClick={() => {
              updateDocTemplateContent(currentTabData, currentTabContent);
            }}
            isLoading={pageLoader}
            disabled={
              !currentTabContent ||
              currentTabContent === currentTabData?.content
            }
          />
          <Button
            title={t(`COMMON.SET_DEFAULT`)}
            type="outline"
            icon={
              <FontAwesomeIcon
                icon={faCircleCheck}
                className={"text-green-600 text-xl"}
              />
            }
            className={"text-[#000] border-typo-light"}
            spaceXSmall={true}
            onClick={() => {
              updateDocTemplateContent(currentTabData, null, true);
            }}
            isLoading={pageLoader}
            disabled={currentTabData?.isDefault}
          />

          <Button
            title={t(`COMMON.DELETE`)}
            type="outline"
            icon={
              <FontAwesomeIcon
                icon={faTrash}
                className={"text-red-600 text-xl"}
              />
            }
            className={"text-[#000] border-typo-light"}
            spaceXSmall={true}
            onClick={() => {
              onDeleteClick(currentTabData?.templateTitleId);
            }}
            isLoading={pageLoader}
            disabled={currentTabData?.isDefault}
          />
        </div>
      )}
    </>
  );
};
export default DocumentTemplateContent;
