import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserService from "services/UserService";
import EmployeePresentational from "./employee-presentational";
import notification from "utils/notification";
import setLoader from "utils/loader";

const EmployeeFunctional = () => {
  const { t } = useTranslation();
  const [btnLoading, setBtnLoading] = useState(false);
  const [employeeDataSource, setEmployeeDataSource] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Start Create Or Update Employee
  const submitEmployee = (value) => {
    setBtnLoading(true);
    let request = {
      name: {
        firstName: value?.firstName || null,
        lastName: value?.lastName || null,
      },
      email: value?.email || null,
      phoneNumber: value?.phoneNumber || null,
      role: value?.role || "EMPLOYEE",
      password: btoa(123456),
    };

    let userId = value?.id;
    if (userId === null) {
      UserService.createUser(request)
        .then((res) => {
          if (res.status === 204) {
            notification.error(t(`TOASTR.API.FAILED_TO_CREATE`));
          } else if (res.status === 400) {
            notification.error(t(`TOASTR.API.FAILED_TO_CREATE`));
          } else if (res.status === 201) {
            notification.success(t(`COMMON.CREATE_SUCCESS`));
            setIsModalOpen(false);
            getEmployeeList();
          }
          setBtnLoading(false);
        })
        .catch((e) => {
          notification.error(t(e));
          setBtnLoading(false);
        });
    } else {
      // delete request.password;
      UserService.updateUser(userId, request)
        .then((res) => {
          if (res.status === 204) {
            notification.error(t(`TOASTR.API.FAILED_TO_UPDATE`));
          } else if (res.status === 200) {
            notification.success(t(`COMMON.UPDATE_SUCCESS`));
            setIsModalOpen(false);
            getEmployeeList();
          }
          setBtnLoading(false);
        })
        .catch((e) => {
          notification.error(t(e));
          setBtnLoading(false);
        });
    }
  };

  // Start Delete Employee
  const deleteEmployee = (id) => {
    UserService.deleteUser(id)
      .then((res) => {
        if (parseInt(res.status, 0) === 204) {
          notification.error(t(`TOASTR.API.FAILED_TO_DELETE`));
        } else {
          notification.success(t(`COMMON.DELETE_SUCCESS`));
          getEmployeeList();
        }
      })
      .catch((e) => {
        notification.error(t(e));
      });
  };

  // Start Get Employee List
  const [filterForm, setFilterForm] = useState({
    size: 8,
    page: 0,
    total: 0,
    search: "",
    isFilter: true,
    role: "EMPLOYEE",
    sort: "createdAt,desc",
  });

  useEffect(() => {
    if (filterForm && filterForm.isFilter) {
      getEmployeeList();
    }
  }, [filterForm]);

  const getEmployeeList = () => {
    setLoader(true);
    UserService.getUserList(filterForm)
      .then((res) => {
        setLoader(false);
        if (res && res.data && res.data.content) {
          setEmployeeDataSource(res.data.content);
          setFilterForm({
            ...filterForm,
            page: res.data.page.number,
            total: res.data.page.totalElements,
            isFilter: false,
          });
        }
      })
      .catch(() => {
        setLoader(false);
        setFilterForm({
          ...filterForm,
          isFilter: false,
        });
      });
  };

  return (
    <EmployeePresentational
      employeeDataSource={employeeDataSource}
      submitEmployee={submitEmployee}
      filterForm={filterForm}
      setFilterForm={setFilterForm}
      btnLoading={btnLoading}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      deleteEmployee={deleteEmployee}
    />
  );
};
export default EmployeeFunctional;
