import React, { useRef, useState } from "react";
import * as markerjs2 from "markerjs2";
import ResizeFile from "utils/image-compressor";
import common from "utils/common";
import setLoader from "utils/loader";

const ImageEditor = ({ children, callBack = () => {} }) => {
  const imageInputRef = useRef(null);
  const imageRef = useRef(null);
  const [imageData, setImageData] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);

  // create editor
  const showMarkerArea = () => {
    if (imageRef.current !== null) {
      const markerArea = new markerjs2.MarkerArea(imageRef.current);
      markerArea.availableMarkerTypes = markerArea.ALL_MARKER_TYPES;
      markerArea.settings.displayMode = "popup";
      markerArea.addEventListener("beforeclose", () => {
        setShowImageModal(false);
      });
      markerArea.addEventListener("render", async (event) => {
        var url = event?.dataUrl;
        let urlToFile = await urltoFile(url, common.generateRandomName(30));
        let imageFinalResult = await ResizeFile(urlToFile);
        callBack(imageFinalResult);
        setShowImageModal(false);
      });
      markerArea.show();
    }
  };

  // convert base64 to file
  const urltoFile = (url, filename) => {
    let name = filename + ".jpg";
    return fetch(url)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((buf) => {
        return new File([buf], name, { type: "image/jpeg" });
      });
  };

  const onImageChange = (e) => {
    let totalLength = e.target.files.length;
    let data = [];
    setLoader(true);
    for (const file of e.target.files) {
      ResizeFile(file).then((base64) => {
        urltoFile(base64, common.generateRandomName(30)).then((urlToFile) => {
          data.push(urlToFile);
          if (totalLength === data.length) {
            callBack(data);
            setLoader(false);
          }
        });
      });
    }
  };

  return (
    <>
      <div onClick={() => imageInputRef.current.click()}>{children}</div>
      <input
        accept="image/*"
        multiple={true}
        className="hidden"
        type={"file"}
        ref={imageInputRef}
        onChange={onImageChange}
        // onChange={(e) => {
        //   imageMetaData = e.target.files[0];
        //   setImageData(URL.createObjectURL(imageMetaData));
        //   setShowImageModal(true);
        //   setTimeout(() => {
        //     showMarkerArea();
        //   }, 100);
        // }}
      />

      {showImageModal && (
        <div className="bg-white absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center flex-col">
          <p
            className="absolute top-8 right-8 text-3xl text-gray-700 cursor-pointer"
            onClick={() => setShowImageModal(false)}
          >
            X
          </p>
          <img
            alt="girl"
            className="rounded-xl max-w-[80vw] max-h-[70vh] object-cover"
            ref={imageRef}
            src={imageData}
            onClick={() => showMarkerArea()}
          />
        </div>
      )}
    </>
  );
};

export default ImageEditor;
