import { faCamera, faRotate, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import common from "utils/common";
import ResizeFile from "utils/image-compressor";

const MultipleImageUpload = ({
  callBack = () => {},
  cancel = () => {},
  visible = false,
}) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [images, setImages] = useState([]);
  const [useFrontCamera, setUseFrontCamera] = useState(false);

  useEffect(() => {
    if (visible) {
      getVideo();
      setImages([]);
    }
  }, [visible]);

  const changeCamera = () => {
    getVideo(!useFrontCamera);
    setUseFrontCamera(!useFrontCamera);
  };

  const getVideo = (camera = useFrontCamera) => {
    try {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: {
              min: 1280,
              ideal: 1920,
              max: 2560,
            },
            height: {
              min: 720,
              ideal: 1080,
              max: 1440,
            },
            facingMode: camera ? "user" : "environment",
          },
        })
        .then((stream) => {
          let video = videoRef.current;
          video.srcObject = stream;
          video.play();
        })
        .catch((err) => {
          cancel();
          if (err.message === "Permission denied") {
            alert("Camera permission denied");
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const captureImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    const img = document.createElement("img");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0);
    img.src = canvas.toDataURL("image/png");
    let newData = {
      id: common.generateRandomName(20),
      url: img.src,
    };
    setImages([...images, newData]);
  };

  // convert base64 to file
  const urltoFile = (url, filename) => {
    let name = filename + ".jpg";
    return fetch(url)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((buf) => {
        return new File([buf], name, { type: "image/jpeg" });
      });
  };

  const deleteImage = (id) => {
    setImages(images.filter((image) => image.id !== id));
  };

  const generateCallBack = () => {
    let totalLength = images.length;
    let data = [];
    images.map((file) => {
      downloadImage(file.url);
      urltoFile(file?.url, common.generateRandomName(30)).then((urlToFile1) => {
        ResizeFile(urlToFile1).then((base64) => {
          urltoFile(base64, common.generateRandomName(30)).then(
            (urlToFile2) => {
              data.push(urlToFile2);
              if (totalLength === data.length) {
                callBack(data);
              }
            }
          );
        });
      });

      return null;
    });
  };

  const downloadImage = (data) => {
    const link = document.createElement("a");
    link.href = data;
    link.setAttribute("download", `${common.generateRandomName(30)}.jpg`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    visible && (
      <div className="fixed top-0 left-0 right-0 bottom-0 min-w-[100vw] min-h-[100vh] bg-white overflow-auto z-[1000] flex flex-1 flex-col justify-between">
        <div className="flex flex-col h-[100vh]">
          <video
            ref={videoRef}
            width={window.innerWidth}
            className="max-h-[100vh]"
          ></video>
          <div
            className="rounded-full bg-primary fixed top-5 right-5 flex justify-center items-center h-[45px] w-[45px]"
            onClick={() => changeCamera()}
          >
            <FontAwesomeIcon icon={faRotate} color="black" size="lg" />
          </div>
        </div>
        <div>
          <div className="flex gap-4 overflow-x-scroll p-2 absolute bottom-[120px] right-0 max-h-[100px] max-w-[100vw] left-0">
            {images.map((image) => {
              return (
                <div className="relative " key={image.id}>
                  <img
                    src={image?.url}
                    alt="new"
                    className="rounded-sm object-cover w-[100px] min-w-[100px] max-h-[80px]"
                  />
                  <div
                    className="p-[6px] absolute rounded-full bg-primary right-[2px] top-[2px] flex justify-center items-center"
                    onClick={() => deleteImage(image.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} color="black" size="sm" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="min-h-[100px] flex gap-5 absolute bottom-10 right-0 left-0">
            <div className="flex flex-1 justify-center items-center">
              <span className="font-medium text-black" onClick={() => cancel()}>
                Cancel
              </span>
            </div>
            <div className="flex flex-1 justify-center items-center">
              <FontAwesomeIcon
                icon={faCamera}
                size={"2x"}
                color={"black"}
                onClick={() => captureImage()}
              />
            </div>
            <div className="flex flex-1 justify-center items-center">
              <span
                className="font-medium text-black"
                onClick={() => generateCallBack()}
              >
                Done
              </span>
            </div>
          </div>
        </div>
        <canvas className="hidden" ref={canvasRef}></canvas>
      </div>
    )
  );
};

export default MultipleImageUpload;
