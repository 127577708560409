import axios from "axios";
import { authUrl } from "urls/urls";

const login = async (request) => {
  const headers = {
    skipAuth: true,
    skipErrors: true,
  };
  return axios.post(authUrl.LOGIN, request, {
    headers: headers,
    params: {},
  });
};

const refreshToken = async (refreshToken) => {
  const headers = {
    refreshToken: refreshToken,
    skipAuth: true,
  };
  return axios.post(
    authUrl.REFRESH_TOKEN,
    {},
    {
      headers: headers,
      params: {},
    }
  );
};

const forgetPassword = async (request, headers = {}, params = {}) => {
  return axios.put(authUrl.FORGET_PASSWORD, request, {
    headers: headers,
    params: params,
  });
};

const resetPassword = async (request) => {
  return axios.put(authUrl.RESET_PASSWORD, request);
};

const logout = async (token = false) => {
  const headers = {
    skipErrors: true,
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
    headers["skipAuth"] = true;
  }
  return axios.get(authUrl.LOGOUT, {
    headers: headers,
    params: {},
  });
};

const LoginService = {
  login,
  logout,
  refreshToken,
  forgetPassword,
  resetPassword,
};

export default LoginService;
