import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import InspectionService from "services/InspectionService";
import DocTemplateService from "services/DocTemplateService";
import notification from "utils/notification";
import InspectionPresentational from "./inspection-presentational";
import setLoader from "utils/loader";
import * as markerjs2 from "markerjs2";
import common from "utils/common";
import ResizeFile from "utils/image-compressor";

const InspectionFunctional = () => {
  // Hooks and instances
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const params = useParams();
  const [activeParams, setActiveParams] = useState(null);

  //Loading
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [showModel, setShowModel] = useState(false);

  //Data
  const [client, setClient] = useState({});
  const [menus, setMenus] = useState([]);
  const [documentTemplateIds, setDocumentTemplateIds] = useState([]);
  const [allInspectionSlugs, setAllInspectionSlugs] = useState({});
  const [isDescriptionRequired, setIsDescriptionRequired] = useState({});
  const [materialData, setMaterialData] = useState([]);
  const [quickNoteData, setQuickNoteData] = useState([]);

  // form State
  const [templateEditorPreData, setTemplateEditorPreData] = useState("");
  const [subCategoryTypePreData, setSubCategoryTypePreData] = useState([]);
  const [subCategoryTypeAllData, setSubCategoryTypeAllData] = useState([]);
  const [preSubCategoryTypeData, setPreSubCategoryTypeData] = useState([]);

  const [templateContent, setTemplateContent] = useState([]);
  const [templateEditorData, setTemplateEditorData] = useState("");
  const [subCategoryData, setSubCategoryData] = useState({});
  const [subCategoryTypeData, setSubCategoryTypeData] = useState([]);
  const [showImageEdit, setShowImageEdit] = useState(false);
  const [editImageURL, setEditImageURL] = useState(null);
  const [editImageId, setEditImageId] = useState(null);

  // Helpers
  const [total, setTotal] = useState(null);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [materialEdit, setMaterialEdit] = useState(false); // Track for construction type is in edit mode
  const [inspectionData, setInspectionData] = useState([]);
  const [isTemplate, setIsTemplate] = useState(true); // Check for selected category is template or not

  const [resetEditor, setResetEditor] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(0);
  const [inspectionId, setInspectionId] = useState(null); // Inspection state and determine weather inspection is new or not
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [initialEdit, setInitialEdit] = useState(true);

  const getInspectionID = () => {
    return localStorage.getItem("inspectionId") || inspectionId;
  };

  const findCurrentCategoryData = (categories) => {
    const catData = [...categories].filter((category) => {
      return category?.categoryId === Number(activeParams?.menu);
    });
    return [...catData];
  };

  const findCurrentSubCategoryData = (subCategories) => {
    const subCatData = [...subCategories].filter((subCategory) => {
      return subCategory?.subCategoryId === Number(activeParams?.submenu);
    });
    return [...subCatData];
  };

  useEffect(() => {
    // updateInspectionData()
    if (!params.menu) {
      navigate(`/inspection/1`);
    }
    fetchMenu();
    if (localStorage.getItem("client")) {
      setClient(JSON.parse(localStorage.getItem("client")));
      if (localStorage.getItem("inspectionId")) {
        setInspectionId(localStorage.getItem("inspectionId"));
      } else {
        localStorage.removeItem("inspectionId");
        localStorage.removeItem("inspectionId");
        setInspectionId(null);
      }
    } else {
      navigate(`/clients`);
    }
    return () => {
      localStorage.removeItem("client");
      localStorage.removeItem("inspectionId");
    };
  }, []);

  useEffect(() => {
    let inspectionSlugIds = {};
    let isDescription = {};
    menus?.forEach((menu) => {
      inspectionSlugIds[menu?.categoryId] = [];
      isDescription[menu?.categoryId] = [];
      if (menu?.exteriorSubCategories?.length > 0) {
        let subMenuIds = [];
        let subMenuIsDescription = [];
        let setsubMenuIsDescription = {};
        [...menu?.exteriorSubCategories]?.forEach((subMenu) => {
          subMenuIds.push(subMenu?.subCategoryId);
          setsubMenuIsDescription[subMenu?.subCategoryId] =
            subMenu?.isDescriptionRequired;
        });

        subMenuIsDescription.push(setsubMenuIsDescription);
        inspectionSlugIds[menu?.categoryId] = [...subMenuIds];
        isDescription[menu?.categoryId] = [...subMenuIsDescription];
      }
    });
    setAllInspectionSlugs({ ...inspectionSlugIds });
    setIsDescriptionRequired({ ...isDescription });
  }, [menus]);

  // Scroll To Top
  useEffect(() => {
    containerRef.current.scrollIntoView();
  }, [activeParams]);

  useEffect(() => {
    let templatedCat = false;
    if (activeParams) {
      templatedCat =
        documentTemplateIds?.includes(Number(activeParams?.menu)) || false;
      if (templatedCat) {
        setSelectedTemplate(Number(activeParams?.menu));
      }
    } else {
      templatedCat =
        documentTemplateIds?.includes(Number(params?.menu)) || false;
      if (templatedCat) {
        setSelectedTemplate(Number(params?.menu));
      }
    }
    setIsTemplate(templatedCat);
  }, [activeParams, documentTemplateIds]);

  useEffect(() => {
    if (activeParams) {
      saveInspection();
    } else {
      setIsTemplate(
        documentTemplateIds?.includes(Number(params?.menu)) || false
      );
      setActiveParams({ ...params });
    }
  }, [params]);

  // Call when category or subCategory changed
  useEffect(() => {
    setShowImageEdit(false);
    setOpenMobileMenu(false);
    if (isTemplate || !activeParams) {
      // console.log(isTemplate)
      fetchDocumentTemplate();
    } else {
      fetchQuickNote();
      fetchMaterialData();
      fetchSubCategoryType();
      setMaterialTypes([]);
      setMaterialEdit(false);
    }
    if (getInspectionID() > 0 && inspectionData?.length < 1) {
      fetchInspectionData(getInspectionID());
    } else if (activeParams?.submenu) {
      if (inspectionData?.length > 1) {
        let currentCategoryArr = [];
        if (inspectionData.length > 0) {
          currentCategoryArr = findCurrentCategoryData([...inspectionData]);
        }
        const currentCategory = currentCategoryArr?.[0];
        let currentSubCategoryArr = [];
        if (currentCategory?.subCategories?.length > 0) {
          currentSubCategoryArr = findCurrentSubCategoryData([
            ...currentCategory?.subCategories,
          ]);
        }
        const currentSubCategoryObj =
          currentSubCategoryArr?.length > 0
            ? { ...currentSubCategoryArr?.[0] }
            : {};
        let currentSubCategory = {};
        let currentSubCategoryTypes = [];
        if (currentSubCategoryObj?.subCategoryTypes) {
          const { subCategoryTypes, ...currentSubCategoryExtract } =
            currentSubCategoryObj;
          currentSubCategory = { ...currentSubCategoryExtract };
          currentSubCategoryTypes = [...subCategoryTypes];
        }
        setSubCategoryData({ ...currentSubCategory });
        if (currentSubCategory?.constructionTypes?.length > 0) {
          const constructionTypeIds = currentSubCategory?.constructionTypes
            .filter(({ constructionTypeId }) => constructionTypeId)
            .map(({ constructionTypeId }) => constructionTypeId);
          setMaterialTypes(constructionTypeIds);
        } else {
          setMaterialTypes([]);
        }
        setSubCategoryTypePreData([...currentSubCategoryTypes]);
      } else {
        setMaterialTypes([]);
        setMaterialEdit(false);
        setSubCategoryTypePreData([]);
        setSubCategoryData({});
      }
    }
  }, [activeParams, inspectionData]);

  // Merge SubCategoryTypeInspectedData And SubCategoryTypeAllData
  useEffect(() => {
    if (activeParams?.submenu) {
      const allData = [...subCategoryTypeAllData];
      const formatAllData = [...allData].map(
        ({ id: subCategoryTypeId, ...rest }) => ({
          subCategoryTypeId,
          ...rest,
        })
      );

      const preData = [...subCategoryTypePreData];
      const mergedTypeData = [...preData].concat(
        formatAllData.filter((bo) =>
          [...preData].every(
            (ao) => ao.subCategoryTypeId != bo.subCategoryTypeId
          )
        )
      );
      let sortedCategoryTypeData = [...mergedTypeData].sort((p2, p1) =>
        p1.subCategoryTypeId < p2.subCategoryTypeId
          ? 1
          : p1.subCategoryTypeId > p2.subCategoryTypeId
          ? -1
          : 0
      );
      // console.log(sortedCategoryTypeData);
      // console.log("allData", formatAllData);
      setSubCategoryTypeData([...sortedCategoryTypeData]);
      setPreSubCategoryTypeData([...sortedCategoryTypeData]);
    }
  }, [subCategoryTypeAllData]);

  const setTemplateEditorContent = (content = "", id = 0) => {
    setSelectedTemplate(id);
    setTemplateEditorData(content);
  };

  // Update Template Editor Data on content change
  useEffect(() => {
    if (isTemplate) {
      if (inspectionData?.length > 0) {
        let currentCategory = findCurrentCategoryData([...inspectionData]);
        if (currentCategory?.length > 0) {
          setTemplateEditorPreData(currentCategory?.[0]?.template?.content);
          setTemplateEditorContent(
            currentCategory?.[0]?.template?.content,
            currentCategory?.[0]?.template?.documentTemplateId
          );
        } else if (templateContent?.length > 0) {
          const defaultTemplate = templateContent?.filter(
            (template) => template?.isDefault === true
          );
          setTemplateEditorPreData("");
          setTemplateEditorContent(
            defaultTemplate?.[0]?.content,
            defaultTemplate?.[0]?.documentTemplateId
          );
        } else {
          setTemplateEditorPreData("");
          setTemplateEditorContent();
        }
      } else if (templateContent?.length > 0) {
        const defaultTemplate = templateContent?.filter(
          (template) => template?.isDefault === true
        );
        setTemplateEditorPreData("");
        setTemplateEditorContent(
          defaultTemplate?.[0]?.content,
          defaultTemplate?.[0]?.documentTemplateId
        );
        if (initialEdit && !templateEditorData && !getInspectionID()) {
          setInitialEdit(false);
          setInspectionData([...inspectionData]);
        }
      } else {
        setTemplateEditorPreData("");
        setTemplateEditorContent();
      }
      setResetEditor("clear");
      // containerRef.current.scrollIntoView()
    }
  }, [inspectionData, isTemplate, templateContent]);

  // console.log(preSubCategoryTypeData);
  // console.log(menus)

  // For goback to previous page
  // useEffect(() => {
  //   window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener("popstate", onBackButtonEvent);
  //   return () => {
  //     window.removeEventListener("popstate", onBackButtonEvent);
  //   };
  // }, []);

  // Handle back navigation
  // const onBackButtonEvent = (e) => {
  //   e.preventDefault();
  //   navigate("/clients");
  // };

  const fetchMenu = async () => {
    let query = {
      isSubCategoryNeed: true,
      isTemplateCategory: null,
    };
    await InspectionService.getInspectionMenu(query).then(async (res) => {
      if (res?.data?.content) {
        let documentTemplates = [...res?.data?.content].map(
          (cat) => cat?.isTemplate && cat?.categoryId
        );
        let documentTemplatesFiltered = [...documentTemplates].filter((x) => x);
        setDocumentTemplateIds(documentTemplatesFiltered || []);
        setMenus(res?.data?.content || []);
      } else {
        setMenus([]);
      }
    });
  };

  // fetch inspection data if inspection id available
  const fetchInspectionData = async (id) => {
    setLoader(true);
    await InspectionService.getInspection(id).then((res) => {
      if (res?.data) {
        setInspectionData([...res?.data?.inspectionCategories]);
        setTotal(res?.data?.total);
        setLoader(false);
      } else {
        setInspectionData([]);
        setLoader(false);
      }
    });
  };

  // update inspection if inspection id available
  const updateInspectionData = async (body, state = null) => {
    await InspectionService.updateInspection(body)
      .then((res) => {
        if ([200, 201].includes(parseInt(res.status, 0))) {
          if (!_.isEqual(params, activeParams)) {
            if (documentTemplateIds?.includes(Number(params?.menu))) {
              setIsTemplate(true);
            } else {
              setIsTemplate(false);
            }
            setActiveParams({ ...params });
          }
          if (state === "submit") {
            notification.success(t(`COMMON.INSPECTION_COMPLETED`));
            setTimeout(() => {
              navigate(`/clients?tab=2`);
            }, 1500);
          } else {
            fetchInspectionData(
              res?.data?.inspectionId ||
                localStorage.getItem("inspectionId") ||
                inspectionId
            );
          }
        } else {
          const tempParams = activeParams;
          if (params !== activeParams) {
            setActiveParams(null);
          }
          navigate("/" + tempParams["*"]);
        }
      })
      .catch((e) => {
        notification.error(t(`${e}`));
        const tempParams = activeParams;
        if (params !== activeParams) {
          setActiveParams(null);
        }
        navigate("/" + tempParams["*"]);
      });
  };

  // create inspection if inspection id not available
  const createInspectionData = async (body, state = null) => {
    await InspectionService.saveInspection(body)
      .then((res) => {
        if ([200, 201].includes(parseInt(res.status, 0))) {
          if (res?.data?.inspectionId) {
            localStorage.setItem("inspectionId", res?.data?.inspectionId);
            localStorage.setItem("inspectionId", res?.data?.inspectionId);
            setInspectionId(res?.data?.inspectionId);
          }
          if (!_.isEqual(params, activeParams)) {
            if (documentTemplateIds?.includes(Number(params?.menu))) {
              setIsTemplate(true);
            } else {
              setIsTemplate(false);
            }
            setActiveParams({ ...params });
          }
          if (state === "submit") {
            navigate(`/clients?tab=2`);
          } else {
            fetchInspectionData(
              res?.data?.inspectionId ||
                localStorage.getItem("inspectionId") ||
                inspectionId
            );
          }
        } else {
          const tempParams = activeParams;
          if (params !== activeParams) {
            setActiveParams(null);
          }
          navigate("/" + tempParams["*"]);
        }
      })
      .catch((e) => {
        notification.error(t(`${e}`));
        const tempParams = activeParams;
        if (params !== activeParams) {
          setActiveParams(null);
        }
        navigate("/" + tempParams["*"]);
      });
  };

  const fetchMaterialData = async () => {
    setLoader(true);
    let query = {
      categoryId: activeParams?.menu,
      subCategoryId: activeParams?.submenu,
      type: "CONSTRUCTION_TYPE",
    };
    await InspectionService.getMasterData(query)
      .then((res) => {
        setLoader(false);
        if (res?.data?.content) {
          setMaterialData([...res?.data?.content]);
        } else if (parseInt(res.status, 0) === 204) {
          if (materialData?.length > 0) {
            setMaterialData([]);
          }
          // notification.info(t(`TOASTR.API.DATA_NOT_FOUND`))
        }
      })
      .catch((e) => {
        notification.error(e);
        setMaterialData([]);
        setLoader(false);
      });
  };

  const createMaterialData = async (text) => {
    const request = {
      masterDatas: [
        {
          categoryId: activeParams?.menu,
          subCategoryId: activeParams?.submenu,
          type: "CONSTRUCTION_TYPE",
          name: text,
        },
      ],
    };
    setLoader(true);
    await InspectionService.saveMasterData(request).then((res) => {
      setLoader(false);
      if (parseInt(res.status, 0) === 201) {
        fetchMaterialData();
      }
    });
  };

  const updateMaterialData = async (id, text) => {
    const request = {
      masterDatas: [
        {
          categoryId: activeParams?.menu,
          subCategoryId: activeParams?.submenu,
          type: "CONSTRUCTION_TYPE",
          name: text,
        },
      ],
    };
    setLoader(true);
    await InspectionService.updateMasterData(id, request).then((res) => {
      setLoader(false);
      if ([200, 201].includes(parseInt(res.status, 0))) {
        fetchMaterialData();
      }
    });
  };

  const deleteMaterialData = async (id) => {
    setLoader(true);
    await InspectionService.deleteMasterData(id).then((res) => {
      setLoader(false);
      if ([200, 201, 204].includes(parseInt(res.status, 0))) {
        fetchMaterialData();
      }
    });
  };

  // const saveMaterialData = (updatedMaterialData) => {};

  const fetchQuickNote = async () => {
    if (activeParams?.submenu) {
      setLoader(true);
      let param = {
        categoryId: activeParams?.menu,
      };
      await InspectionService.getQuickNote(param).then((res) => {
        setLoader(false);
        setQuickNoteData(res?.data || []);
      });
    }
  };

  // Get Document Templates
  const fetchDocumentTemplate = async () => {
    if (activeParams?.menu || (!activeParams && params?.menu)) {
      setLoader(true);
      const filterForm = {
        exteriorCategoryIds: activeParams?.menu || params?.menu,
      };
      // Get Tax Amount
      if (filterForm.exteriorCategoryIds == 17) {
        await InspectionService.getTaxPercentage()
          .then(async (res) => {
            if (res?.data?.tax) {
              // console.log(res?.data?.tax);
              setTaxPercentage(res?.data?.tax || 0);
              setLoader(false);
            } else if (parseInt(res.status, 0) === 204) {
              setTemplateContent([]);
              setLoader(false);
            }
          })
          .catch((e) => {
            notification.error(t(`${e}`));
            setLoader(false);
          });
      }
      await DocTemplateService.getDocTemplateContent(filterForm)
        .then(async (res) => {
          if (res?.data?.content) {
            // console.log(res?.data?.content)
            setTemplateContent([...res?.data?.content]);
            setLoader(false);
          } else if (parseInt(res.status, 0) === 204) {
            setTemplateContent([]);
            setLoader(false);
          }
        })
        .catch((e) => {
          notification.error(t(`${e}`));
          setLoader(false);
        });
    }
  };

  // get Sub Category Type
  const fetchSubCategoryType = async () => {
    if (activeParams?.submenu) {
      let categoryId = activeParams?.submenu;
      let query = {
        inspectionId: inspectionId,
      };
      await InspectionService.getSubCategoryType(categoryId, query).then(
        (res) => {
          let data = [];
          if (res?.data?.content) {
            res?.data?.content?.map((subCategorytype) => {
              let subCategoryTypeObj = {
                id: subCategorytype?.subCategoryId,
                subCategoryTypeName: subCategorytype?.name,
                name: "",
                description: "",
                imageIds: [],
              };
              data.push(subCategoryTypeObj);
            });
          }
          setSubCategoryTypeAllData(data);
        }
      );
    }
  };

  const uploadImage = async (file) => {
    return new Promise((resolve, reject) => {
      const image = file;
      let formData = new FormData();
      let domain = "honeycomb-inspection";
      let jsonData = '{"attachmentType":"INSPECTION_PICTURE"}';
      formData.append("jsonData", jsonData);

      image.map((img) => {
        formData.append("files", img);
      });

      let newImage = {};
      setImageUploadLoading(true);
      setLoader(true);
      return InspectionService.postInspectionAttachements(domain, formData)
        .then(async (res) => {
          let imageData = res?.data?.content;
          let result = [];
          imageData.map((data, index) => {
            newImage = {
              imageId: data?.attachmentId,
              imageUrl: data?.url,
              position: index,
            };
            return result.push(newImage);
          });
          setImageUploadLoading(false);
          setLoader(false);
          resolve(imageData);
        })
        .catch((err) => {
          setImageUploadLoading(false);
          setLoader(false);
          reject(newImage);
        });
    });
  };

  // Old Code
  // const uploadImage = async (file) => {
  //   return new Promise((resolve, reject) => {
  //     const image = file;
  //     let formData = new FormData();
  //     let domain = "honeycomb-inspection";
  //     let jsonData = '{"attachmentType":"INSPECTION_PICTURE"}';
  //     formData.append("file", image);
  //     formData.append("jsonData", jsonData);
  //     let newImage = {};
  //     setImageUploadLoading(true);
  //     setLoader(true);
  //     return InspectionService.postInspectionAttachement(domain, formData)
  //       .then(async (res) => {
  //         const imageData = await getImageURL(res?.data?.attachmentId);
  //         if (imageData?.id) {
  //           newImage = {
  //             imageId: imageData?.id,
  //             imageUrl: imageData?.url,
  //           };
  //         }
  //         setImageUploadLoading(false);
  //         setLoader(false);
  //         resolve(newImage);
  //       })
  //       .catch((err) => {
  //         setImageUploadLoading(false);
  //         setLoader(false);
  //         reject(newImage);
  //       });
  //   });
  // };

  const getImageURL = (id) => {
    return InspectionService.getImageUrl(id).then((res) => {
      return res?.data;
    });
  };

  const next = () => {
    const currentMenu = Number(activeParams?.menu) || null;
    const currentSubMenu = Number(activeParams?.submenu) || null;
    let nextMenu = null;
    let availableMenus = { ...allInspectionSlugs };
    let availableMenuIds = Object?.keys(availableMenus);
    // console.log(availableMenus)
    if (currentSubMenu) {
      let availableSubMenus = availableMenus?.[`${currentMenu}`];
      let currentSubIndex = availableSubMenus?.indexOf(currentSubMenu);
      if (availableSubMenus?.length > currentSubIndex + 1) {
        nextMenu = currentMenu + "/" + availableSubMenus[currentSubIndex + 1];
      } else {
        let currentIndex = availableMenuIds?.indexOf(currentMenu?.toString());
        if (menus?.length > currentIndex + 1) {
          let nextCatMenuIndex = availableMenuIds?.[`${currentIndex + 1}`];
          const availableNextSubMenus = availableMenus?.[`${nextCatMenuIndex}`];
          // console.log(availableNextSubMenus)
          // console.log(availableMenus, nextCatMenuIndex)
          if (availableNextSubMenus?.length > 0) {
            nextMenu =
              availableMenuIds?.[`${currentIndex + 1}`] +
              "/" +
              availableNextSubMenus?.[0];
          } else {
            nextMenu = availableMenuIds?.[`${currentIndex + 1}`];
          }
        } else {
          // console.log("last menu");
          saveInspection();
        }
      }
    } else {
      let currentIndex = availableMenuIds?.indexOf(currentMenu?.toString());
      if (availableMenuIds?.length > currentIndex + 1) {
        let nextCatMenuIndex = availableMenuIds?.[`${currentIndex + 1}`];
        const availableNextSubMenus = availableMenus?.[`${nextCatMenuIndex}`];
        // console.log(nextCatMenuIndex)
        // console.log(availableNextSubMenus)
        if (availableNextSubMenus?.length > 0) {
          nextMenu =
            availableMenuIds?.[`${currentIndex + 1}`] +
            "/" +
            availableNextSubMenus?.[0];
        } else {
          nextMenu = availableMenuIds?.[`${currentIndex + 1}`];
        }
      } else {
        // console.log("last menu");
        saveInspection();
      }
    }

    if (nextMenu) {
      navigate(`/inspection/${nextMenu}`);
    }
  };

  const saveInspection = (state = null, isFinal = false) => {
    Number(activeParams?.menu) == 17 && setShowModel(false);
    Number(activeParams?.menu) == 17 && setLoader(true);

    let query = {
      categoryId: Number(activeParams?.menu) || null,
      clientId: client?.userId,
      inspectionId: Number(inspectionId) || null,
      subCategory: null,
      subCategoryTypes: null,
      templateContent: null,
      templateId: null,
      total: (Number(activeParams?.menu) == 17 && Number(total)) || null,
    };
    if (isTemplate) {
      if (templateEditorData !== templateEditorPreData || state === "submit") {
        query = {
          ...query,
          templateContent: templateEditorData,
          templateId:
            Number(selectedTemplate) > 0 ? Number(selectedTemplate) : null,
        };
        let currentCategory = findCurrentCategoryData(inspectionData);
        if (currentCategory?.[0]?.inspectionCategoryId) {
          query["inspectionCategoryId"] = Number(
            currentCategory?.[0]?.inspectionCategoryId
          );
          updateInspectionData(query, state);
        } else {
          createInspectionData(query, state);
        }
      } else {
        if (state === "submit") {
          navigate(`/clients?tab=2`);
        } else {
          setActiveParams({ ...params });
          if (documentTemplateIds?.includes(Number(params?.menu))) {
            setIsTemplate(true);
          } else {
            setIsTemplate(false);
          }
        }
      }
    } else if (activeParams?.submenu) {
      // console.log(inspectionData)
      const preInspextionData = [...inspectionData];
      const preCategoryArr = findCurrentCategoryData(
        preInspextionData.length > 0 ? [...preInspextionData] : []
      );
      const preCategory = preCategoryArr?.[0];
      const preSubCategoryArr = findCurrentSubCategoryData(
        preCategory?.subCategories?.length > 0
          ? [...preCategory?.subCategories]
          : []
      );
      // const preSubCategory = preSubCategoryArr?.[0];
      // const preSubCategoryTypes = preSubCategory?.["subCategoryTypes"] || [];
      // if (preSubCategory?.subCategoryTypes) {
      //   delete preSubCategory?.subCategoryTypes;
      // }
      const preSubCategoryObj =
        preSubCategoryArr?.length > 0 ? { ...preSubCategoryArr?.[0] } : {};
      let preSubCategory = {};
      // let preSubCategoryTypes = [];
      if (preSubCategoryObj?.subCategoryTypes) {
        const { subCategoryTypes, ...preSubCategoryExtract } =
          preSubCategoryObj;
        preSubCategory = { ...preSubCategoryExtract };
        // preSubCategoryTypes = [...subCategoryTypes];
      }
      const preSubCategoryTypes = [...preSubCategoryTypeData];
      const currentSubCategory = { ...subCategoryData };
      const currentSubCategoryTypes = [...subCategoryTypeData];
      if (currentSubCategory?.subCategoryTypes) {
        delete currentSubCategory?.subCategoryTypes;
      }
      let hasCatChanges = !_.isEqual(currentSubCategory, preSubCategory);
      let hasTypeChanges = !_.isEqual(
        currentSubCategoryTypes,
        preSubCategoryTypes
      );
      let constructionTypeIds = [];
      if (preSubCategory?.constructionTypes?.length > 0) {
        constructionTypeIds = [...preSubCategory?.constructionTypes]
          .filter(({ constructionTypeId }) => constructionTypeId)
          .map(({ constructionTypeId }) => constructionTypeId);
        setMaterialTypes(constructionTypeIds);
      } else {
        setMaterialTypes([]);
      }
      let hasMaterialChanges = !_.isEqual(materialTypes, constructionTypeIds);
      // console.log(currentSubCategoryTypes, preSubCategoryTypes);
      // console.log(hasCatChanges, hasTypeChanges, hasMaterialChanges);
      if (hasCatChanges || hasTypeChanges || hasMaterialChanges) {
        query["subCategory"] = {
          id: Number(activeParams?.submenu) || null,
          constructionTypeName: null,
        };
        if (hasMaterialChanges || hasCatChanges) {
          query["subCategory"] = {
            ...query["subCategory"],
            constructionTypeIds:
              materialTypes?.length > 0 ? materialTypes : null,
            description: subCategoryData?.description || null,
            imageIds:
              subCategoryData?.attachments?.length > 0
                ? subCategoryData?.attachments?.map((attachment) => {
                    return attachment?.imageId;
                  })
                : [],
            images:
              subCategoryData?.attachments?.length > 0
                ? subCategoryData?.attachments?.map((attachment) => {
                    return {
                      imageId: attachment?.imageId,
                      position: attachment?.position,
                    };
                  })
                : [],
          };
        }
        if (hasTypeChanges) {
          const subCategoryTypeDifference = _.differenceBy(
            currentSubCategoryTypes,
            preSubCategoryTypes
          );
          // console.log(subCategoryTypeDifference);
          const subCategoryTypeQuery = [];
          // currentSubCategoryTypes?.forEach((subCategoryType) => {
          subCategoryTypeDifference?.forEach((subCategoryType) => {
            subCategoryTypeQuery?.push({
              description: subCategoryType?.description || "",
              id: subCategoryType?.subCategoryTypeId || null,
              imageIds:
                subCategoryType?.attachments?.length > 0
                  ? subCategoryType?.attachments?.map((attachment) => {
                      return attachment?.imageId;
                    })
                  : [],
              images:
                subCategoryType?.attachments?.length > 0
                  ? subCategoryType?.attachments?.map((attachment) => {
                      return {
                        imageId: attachment?.imageId,
                        position: attachment?.position,
                      };
                    })
                  : [],
              inspectionSubCategoryId:
                Number(subCategoryType?.inspectionSubCategoryId) || null,
              name: subCategoryType?.subCategoryTypeId
                ? null
                : subCategoryType?.subCategoryTypeName || null,
            });
          });
          if (subCategoryTypeQuery?.length > 0) {
            query["subCategoryTypes"] = subCategoryTypeQuery;
          }
        }
        if (subCategoryData?.inspectionSubCategoryId) {
          query["subCategory"]["inspectionSubCategoryId"] = Number(
            subCategoryData?.inspectionSubCategoryId
          );
          updateInspectionData(query, state);
        } else {
          createInspectionData(query, state);
        }
      } else {
        if (state === "submit") {
          navigate(`/clients?tab=2`);
        } else {
          setActiveParams({ ...params });
          if (documentTemplateIds?.includes(Number(params?.menu))) {
            setIsTemplate(true);
          } else {
            setIsTemplate(false);
          }
        }
      }
    } else if (activeParams?.menu) {
      if (documentTemplateIds?.includes(Number(params?.menu))) {
        setIsTemplate(true);
      } else {
        setIsTemplate(false);
      }
      setActiveParams({ ...params });
    }
  };

  const showMarkerArea = () => {
    if (imageRef.current !== null) {
      localStorage.setItem("markerLoaded", "true");
      try {
        const markerArea = new markerjs2.MarkerArea(imageRef.current);
        markerArea.availableMarkerTypes = markerArea.ALL_MARKER_TYPES;
        markerArea.settings.displayMode = "popup";
        markerArea.addEventListener("beforeclose", () => {
          setShowImageEdit(false);
          localStorage.setItem("markerLoaded", "false");
        });
        markerArea.addEventListener("render", async (event) => {
          var url = event?.dataUrl;
          let urlToFile = await urltoFile(url, common.generateRandomName(30));
          uploadEditedImage(urlToFile);
          setShowImageEdit(false);
          localStorage.setItem("markerLoaded", "false");
        });
        markerArea.show();
      } catch (error) {
        console.log(error);
      }
    }
  };

  // convert base64 to file
  const urltoFile = (url, filename) => {
    let name = filename + ".jpg";
    return fetch(url)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((buf) => {
        return new File([buf], name, { type: "image/jpeg" });
      });
  };

  const uploadEditedImage = (file) => {
    setShowImageEdit(false);
    const imageId = localStorage.getItem("editImageId");
    const image = file;
    let formData = new FormData();
    let domain = "honeycomb-inspection";
    let jsonData = '{"attachmentType":"INSPECTION_PICTURE"}';
    formData.append("file", image);
    formData.append("jsonData", jsonData);
    let newImage = {};
    setImageUploadLoading(true);
    setLoader(true);
    return InspectionService.updateInspectionAttachement(
      domain,
      formData,
      imageId
    )
      .then(async (res) => {
        const imageData = await getImageURL(res?.data?.attachmentId);
        const type = localStorage.getItem("editImageType");
        if (type === "subCategory") {
          let data = subCategoryData.attachments;
          data.map((a) => {
            if (a.imageId === imageData?.id) {
              a.imageUrl = imageData.url;
            }
          });
          setSubCategoryData({
            ...subCategoryData,
            attachments: data,
          });
        } else if (type === "subCategoryType") {
          const subCategoryTypeId = localStorage.getItem("subCategoryTypeId");
          let finalData = subCategoryTypeData.map((a) => {
            if (a.subCategoryTypeId == subCategoryTypeId) {
              a.attachments.map((b) => {
                if (b.imageId === imageData?.id) {
                  b.imageUrl = imageData.url;
                }
              });
            }
            return a;
          });
          setSubCategoryTypeData(finalData);
          localStorage.removeItem("editImageType");
          localStorage.removeItem("subCategoryTypeId");
        }
        setImageUploadLoading(false);
        setLoader(false);
        localStorage.removeItem("editImageId");
      })
      .catch((err) => {
        localStorage.removeItem("editImageId");
        setImageUploadLoading(false);
        setLoader(false);
        notification.error("Image Upload Failed!");
        return newImage;
      });
  };

  return (
    <InspectionPresentational
      containerRef={containerRef}
      client={client}
      menus={menus}
      allInspectionSlugs={allInspectionSlugs}
      setAllInspectionSlugs={setAllInspectionSlugs}
      inspectionData={inspectionData}
      materialEdit={materialEdit}
      setMaterialEdit={setMaterialEdit}
      isTemplate={isTemplate}
      setIsTemplate={setIsTemplate}
      templateContent={templateContent}
      setTemplateContent={setTemplateContent}
      templateEditorData={templateEditorData}
      setTemplateEditorData={setTemplateEditorData}
      resetEditor={resetEditor}
      setResetEditor={setResetEditor}
      selectedTemplate={selectedTemplate}
      setSelectedTemplate={setSelectedTemplate}
      uploadImage={uploadImage}
      imageUploadLoading={imageUploadLoading}
      next={next}
      saveInspection={saveInspection}
      openMobileMenu={openMobileMenu}
      setOpenMobileMenu={setOpenMobileMenu}
      quickNoteData={quickNoteData}
      materialData={materialData}
      materialTypes={materialTypes}
      setMaterialTypes={setMaterialTypes}
      createMaterialData={createMaterialData}
      updateMaterialData={updateMaterialData}
      deleteMaterialData={deleteMaterialData}
      subCategoryData={subCategoryData}
      setSubCategoryData={setSubCategoryData}
      subCategoryTypeData={subCategoryTypeData}
      setSubCategoryTypeData={setSubCategoryTypeData}
      total={total}
      setTotal={setTotal}
      taxPercentage={taxPercentage}
      setShowImageEdit={setShowImageEdit}
      showImageEdit={showImageEdit}
      showMarkerArea={showMarkerArea}
      imageRef={imageRef}
      editImageURL={editImageURL}
      setEditImageURL={setEditImageURL}
      editImageId={editImageId}
      setEditImageId={setEditImageId}
      showModel={showModel}
      setShowModel={setShowModel}
      isDescriptionRequired={isDescriptionRequired}
    />
  );
};

export default InspectionFunctional;
