import React, { useState } from "react";

const VTabs = ({
  tabsData,
  defaultIndex = 0,
  onTabChange = () => {},
  onClickLoad = false,
  spacing = "default",
  borderless = false,
  tabTitleWidth = "",
}) => {
  const [activeTab, setActiveTab] = useState(defaultIndex);
  const [loadedContentKeys, setLoadedContentKeys] = useState([]);

  if (!(tabsData?.length > 0)) return "";

  let tabContentSpacing = "";
  if (spacing === "default") {
    tabContentSpacing = " p-4 xl:p-6";
  } else {
    tabContentSpacing = " " + spacing;
  }

  let tabContentBorder = "";
  if (!borderless) {
    tabContentBorder = " border rounded border-typo-light";
  }

  if (tabTitleWidth) {
    tabTitleWidth = " " + tabTitleWidth;
  }

  return (
    <div className="tabs-group flex flex-1 space-x-4 w-full">
      <div className={`min-w-[220px] bg-light${tabTitleWidth}`}>
        <ul className={`flex flex-col my-3 flex-wrap font-normal text-base`}>
          {tabsData?.map((tabData, key) => {
            return (
              <li
                key={key}
                role="tab"
                className={`px-3 py-2 flex items-center cursor-pointer ml-4 text-typo-primary aria-selected:bg-primary-light aria-selected:rounded-tl-full aria-selected:rounded-bl-full aria-selected:font-semibold`}
                aria-selected={key === activeTab || false}
                onClick={() => {
                  const previousTabKey = activeTab || 0;
                  const previousTabData = tabsData[previousTabKey];
                  onTabChange(key, tabData, previousTabKey, previousTabData);
                  setActiveTab(key);
                }}
              >
                {tabData?.icon && (
                  <span className="inline-flex items-center justify-center mr-2 w-5 h-5 rounded-full bg-primary">
                    {tabData?.icon}
                  </span>
                )}
                <span className="flex-1">{tabData?.tabTitle}</span>
              </li>
            );
          })}
        </ul>
      </div>
      <div
        className={`tab-content flex flex-col flex-1${tabContentSpacing}${tabContentBorder}`}
      >
        {tabsData?.map((tabData, key) => {
          if (
            onClickLoad &&
            !loadedContentKeys.includes(key) &&
            key !== activeTab
          ) {
            return "";
          }
          if (onClickLoad && !loadedContentKeys.includes(key)) {
            setLoadedContentKeys([...loadedContentKeys, key]);
          }
          return (
            <div
              key={key}
              className={`${
                key === activeTab ? "flex flex-col flex-1" : "hidden"
              }`}
              role="tabpanel"
            >
              {tabData?.tabContent}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VTabs;
