import React, { useCallback, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import validator from "utils/validator";
import hasPermissions from "utils/permissions";
import AuthService from "services/AuthService";
import ClientCard from "components/card/client-card";
import { Modal, Pagination, Popover, Table } from "antd";
import HTabs from "components/tabs/h-tabs";
import Button from "components/Button";
import NoDataFound from "components/NoDataFound";
import TextField from "components/form-fields/text-fields";
import FormErrMsg from "components/FormErrMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisH,
  faExclamationTriangle,
  faFileEdit,
  faPlusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import images from "assets/images/images";

const ClientsPresentational = ({
  clientDataSource,
  empDataSource,
  filterForm,
  setFilterForm,
  submitClient,
  btnLoading,
  isModalOpen,
  setIsModalOpen,
  deleteClient,
  isMobile,
  onUploadInspectionImage,
  paymentChange,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const imgUpload = useRef(null);
  const navigation = useNavigate();
  const { t } = useTranslation();
  const CurrentUser = AuthService?.getCurrentUser();
  const {
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      id: null,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      addressLine: "",
      landMark: "",
      city: "",
      zipCode: "",
      employeeId: "",
      propertyType: "",
      role: "CLIENT",
    },
    mode: "all",
  });

  useEffect(() => {
    if (Number(searchParams?.get("tab"))) {
      setFilterForm({
        ...filterForm,
        inspectionState:
          tabs?.[Number(searchParams?.get("tab"))]?.inspectionState,
        isFilter: true,
      });
      searchParams.delete("tab");
      setSearchParams(searchParams);
    }
  }, []);

  const onFormSubmit = () => {
    trigger().then((res) => {
      if (res) {
        const formData = getValues();
        submitClient(formData);
      }
    });
  };

  const validatormsg = {
    firstName: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
    },
    lastName: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
    },
    email: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
      pattern: { value: validator.EMAIL, message: "VALIDATOR.PATTERN.EMAIL" },
    },
    phoneNumber: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
      minLength: { value: 8, message: "VALIDATOR.MINIMUM.8" },
    },
    addressLine: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
    },
    city: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
    },
    zipCode: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
    },
    propertyType: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
    },
    // employeeId: {
    //   required: {
    //     value:
    //       CurrentUser?.role === roles.SUPER_ADMIN ||
    //       CurrentUser?.role === roles.ADMIN
    //         ? true
    //         : false,
    //     message: "VALIDATOR.REQUIRED",
    //   },
    // },
  };

  const onEditClick = (data) => {
    setValue("id", data?.client?.userId);
    setValue("firstName", data?.client?.name?.firstName);
    setValue("lastName", data?.client?.name?.lastName);
    setValue("email", data?.client?.email);
    setValue("phoneNumber", data?.client?.phoneNumber);
    setValue("addressLine", data?.client?.address?.addressLine);
    setValue("landMark", data?.client?.address?.landMark);
    setValue("city", data?.client?.address?.city);
    setValue("country", "New Zealand");
    setValue("zipCode", data?.client?.address?.zipCode);
    setValue("propertyType", data?.client?.propertyType);
    setValue("employeeId", data?.employee?.userId);
    setValue("role", "CLIENT");
    setIsModalOpen(true);
  };

  // Delete Client
  const { confirm } = Modal;
  const onDeleteClick = (userId) => {
    confirm({
      title: "Are you sure delete this client?",
      icon: (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size={"xl"}
          className="float-left mr-4 text-red-600"
        />
      ),
      okText: t(`COMMON.YES`),
      okButtonProps: {
        className:
          "font-medium text-gray-600 hover:text-primary border-gray-300 hover:border-primary rounded",
      },
      okType: "danger",
      cancelText: t(`COMMON.CANCEL`),
      cancelButtonProps: {
        className:
          "font-medium rounded text-white bg-primary border border-primary hover:border-primary",
      },
      className: "rounded",
      onOk() {
        deleteClient(userId);
      },
      onCancel() {},
    });
  };

  const paymentStatusChangeModal = (inspectionId) => {
    confirm({
      title: "Are you sure payment was received?",
      icon: (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size={"xl"}
          className="float-left mr-4 text-red-600"
        />
      ),
      okText: t(`COMMON.YES`),
      okButtonProps: {
        className:
          "font-medium text-gray-600 hover:text-primary border-gray-300 hover:border-primary rounded",
      },
      okType: "danger",
      cancelText: t(`COMMON.NO`),
      cancelButtonProps: {
        className:
          "font-medium rounded text-white bg-primary border border-primary hover:border-primary",
      },
      className: "rounded",
      onOk() {
        paymentChange(inspectionId);
      },
      onCancel() {},
    });
  };

  const completeTabActionContent = (data) => {
    return (
      <>
        <div
          style={{ marginBottom: 12, cursor: "pointer" }}
          onClick={() => onEditClick(data)}
        >
          <FontAwesomeIcon icon={faEdit} className="mr-2" />
          {t(`COMMON.EDIT_INFO`)}
        </div>

        <div
          style={{ marginBottom: 12, cursor: "pointer" }}
          onClick={() => {
            localStorage.setItem("client", JSON.stringify(data?.client));
            localStorage.setItem("inspectionId", data?.inpsectionId || null);
            navigation("/inspection/1");
          }}
        >
          <FontAwesomeIcon icon={faFileEdit} className="mr-2" />
          {t(`COMMON.EDIT`)}
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => onDeleteClick(data?.client?.userId)}
        >
          <FontAwesomeIcon icon={faTrash} className="mr-2" />
          {t(`COMMON.DELETE`)}
        </div>
      </>
    );
  };

  const completedTableColumns = [
    {
      title: "Image",
      key: "name",
      render: (data, index) => {
        return (
          <div className="cursor-pointer">
            <input
              type="file"
              ref={imgUpload}
              accept="image/*"
              className="hidden"
              onChange={(e) => {
                onUploadInspectionImage(e, localStorage.getItem("imageEditId"));
                localStorage.removeItem("imageEditId");
              }}
            />
            <img
              onClick={() => {
                localStorage.setItem("imageEditId", data?.inpsectionId);
                imgUpload.current.click();
              }}
              className="h-[50px] min-w-[50px] rounded-md bg-gray-400 aspect-video object-cover"
              src={data?.inspectionImageUrl?.url || images.propertyImg}
              alt={
                data?.client?.name?.firstName +
                " " +
                data?.client?.name?.lastName
              }
            />
          </div>
        );
      },
    },
    {
      title: "Client Name",
      key: "name",
      render: (data) => {
        return (
          <a
            onClick={() => {
              navigation(`/inspection-preview/${data?.inpsectionId}`);
            }}
          >
            {data?.client?.name?.firstName} {data?.client?.name?.lastName}
          </a>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "client",
      key: "email",
      render: (text) => <p className="break-words">{text?.email}</p>,
    },
    {
      title: "Phone Number",
      dataIndex: "client",
      key: "phone",
      render: (text) => <p>{text?.phoneNumber}</p>,
    },
    {
      title: "Address",
      dataIndex: "client",
      key: "address",
      render: (text) => (
        <p>
          {" "}
          {text?.address?.addressLine} {text?.address?.landMark}{" "}
          {text?.address?.city} {text?.address?.zipCode}
        </p>
      ),
    },
    {
      title: "Assigned To",
      dataIndex: "employee",
      key: "employee",
      render: (text) => (
        <p>
          {text?.name?.firstName} {text?.name?.lastName}
        </p>
      ),
    },
    {
      title: "Amount (NZD)",
      dataIndex: "netTotal",
      key: "total",
      align: "right",
    },
    {
      title: "Payment Status",
      key: "inspectionPaymentState",
      render: (data) => (
        <div className="flex justify-center">
          {data?.inspectionPaymentState === "Paid" && (
            <div className="bg-[#42855B] rounded-full flex justify-center items-center px-2 color-[#fff]">
              <span className="text-[#fff]">
                {data?.inspectionPaymentState}
              </span>
            </div>
          )}
          {data?.inspectionPaymentState === "Pending" && (
            <div
              className="bg-[#EB1D36] rounded-full flex justify-center items-center px-2 color-[#fff] cursor-pointer"
              onClick={() => paymentStatusChangeModal(data?.inpsectionId)}
            >
              <span className="text-[#fff]">
                {data?.inspectionPaymentState}
              </span>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      render: (data) => {
        return (
          <>
            <Popover
              trigger="click"
              placement="bottom"
              content={() => completeTabActionContent(data)}
            >
              <FontAwesomeIcon icon={faEllipsisH} className="w-5 h-5" />
            </Popover>
          </>
        );
      },
    },
  ];

  const tabsContent = useCallback(() => {
    return (
      <>
        {isMobile && (
          <Button
            title="Add New Client"
            icon={
              <FontAwesomeIcon
                icon={faPlusCircle}
                className={"text-white text-lg"}
              />
            }
            type="outline"
            className="m-0 p-0 text-sm mb-2 inline-block w-full"
            onClick={() => {
              setIsModalOpen(true);
              reset();
            }}
          />
        )}
        {(clientDataSource.length >= 1 && (
          <div className="w-full justify-center">
            <div className="md:grid md:grid-cols-4 md:gap-4">
              {clientDataSource.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <ClientCard
                      key={data?.userId}
                      data={data}
                      onEditClient={onEditClick}
                      onDeleteClient={onDeleteClick}
                      isMobile={isMobile}
                      onUploadImage={onUploadInspectionImage}
                    />
                  </React.Fragment>
                );
              })}
              <div className="col-span-4 mt-10">
                <Pagination
                  className="text-right"
                  pageSize={filterForm && filterForm.size}
                  hideOnSinglePage={true}
                  current={filterForm && filterForm.page + 1}
                  total={filterForm && filterForm.total}
                  onChange={(page) => {
                    setFilterForm({
                      ...filterForm,
                      page: page - 1,
                      isFilter: true,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        )) || <NoDataFound />}
      </>
    );
  }, [clientDataSource]);

  const completedContent = useCallback(() => {
    return (
      <>
        {isMobile && (
          <Button
            title="Add New Client"
            icon={
              <FontAwesomeIcon
                icon={faPlusCircle}
                className={"text-white text-lg"}
              />
            }
            type="outline"
            className="m-0 p-0 text-sm mb-2 inline-block w-full"
            onClick={() => {
              setIsModalOpen(true);
              reset();
            }}
          />
        )}
        {(clientDataSource.length >= 1 && (
          <div className="w-full justify-center">
            <Table
              dataSource={clientDataSource}
              columns={completedTableColumns}
              pagination={false}
            />
            <div className="col-span-4 mt-10">
              <Pagination
                className="text-right"
                pageSize={filterForm && filterForm.size}
                hideOnSinglePage={true}
                current={filterForm && filterForm.page + 1}
                total={filterForm && filterForm.total}
                onChange={(page) => {
                  setFilterForm({
                    ...filterForm,
                    page: page - 1,
                    isFilter: true,
                  });
                }}
              />
            </div>
          </div>
        )) || <NoDataFound />}
      </>
    );
  }, [clientDataSource]);

  const tabs = [
    {
      tabTitle: t(`CLIENTS_PAGE.COMMON.ASSIGNED`),
      inspectionState: "ASSIGNED",
      tabContent: tabsContent(),
    },
    {
      tabTitle: t(`CLIENTS_PAGE.COMMON.INPROGRESS`),
      inspectionState: "IN_PROGRESS",
      tabContent: tabsContent(),
    },
  ];

  if (!isMobile) {
    tabs.push({
      tabTitle: t(`CLIENTS_PAGE.COMMON.COMPLETED`),
      inspectionState: "COMPLETED",
      tabContent: completedContent(),
    });
  }

  return (
    <>
      <HTabs
        defaultIndex={Number(searchParams?.get("tab")) || 0}
        tabsData={tabs}
        onClickLoad
        tabContentCSSClasses="flex-1"
        onTabChange={(key, tabData) =>
          setFilterForm({
            ...filterForm,
            page: 0,
            inspectionState: tabData?.inspectionState,
            sort:
              tabData?.inspectionState === "ASSIGNED"
                ? "createdAt,desc"
                : "modifiedAt,desc",
            isFilter: true,
          })
        }
        tabTitleEnd={
          isMobile || (
            <>
              <Button
                title={t(`COMMON.ADD`) + " " + t(`COMMON.CLIENT`)}
                icon={
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className={"text-white text-xl"}
                  />
                }
                rounded={true}
                spaceXSmall={true}
                className="ml-auto text-sm mb-2 inline-block"
                onClick={() => {
                  setIsModalOpen(true);
                  reset();
                }}
              />
            </>
          )
        }
      />
      {/* Start Modal */}
      <Modal
        title="Client"
        open={isModalOpen}
        okText="Submit"
        onOk={() => onFormSubmit()}
        cancelText={t(`COMMON.CANCEL`)}
        onCancel={() => {
          setIsModalOpen(false);
          reset();
        }}
        okButtonProps={{
          disabled: btnLoading,
          className:
            "font-medium text-white hover:border-primary rounded bg-primary border border-primary",
        }}
        cancelButtonProps={{
          disabled: btnLoading,
          className:
            "font-medium hover:text-primary hover:border-primary rounded",
        }}
        centered
        width={800}
        isLoading={btnLoading}
      >
        <div className="grid md:grid-cols-2 gap-2 md:gap-4">
          <div className="">
            <label
              className="block text-gray-700 text-{8px} mb-1"
              for="{t(`COMMON.FIRST_NAME`)}"
            >
              {t(`COMMON.FIRST_NAME`)}
            </label>
            <Controller
              control={control}
              name="firstName"
              rules={validatormsg?.firstName}
              render={({ field }) => (
                <TextField
                  tabIndex="1"
                  {...field}
                  name="firstName"
                  placeholder={t(`COMMON.FIRST_NAME`)}
                  type="text"
                  value={getValues("firstName")}
                  onInput={(e) => {
                    setValue("firstName", e.target.value);
                    trigger("firstName");
                  }}
                  disabled={btnLoading}
                />
              )}
            />
            <FormErrMsg errors={errors?.firstName} />
          </div>

          <div className="">
            <label
              className="block text-gray-700 text-{8px} mb-1"
              for="{t(`COMMON.LAST_NAME`)}"
            >
              {t(`COMMON.LAST_NAME`)}
            </label>
            <Controller
              control={control}
              name="lastName"
              rules={validatormsg?.lastName}
              render={({ field }) => (
                <TextField
                  tabIndex="1"
                  {...field}
                  name="lastName"
                  placeholder={t(`COMMON.LAST_NAME`)}
                  type="text"
                  value={getValues("lastName")}
                  onInput={(e) => {
                    setValue("lastName", e.target.value);
                    trigger("lastName");
                  }}
                  disabled={btnLoading}
                />
              )}
            />
            <FormErrMsg errors={errors?.lastName} />
          </div>

          <div className="">
            <label
              className="block text-gray-700 text-{8px} mb-1"
              for="{t(`COMMON.EMAIL`)}"
            >
              {t(`COMMON.EMAIL`)}
            </label>
            <Controller
              control={control}
              name="email"
              rules={validatormsg?.email}
              render={({ field }) => (
                <TextField
                  tabIndex="1"
                  {...field}
                  name="email"
                  placeholder={t(`COMMON.EMAIL`)}
                  type="email"
                  value={getValues("email")}
                  onInput={(e) => {
                    setValue("email", e.target.value);
                    trigger("email");
                  }}
                  disabled={btnLoading}
                />
              )}
            />
            <FormErrMsg errors={errors?.email} />
          </div>
          <div className="">
            <label
              className="block text-gray-700 text-{8px} mb-1"
              for="{t(`COMMON.PHONE`)}"
            >
              {t(`COMMON.PHONE`)}
            </label>
            <Controller
              control={control}
              name="phoneNumber"
              rules={validatormsg?.phoneNumber}
              render={({ field }) => (
                <TextField
                  tabIndex="1"
                  {...field}
                  name="phoneNumber"
                  placeholder={t(`COMMON.PHONE`)}
                  type="number"
                  value={getValues("phoneNumber")}
                  min={0}
                  onInput={(e) => {
                    setValue("phoneNumber", e.target.value);
                    trigger("phoneNumber");
                  }}
                  disabled={btnLoading}
                />
              )}
            />
            <FormErrMsg errors={errors?.phoneNumber} />
          </div>
          <div className="">
            <label
              className="block text-gray-700 text-{8px} mb-1"
              for="{t(`COMMON.ADDRESS`)}"
            >
              {t(`COMMON.ADDRESS`)}
            </label>
            <Controller
              control={control}
              name="addressLine"
              rules={validatormsg?.addressLine}
              render={({ field }) => (
                <TextField
                  tabIndex="1"
                  {...field}
                  name="addressLine"
                  placeholder={t(`COMMON.ADDRESS`)}
                  type="text"
                  value={getValues("addressLine")}
                  onInput={(e) => {
                    setValue("addressLine", e.target.value);
                    trigger("addressLine");
                  }}
                  disabled={btnLoading}
                />
              )}
            />
            <FormErrMsg errors={errors?.addressLine} />
          </div>
          <div className="">
            <label
              className="block text-gray-700 text-{8px} mb-1"
              for="{t(`COMMON.LANDMARK`)}"
            >
              {t(`COMMON.LANDMARK`)}
            </label>
            <Controller
              control={control}
              name="landMark"
              rules={validatormsg?.landMark}
              render={({ field }) => (
                <TextField
                  tabIndex="1"
                  {...field}
                  name="landMark"
                  placeholder={t(`COMMON.LANDMARK`)}
                  type="text"
                  value={getValues("landMark")}
                  onInput={(e) => {
                    setValue("landMark", e.target.value);
                    trigger("landMark");
                  }}
                  disabled={btnLoading}
                />
              )}
            />
            <FormErrMsg errors={errors?.landMark} />
          </div>
          <div className="">
            <label
              className="block text-gray-700 text-{8px} mb-1"
              for="{t(`COMMON.CITY`)}"
            >
              {t(`COMMON.CITY`)}
            </label>
            <Controller
              control={control}
              name="city"
              rules={validatormsg?.city}
              render={({ field }) => (
                <TextField
                  tabIndex="1"
                  {...field}
                  name="city"
                  placeholder={t(`COMMON.CITY`)}
                  type="text"
                  value={getValues("city")}
                  onInput={(e) => {
                    setValue("city", e.target.value);
                    trigger("city");
                  }}
                  disabled={btnLoading}
                />
              )}
            />
            <FormErrMsg errors={errors?.city} />
          </div>
          <div className="">
            <label
              className="block text-gray-700 text-{8px} mb-1"
              for="{t(`COMMON.ZIPCODE`)}"
            >
              {t(`COMMON.ZIPCODE`)}
            </label>
            <Controller
              control={control}
              name="zipCode"
              rules={validatormsg?.zipCode}
              render={({ field }) => (
                <TextField
                  tabIndex="1"
                  {...field}
                  name="zipCode"
                  placeholder={t(`COMMON.ZIPCODE`)}
                  type="number"
                  value={getValues("zipCode")}
                  min="0"
                  onInput={(e) => {
                    setValue("zipCode", e.target.value);
                    trigger("zipCode");
                  }}
                  disabled={btnLoading}
                />
              )}
            />
            <FormErrMsg errors={errors?.zipCode} />
          </div>

          <div className="">
            <label
              className="block text-gray-700 text-{8px} mb-1"
              for="{t(`CLIENTS_PAGE.COMMON.PROPERTY_TYPE`)}"
            >
              {t(`CLIENTS_PAGE.COMMON.PROPERTY_TYPE`)}
            </label>
            <Controller
              control={control}
              name="propertyType"
              rules={validatormsg?.propertyType}
              render={({ field }) => (
                <select
                  className="ant-input px-3 py-1.5 shadow-none border-typo-light focus-within:ring-1 focus-within:border-primary focus-within:ring-primary"
                  tabIndex="1"
                  {...field}
                  name="propertyType"
                  value={getValues("propertyType")}
                  onInput={(e) => {
                    setValue("propertyType", e.target.value);
                    trigger("propertyType");
                  }}
                  disabled={btnLoading}
                >
                  <option>Select Property Type</option>
                  <option value="HOUSE">House</option>
                  <option value="APARTMENT">Apartment</option>
                  <option value="OFFICE">Office</option>
                </select>
              )}
            />
            <FormErrMsg errors={errors?.propertyType} />
          </div>
          {hasPermissions.admin(CurrentUser?.role) && (
            <div className="">
              <label
                className="block text-gray-700 text-{8px} mb-1"
                for="{t(`CLIENTS_PAGE.COMMON.ASSIGN_EMPLOYEE`)}"
              >
                {t(`CLIENTS_PAGE.COMMON.ASSIGN_EMPLOYEE`)}
              </label>
              <Controller
                control={control}
                name="employeeId"
                rules={validatormsg?.employeeId}
                render={({ field }) => (
                  <select
                    className="ant-input px-3 py-1.5 shadow-none border-typo-light focus-within:ring-1 focus-within:border-primary focus-within:ring-primary capitalize"
                    tabIndex="1"
                    {...field}
                    name="employeeId"
                    value={getValues("employeeId")}
                    onInput={(e) => {
                      setValue("employeeId", e.target.value);
                      trigger("employeeId");
                    }}
                    disabled={btnLoading}
                  >
                    <option value={CurrentUser?.userId}>
                      {t(`CLIENTS_PAGE.COMMON.ASSIGN_MY_SELF`)}
                    </option>
                    {empDataSource.map((data) => {
                      return (
                        <option value={data?.userId} key={data?.userId}>
                          {data?.name?.firstName +
                            " " +
                            data?.name?.lastName +
                            " " +
                            getValues("employeeId")}
                        </option>
                      );
                    })}
                  </select>
                )}
              />
              <FormErrMsg errors={errors?.employeeId} />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ClientsPresentational;
