import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import notification from "utils/notification";
import InspectionService from "services/InspectionService";
import MaterialTypesPresentational from "./material-types-presentational";
import setLoader from "utils/loader";

const MaterialTypes = () => {
  const { t } = useTranslation();
  const [inspaectionCategories, setInspaectionCategories] = useState([]);

  const [filterForm] = useState({
    page: 0,
    size: 0,
    isTemplateCategory: false,
    isSubCategoryNeed: true,
  });

  const getInspectionMenu = useCallback(() => {
    setLoader(true);
    InspectionService.getInspectionMenu(filterForm)
      .then((res) => {
        setLoader(false);
        if (res?.data?.content) {
          setInspaectionCategories(res?.data?.content);
        } else if (parseInt(res.status, 0) === 204) {
          notification.info(t(`TOASTR.API.DATA_NOT_FOUND`));
        }
      })
      .catch((e) => {
        notification.error(e);
        setLoader(false);
      });
  }, [t, filterForm]);

  useEffect(() => {
    getInspectionMenu();
  }, [getInspectionMenu]);

  return (
    <MaterialTypesPresentational
      inspaectionCategories={inspaectionCategories}
    />
  );
};

export default MaterialTypes;
