import React from 'react';
import HTabs from "components/tabs/h-tabs";
import DocumentTemplateContent from './document-tempalate-content';

const DocumentTemplatesPresentational = ({
  pageLoader,
  setPageLoader,
  templateCategory,
}) => {
  const templatesTabs = templateCategory?.map(template => {
    return {
      tabTitle: template?.name,
      tabContent: (
        <DocumentTemplateContent
          categoryId={template?.categoryId}
          pageLoader={pageLoader}
          setPageLoader={setPageLoader}
        />
      )
    };
  });

  return (
    <>
      <HTabs tabsData={templatesTabs} tabTittleSize="text-base" onClickLoad={true} />
    </>
  );
}
 
export default DocumentTemplatesPresentational;