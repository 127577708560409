import axios from "axios";
import { notificationUrl } from "urls/urls";

const getNotificationList = (params = {}) => {
    return axios.get(notificationUrl.NOTIFICATION, { params });
};

const getNotificationCount = (params = {}) => {
    return axios.get(notificationUrl.NOTIFICATION + '-count', { params });
};

const NotificationService = {
    getNotificationList,
    getNotificationCount,
};

export default NotificationService;
