import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DashboardService from "services/DashboardService";
import UserService from "services/UserService";
import DashboardPresentational from "./dashboard-presentational";
import setLoader from "utils/loader";

const DashboardFunctional = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [btnLoading, setBtnLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);

  // Get Dashboard Data
  const [filterForm, setFilterForm] = useState({
    dateType: "TODAY",
    isFilter: true,
  });

  useEffect(() => {
    if (filterForm && filterForm.isFilter) {
      getDashboardData();
    }
  }, [filterForm]);

  const getDashboardData = (key = "") => {
    setLoader(true);
    DashboardService.getDashboardData(filterForm.dateType)
      .then((res) => {
        setLoader(false);
        if (res?.data) {
          setDashboardData(res?.data);
        } else {
          setDashboardData([]);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  // Get Client List
  const [clientDataSource, setClientDataSource] = useState([]);
  const [clientFilterForm, setClinetFilterForm] = useState({
    size: 100,
    page: 0,
    total: 0,
    search: "",
    isFilter: true,
    inspectionState: "ASSIGNED",
    sort: "createdAt,desc",
  });
  useEffect(() => {
    if (clientFilterForm && clientFilterForm.isFilter) {
      getClientList();
    }
  }, [clientFilterForm]);

  const getClientList = (key = "") => {
    setLoader(true);
    let params = {
      ...clientFilterForm,
    };
    UserService.getUserClientList(params)
      .then((res) => {
        setLoader(false);
        if (res?.data?.content) {
          setClientDataSource(res.data.content);
          setClinetFilterForm({
            ...clientFilterForm,
            page: res.data.page.number,
            total: res.data.page.totalElements,
            isFilter: false,
          });
        } else {
          setClientDataSource([]);
        }
      })
      .catch(() => {
        setLoader(false);
        setClinetFilterForm({
          ...clientFilterForm,
          isFilter: false,
        });
      });
  };

  return (
    <DashboardPresentational
      dashboardData={dashboardData}
      filterForm={filterForm}
      setFilterForm={setFilterForm}
      clientDataSource={clientDataSource}
      clientFilterForm={clientFilterForm}
      setClinetFilterForm={setClinetFilterForm}
      btnLoading={btnLoading}
    />
  );
};
export default DashboardFunctional;
