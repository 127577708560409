import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import notification from "utils/notification";
import InspectionService from "services/InspectionService";
import DocumentTemplatesPresentational from "./document-templates-presentational";
import setLoader from "utils/loader";

const DocumentTemplates = () => {
  const { t } = useTranslation();
  const [pageLoader, setPageLoader] = useState(true);
  const [templateCategory, setTemplateCategory] = useState([]);

  const [filterForm] = useState({
    page: 0,
    size: 0,
    isTemplateCategory: true,
  });

  const getDocumentTemplatesMenu = useCallback(() => {
    setPageLoader(true);
    setLoader(true);
    InspectionService.getInspectionMenu(filterForm)
      .then((res) => {
        setPageLoader(false);
        setLoader(false);
        if (res?.data?.content) {
          setTemplateCategory(res?.data?.content);
        } else if (parseInt(res.status, 0) === 204) {
          notification.info(t(`TOASTR.API.DATA_NOT_FOUND`));
        }
      })
      .catch((e) => {
        notification.error(e);
        setPageLoader(false);
        setLoader(false);
      });
  }, [t, filterForm]);

  useEffect(() => {
    getDocumentTemplatesMenu();
  }, [getDocumentTemplatesMenu]);

  return (
    <DocumentTemplatesPresentational
      pageLoader={pageLoader}
      setPageLoader={setPageLoader}
      templateCategory={templateCategory}
    />
  );
};

export default DocumentTemplates;
