import axios from "axios";
import { paymentUrl } from "urls/urls";

const paymentChange = (body = {}) => {
  return axios.patch(paymentUrl.payment, body);
};

const createPaymentToken = (body) => {
  return axios.post(paymentUrl.CREATE_PAYMENT_TOKEN, body);
};

const getPaymentData = (params = {}) => {
  const headers = {
    skipAuth: true,
    skipErrors: true,
  };

  return axios.get(paymentUrl.PAYMENT_CUS_DATA_URL, {
    headers: headers,
    params,
  });
};

const paymentStateUpdateService = (request) => {
  const headers = {
    skipAuth: true,
    skipErrors: true,
  };
  return axios.put(paymentUrl.INSPECTION_PAYMENT_URL, request, {
    headers: headers,
  });
};

const PaymentService = {
  paymentChange,
  createPaymentToken,
  getPaymentData,
  paymentStateUpdateService,
};

export default PaymentService;
