import { notification as Notification } from "antd";
// import loadable from "@loadable/component";
// import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

// const FontAwesomeIcon = loadable(
//   () => import("@fortawesome/react-fontawesome"),
//   {
//     resolveComponent: (components) => components.FontAwesomeIcon,
//   }
// );

const notify = (messege = "", type = "success", duration = 2) => {
  if (!["success", "info", "warning", "error"].includes(type)) {
    type = "info";
  }

  const customMainClassesDefault = "border-t-8 rounded-md";
  const customMainClasses = {
    success: `${customMainClassesDefault} border-green-800`,
    info: `${customMainClassesDefault} border-blue-500`,
    warning: `${customMainClassesDefault} border-yellow-500`,
    error: `${customMainClassesDefault} border-red-700`,
  };

  if (messege) {
    Notification.open({
      message: (
        <span className="inline-flex mt-0.5 mr-2 font-semibold">{messege}</span>
      ),
      className: customMainClasses[type],
      duration: duration || 2,
      closeIcon: " ",
      // closeIcon: <FontAwesomeIcon icon={faCircleXmark} className="text-red-600" size="2xl"/>
    });
  }
};

const notification = {
  success: (messege = "", duration = false) =>
    notify(messege, "success", duration),
  info: (messege = "", duration = false) => notify(messege, "info", duration),
  warning: (messege = "", duration = false) =>
    notify(messege, "warning", duration),
  error: (messege = "", duration = false) => notify(messege, "error", duration),
};
export default notification;
