import store from "store/createStore";
import secureLocalStorage from "react-secure-storage";
import LoginService from "./LoginService";
import UserService from "./UserService";

const updateTokenState = (token = null) => {
  store?.dispatch({ type: "SET_ACCESS_TOKEN", payload: token });
};

const setToken = async (token) => {
  secureLocalStorage.setItem("CURRENT_SESSION", new Date().getTime());
  secureLocalStorage.setItem("ACCESS_TOKEN", token);
  updateTokenState(token);
};

const getToken = () => {
  const accessToken = secureLocalStorage.getItem("ACCESS_TOKEN");
  const accessTokenState = store?.getState()?.authReducer;
  if (accessToken && accessTokenState === "init") {
    updateTokenState(accessToken);
  }
  return accessToken;
};

const removeToken = () => {
  secureLocalStorage.removeItem("ACCESS_TOKEN");
};

const setRefreshToken = (token) => {
  secureLocalStorage.setItem("REFRESH_TOKEN", token);
};

const getRefreshToken = () => {
  return secureLocalStorage.getItem("REFRESH_TOKEN");
};

const removeRefreshToken = () => {
  secureLocalStorage.removeItem("REFRESH_TOKEN");
};

const verifyActiveSession = () => {
  let expires = 24; // in hours
  const CurrentSession = secureLocalStorage.getItem("CURRENT_SESSION");
  if (
    !CurrentSession ||
    (CurrentSession &&
      new Date().getTime() - CurrentSession > expires * 60 * 60 * 1000)
  ) {
    return false;
  }
  return true;
};

const setCurrentUser = (data) => {
  secureLocalStorage.setItem("CURRENT_USER", data);
};

const getCurrentUser = () => {
  const accessToken = getToken();
  if (!accessToken) {
    return {};
  }
  const currentUser = secureLocalStorage.getItem("CURRENT_USER");
  if (!currentUser) {
    updateCurrentUser(false);
  }
  return currentUser;
};

const updateCurrentUser = (notifyCount = true) => {
  UserService.currentUser().then((res) => {
    if (notifyCount) {
      res.data.notifyCount = getCurrentUser()?.notifyCount || null;
    }
    setCurrentUser(res.data);
    return res.data;
  });
};

const removeCurrentUser = () => {
  secureLocalStorage.removeItem("CURRENT_USER");
};

const clearSecureLocalStorage = async () => {
  secureLocalStorage.clear();
  updateTokenState();
  localStorage.setItem("lng", "en");
};

const updateToken = async () => {
  const refreshToken = await getRefreshToken();
  try {
    const refreshResponse = await LoginService?.refreshToken(refreshToken);
    if (refreshResponse?.data?.accessToken) {
      setToken(refreshResponse?.data?.accessToken);
      updateTokenState(refreshResponse?.data?.accessToken);
      if (refreshResponse?.data?.refreshToken) {
        setRefreshToken(refreshResponse?.data?.refreshToken);
      }
      updateCurrentUser();
      return refreshResponse?.data?.accessToken;
    }
    clearSecureLocalStorage();
  } catch (e) {
    clearSecureLocalStorage();
  }
};

const logout = async () => {
  try {
    await LoginService?.logout();
  } catch (e) {
  } finally {
    clearSecureLocalStorage();
  }
};

const AuthService = {
  setToken,
  getToken,
  removeToken,
  updateToken,
  setRefreshToken,
  getRefreshToken,
  removeRefreshToken,
  verifyActiveSession,
  setCurrentUser,
  getCurrentUser,
  removeCurrentUser,
  clearSecureLocalStorage,
  logout,
};

export default AuthService;
