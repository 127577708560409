import React from "react";
import { Outlet } from "react-router-dom";
import { logoTransparent } from "assets/images/images";

const PaymentLayout = () => {
  return (
    <div className="min-h-screen flex items-center bg-light">
      <div className="w-full h-full p-6 flex items-center justify-center">
        <div className="w-1/2 max-w-full  bg-white border border-typo-light rounded shadow-md">
          <div className="app-logo-div bg-dark h-full  px-3 py-2 mx-auto mb-6">
            <img src={logoTransparent} alt={"App Logo"} />
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PaymentLayout;
