import React, { useState, useMemo, useCallback } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { useParams } from "react-router-dom";
import HTabs from "components/tabs/h-tabs";
import Button from "components/Button";
import Editor from "components/richtext-ediotor/richtext-editor";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFloppyDisk,
  faPen,
  faPlus,
  faPlusCircle,
  faSpinner,
  faClose,
  faTrash,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { Select, Image } from "antd";
import confirm from "antd/lib/modal/confirm";
import InspectionMenu from "components/InspectionMenu";
import Checkbox from "components/checkbox";
import InspectionMenuMobile from "components/InspectionMenuMobile";
import InspectionImage from "components/InspectionImage";
import ImageEditor from "components/ImageEditor";
import Modal from "components/Modal";
import TextField from "components/form-fields/text-fields";
import setLoader from "utils/loader";

const RichTextEditor = ({
  resetEditor,
  setResetEditor,
  selectedTemplate,
  setSelectedTemplate,
  templateContent,
  templateEditorData,
  setTemplateEditorData,
}) => {
  const [selectChange, setSelectChange] = useState(false);
  const templateOptions = useMemo(
    () =>
      templateContent?.map((template) => {
        return {
          label: template?.templateName,
          value: Number(template?.documentTemplateId),
        };
      }),
    [templateContent]
  );

  const templateChange = (value) => {
    setSelectChange(true);
    setSelectedTemplate(value);
    setResetEditor("clear");
  };

  const findSelectedTemplate = (templateContent) => {
    return templateContent.filter((template) => {
      return Number(template?.documentTemplateId) === Number(selectedTemplate);
    });
  };

  const onEditorStateChange = (textContent) => {
    if (resetEditor === "clear") {
      const selectedTemplateArray = findSelectedTemplate(templateContent);
      if (selectChange) {
        setTemplateEditorData(selectedTemplateArray?.[0]?.content || "");
      } else {
        setTemplateEditorData(templateEditorData);
      }
      setResetEditor("cleared");
    } else if (resetEditor === "cleared") {
      // const selectedTemplateArray = findSelectedTemplate(templateContent)
      // setTemplateEditorData(selectedTemplateArray?.[0]?.content)
      setTemplateEditorData(templateEditorData);
      setResetEditor(null);
    } else {
      setTemplateEditorData(textContent);
      if (resetEditor !== null) {
        setResetEditor(null);
      }
    }
  };

  const toolbarBefore = (
    <Select
      placeholder="Choose Template"
      className="min-w-[220px] max-w-full mr-auto mb-4"
      // defaultValue={selectedTemplate}
      value={selectedTemplate}
      onChange={(value) => templateChange(value)}
      options={[
        {
          label: "Choose Template",
          value: 0,
        },
        ...templateOptions,
      ]}
    />
  );

  return (
    <>
      <Editor
        defaultValue={templateEditorData}
        onEditorStateChange={onEditorStateChange}
        resetEditor={resetEditor}
        setResetEditor={setResetEditor}
        toolbarBefore={toolbarBefore}
      />
    </>
  );
};

const MaterialData = ({
  materialData,
  materialTypes,
  setMaterialTypes,
  materialEdit,
  setMaterialEdit,
  createMaterialData,
  updateMaterialData,
  deleteMaterialData,
}) => {
  const { t } = useTranslation();
  // const [materialEdit, setMaterialEdit] = useState(false); // Track for construction type is in edit mode
  const [newMaterial, setNewMaterial] = useState("");
  const [materialUpdate, setMaterialUpdate] = useState(-1);
  const [updateMaterial, setUpdateMaterial] = useState("");
  const showDeleteConfirm = useCallback(
    (id) => {
      confirm({
        title: t(`COMMON.CONFIRM_DELETE`),
        icon: (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size={"xl"}
            className="float-left mr-4 text-red-600"
          />
        ),
        okText: t(`COMMON.YES`),
        okButtonProps: {
          className:
            "font-medium text-gray-600 hover:text-primary border-gray-300 hover:border-primary rounded",
        },
        okType: "danger",
        cancelText: t(`COMMON.CANCEL`),
        cancelButtonProps: {
          className:
            "font-medium rounded text-white bg-primary border border-primary hover:border-primary",
        },
        className: "rounded",
        onOk() {
          deleteMaterialData(id);
        },
        onCancel() {},
      });
    },
    [t, deleteMaterialData]
  );

  return (
    <>
      <div className="bg-[#F7F7F9] rounded-md border-2 p-3 flex flex-col border-gray mb-4">
        <div className="flex flex-wrap items-center last:mt-2">
          <h2 className="font-bold mb-2">{t(`INSPECTION_PAGE.COMMON.TYPE`)}</h2>
          <div
            className="bg-[#FCAE01] rounded-full px-3 cursor-pointer ml-auto mb-2"
            onClick={() => {
              setMaterialEdit(!materialEdit);
            }}
          >
            {/* <FontAwesomeIcon
              icon={materialEdit ? faClose : faPen}
              className={"text-white text-[14px] mr-2"}
            /> */}
            <span className="inline-block text-white text-sm">
              {materialEdit ? t("COMMON.DONE") : t("COMMON.ADD")}
            </span>
          </div>
        </div>
        {materialData.map((material, index) => {
          if (materialEdit && material?.isDelete) {
            return;
          }
          return (
            <Checkbox
              key={index}
              hideBox={materialEdit}
              label={material?.name}
              value={[...materialTypes]?.includes(material?.id)}
              onClick={() => {
                if ([...materialTypes]?.includes(material?.id)) {
                  // let newMaterialTypes = [...materialTypes];
                  // newMaterialTypes.splice(index, 1);
                  // // console.log(newMaterialTypes);
                  // setMaterialTypes([...newMaterialTypes]);
                  setMaterialTypes((materialTypes) =>
                    materialTypes.filter((id) => {
                      return id !== material?.id;
                    })
                  );
                } else {
                  let newMaterialTypes = [...materialTypes];
                  newMaterialTypes.push(material?.id);
                  // console.log(materialTypes);
                  setMaterialTypes([...newMaterialTypes]);
                }
              }}
            />
          );
        })}
        {materialEdit && (
          <>
            {materialData.map((material) => {
              if (material?.isDelete) {
                return (
                  <>
                    <div
                      key={material?.id}
                      className="flex flex-row items-center"
                    >
                      {materialUpdate === material?.id ? (
                        <TextField
                          className="border border-gray rounded-md min-h-[40px] px-3 py-2 outline-none my-2 flex flex-1"
                          placeholder="Material"
                          value={updateMaterial}
                          onChange={(e) => {
                            setUpdateMaterial(e.target.value);
                          }}
                        />
                      ) : (
                        <div className="border border-gray rounded-md min-h-[40px] px-3 py-2 outline-none my-2 flex flex-1">
                          {material?.name}
                        </div>
                      )}
                      <div
                        className="h-[30px] w-[30px] bg-green-500 rounded-full flex justify-center items-center ml-4 cursor-pointer aria-disabled:opacity-30 aria-disabled:pointer-events-none"
                        onClick={() => {
                          if (materialUpdate === material?.id) {
                            if (
                              updateMaterial &&
                              material?.name !== updateMaterial
                            ) {
                              updateMaterialData(material?.id, updateMaterial);
                              setMaterialUpdate(-1);
                              setUpdateMaterial("");
                            }
                          } else {
                            setUpdateMaterial(material?.name);
                            setMaterialUpdate(material?.id);
                          }
                        }}
                        aria-disabled={
                          materialUpdate === material?.id &&
                          (material?.name === updateMaterial || !updateMaterial)
                        }
                      >
                        <FontAwesomeIcon
                          icon={
                            materialUpdate === material?.id
                              ? faFloppyDisk
                              : faPen
                          }
                          className={"text-white text-[14px]"}
                        />
                      </div>
                      <div
                        className="h-[30px] w-[30px] bg-red-500 rounded-full flex justify-center items-center ml-4 cursor-pointer"
                        onClick={() => {
                          if (materialUpdate === material?.id) {
                            setMaterialUpdate(-1);
                            setUpdateMaterial("");
                          } else {
                            showDeleteConfirm(material?.id);
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          icon={
                            materialUpdate === material?.id ? faClose : faTrash
                          }
                          className={"text-white text-[14px]"}
                        />
                      </div>
                    </div>
                  </>
                );
              }
            })}
            <div className="flex flex-row justify-between items-center">
              <TextField
                className="border border-gray rounded-md min-h-[40px] px-3 py-2 outline-none my-2 flex flex-1"
                placeholder="Material"
                value={newMaterial}
                onChange={(e) => {
                  setNewMaterial(e.target.value);
                }}
              />
              <div className="flex flex-row">
                <div
                  className="h-[30px] w-[30px] bg-primary rounded-full flex justify-center items-center ml-4 cursor-pointer aria-disabled:opacity-30 aria-disabled:pointer-events-none"
                  onClick={() => {
                    if (newMaterial) {
                      createMaterialData(newMaterial);
                      setNewMaterial("");
                    }
                  }}
                  aria-disabled={!newMaterial}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className={"text-white text-[14px]"}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const SubCategory = ({
  subCategoryData,
  setSubCategoryData,
  subCategoryTypeData,
  setSubCategoryTypeData,
  quickNoteData,
  uploadImage,
  imageUploadLoading,
  setShowImageEdit,
  showImageEdit,
  showMarkerArea,
  imageRef,
  editImageURL,
  setEditImageURL,
  setEditImageId,
  isDescriptionRequired,
}) => {
  const { t } = useTranslation();
  const params = useParams();

  const [imgPreview, setImgPreview] = useState([
    {
      status: false,
      imgUrl: null,
    },
  ]);
  const quickNoteOptions = useMemo(
    () =>
      quickNoteData?.map((quickNote) => {
        return {
          label: quickNote?.note,
          value: quickNote?.note,
        };
      }),
    [quickNoteData]
  );

  // Sort Sub Category
  const onSortEndSubCategory = (oldIndex: number, newIndex: number) => {
    let subCategoryDataAttch = subCategoryData?.attachments;
    let itemArr = arrayMoveImmutable(subCategoryDataAttch, oldIndex, newIndex);
    itemArr.map((image, index) => {
      itemArr[index].position = index;
    });
    setSubCategoryData({
      ...subCategoryData,
      attachments: [...itemArr],
    });
    // console.log(itemArr);
  };

  return (
    <>
      <div className="bg-[#F7F7F9] rounded-md border-2 px-3 py-5 flex flex-col border-gray mb-4">
        <div className="w-full">
          {params?.submenu &&
            isDescriptionRequired[params?.menu][0][params?.submenu] ===
              true && (
              <div>
                {quickNoteData.length >= 1 && (
                  <Select
                    placeholder={t(`INSPECTION_PAGE.COMMON.CHOOSE_NOTE`)}
                    className="min-w-[220px] max-w-full ml-auto mb-4"
                    options={[
                      {
                        label: t(`INSPECTION_PAGE.COMMON.CHOOSE_NOTE`),
                        value: 0,
                      },
                      ...quickNoteOptions,
                    ]}
                    onChange={(value) => {
                      setSubCategoryData({
                        ...subCategoryData,
                        description: value,
                      });
                    }}
                    value={0}
                  />
                )}
                <div className="mb-4">
                  <textarea
                    className="w-full border border-gray rounded-md min-h-[160px] p-3 outline-none"
                    placeholder="Take Note"
                    value={subCategoryData?.description || ""}
                    onChange={(e) => {
                      setSubCategoryData({
                        ...subCategoryData,
                        description: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            )}

          <div className="flex flex-wrap">
            {imageUploadLoading ? (
              <div className="h-[120px] w-[120px] rounded-md bg-[#FEEFCC] border-[#FCAE01] border-[1px] flex justify-center items-center cursor-pointer mr-4 mb-4">
                <div className="flex flex-col justify-center items-center">
                  <div className="animate-spin">
                    <FontAwesomeIcon icon={faSpinner} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="mr-4 mb-4">
                <ImageEditor
                  callBack={async (data) => {
                    let attachments = [];
                    if (subCategoryData?.attachments?.length > 0) {
                      attachments = [...subCategoryData?.attachments];
                    }
                    const imageArr = await uploadImage(data);
                    let attachmentLength =
                      attachments?.length > 0 ? attachments?.length : 0;
                    imageArr.map((image, index) => {
                      if (image?.attachmentId) {
                        attachments?.push({
                          imageId: image?.attachmentId,
                          imageUrl: image?.url,
                          position: attachmentLength + index,
                        });
                        setSubCategoryData({
                          ...subCategoryData,
                          attachments: [...attachments],
                        });
                      }
                    });

                    // Old Code
                    // data.map(async (e) => {
                    //   const image = await uploadImage(e);
                    //   if (image?.imageId) {
                    //     attachments?.push(image);
                    //     setSubCategoryData({
                    //       ...subCategoryData,
                    //       attachments: [...attachments],
                    //     });
                    //   }
                    // });
                  }}
                >
                  <div className="h-[120px] w-[120px] rounded-md bg-[#FEEFCC] border-[#FCAE01] border-[1px] flex justify-center items-center cursor-pointer min-w-[120px]">
                    <div className="flex flex-col justify-center items-center">
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        className={"text-[#FCAE01] text-[30px]"}
                      />
                      <p className="mt-1 mb-0">{t("COMMON.IMAGE")}</p>
                    </div>
                  </div>
                </ImageEditor>
              </div>
            )}

            <Image.PreviewGroup>
              <SortableList
                onSortEnd={onSortEndSubCategory}
                className="drag-list"
                draggedItemClassName="drag-dragged"
              >
                {subCategoryData?.attachments?.length > 0 &&
                  subCategoryData?.attachments?.map((image, index) => {
                    return (
                      <SortableItem key={image.imageId}>
                        <div key={index} className="drag-item">
                          <InspectionImage
                            key={index}
                            data={image}
                            onPreview={() => {
                              setImgPreview([
                                {
                                  status: true,
                                  imgUrl: image?.imageUrl,
                                },
                              ]);
                            }}
                            onEdit={() => {
                              localStorage.setItem(
                                "editImageId",
                                image?.imageId
                              );
                              localStorage.setItem(
                                "editImageType",
                                "subCategory"
                              );
                              setEditImageId(image?.imageId);
                              setLoader(true);
                              fetch(image?.imageUrl)
                                .then((res) => res.blob())
                                .then((blob) => {
                                  try {
                                    let objectURL = URL.createObjectURL(blob);
                                    setEditImageURL(objectURL);
                                    setShowImageEdit(true);
                                    setTimeout(() => {
                                      showMarkerArea();
                                    }, 1000);
                                  } catch (error) {
                                    setShowImageEdit(false);
                                    // console.log(error);
                                  }
                                  setLoader(false);
                                })
                                .catch((err) => {
                                  setLoader(true);
                                });
                            }}
                            onDelete={() => {
                              let attachments = [];
                              if (subCategoryData?.attachments?.length > 0) {
                                attachments = [...subCategoryData?.attachments];
                              }
                              attachments.splice(index, 1);
                              attachments.map((img, scNum) => {
                                attachments[scNum].position = scNum;
                              });
                              setSubCategoryData({
                                ...subCategoryData,
                                attachments: [...attachments],
                              });
                            }}
                          />
                        </div>
                      </SortableItem>
                    );
                  })}
              </SortableList>
            </Image.PreviewGroup>
          </div>
        </div>
      </div>

      {subCategoryTypeData?.length > 0 &&
        [...subCategoryTypeData]?.map((data, index) => {
          // console.log(data)
          return (
            <div
              key={index}
              className="bg-[#F7F7F9] rounded-md border-2 px-3 py-5 flex flex-col border-gray mb-4"
            >
              <div className="w-full" key={index}>
                <div className="mb-4">
                  <div className="flex flex-wrap items-center text-gray-700 text-sm">
                    {data?.subCategoryTypeId || data?.id ? (
                      <span className="mb-3 font-bold">
                        {data?.subCategoryTypeName}
                      </span>
                    ) : (
                      <>
                        <TextField
                          className="border border-gray rounded-md px-3 py-2 outline-none mb-3 flex flex-1"
                          placeholder="Name"
                          value={data?.subCategoryTypeName}
                          onChange={(e) => {
                            // const newData = subCategoryTypeData;
                            // newData[index].subCategoryTypeName = e.target.value;
                            // setSubCategoryTypeData([...newData]);
                            setSubCategoryTypeData((subCategoryTypeData) =>
                              subCategoryTypeData.map((obj, i) => {
                                if (i === index) {
                                  return {
                                    ...obj,
                                    subCategoryTypeName: e.target.value,
                                  };
                                }
                                return obj;
                              })
                            );
                          }}
                        />
                        <div
                          className="h-[30px] w-[30px] bg-red-500 rounded-full flex justify-center items-center cursor-pointer mb-3 mx-3 last:mr-0"
                          onClick={() => {
                            // let newData = [...subCategoryTypeData];
                            // newData.splice(index, 1);
                            // setSubCategoryTypeData([...newData]);
                            setSubCategoryTypeData((subCategoryTypeData) =>
                              subCategoryTypeData.filter((obj, i) => {
                                return i !== index;
                              })
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className={"text-white text-[14px]"}
                          />
                        </div>
                      </>
                    )}
                    {quickNoteData.length >= 1 && (
                      <Select
                        placeholder={t(`INSPECTION_PAGE.COMMON.CHOOSE_NOTE`)}
                        className="min-w-[220px] max-w-full ml-auto mb-3"
                        options={[
                          {
                            label: t(`INSPECTION_PAGE.COMMON.CHOOSE_NOTE`),
                            value: 0,
                          },
                          ...quickNoteOptions,
                        ]}
                        onChange={(value) => {
                          // const newData = subCategoryTypeData;
                          // newData[index].description = value;
                          // setSubCategoryTypeData([...newData]);
                          setSubCategoryTypeData((subCategoryTypeData) =>
                            subCategoryTypeData.map((obj, i) => {
                              if (i === index) {
                                return { ...obj, description: value };
                              }
                              return obj;
                            })
                          );
                        }}
                        value={0}
                      />
                    )}
                  </div>
                  <textarea
                    className="w-full border border-gray rounded-md min-h-[160px] p-3 outline-none"
                    placeholder="Take Note"
                    value={data?.description}
                    onChange={(e) => {
                      // const newData = subCategoryTypeData;
                      // newData[index].description = e.target.value;
                      // setSubCategoryTypeData([...newData]);
                      setSubCategoryTypeData((subCategoryTypeData) =>
                        subCategoryTypeData.map((obj, i) => {
                          if (i === index) {
                            return { ...obj, description: e.target.value };
                          }
                          return obj;
                        })
                      );
                    }}
                  />
                </div>
                <div className="flex flex-wrap">
                  {imageUploadLoading ? (
                    <div className="h-[120px] w-[120px] rounded-md bg-[#FEEFCC] border-[#FCAE01] border-[1px] flex justify-center items-center cursor-pointer mr-4 mb-4">
                      <div className="flex flex-col justify-center items-center">
                        <div className="animate-spin">
                          <FontAwesomeIcon icon={faSpinner} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mr-4 mb-4">
                      <ImageEditor
                        callBack={async (imageData) => {
                          let attachments = [];
                          if (data?.attachments?.length > 0) {
                            attachments = [...data?.attachments];
                          }
                          if (imageData?.length > 0) {
                            const imageArr = await uploadImage(imageData);
                            let attachmentLength =
                              attachments?.length > 0 ? attachments?.length : 0;
                            imageArr.map((img, inum) => {
                              if (img?.attachmentId) {
                                attachments?.push({
                                  imageId: img?.attachmentId,
                                  imageUrl: img?.url,
                                  position: attachmentLength + inum,
                                });
                                setSubCategoryTypeData((subCategoryTypeData) =>
                                  subCategoryTypeData.map((obj, i) => {
                                    if (i === index) {
                                      return {
                                        ...obj,
                                        attachments: [...attachments],
                                      };
                                    }
                                    return obj;
                                  })
                                );
                              }
                            });

                            // Old Code
                            // imageData.map(async (e) => {
                            //   const image = await uploadImage(e);
                            //   if (image?.imageId) {
                            //     attachments?.push(image);
                            //   }
                            //   setSubCategoryTypeData((subCategoryTypeData) =>
                            //     subCategoryTypeData.map((obj, i) => {
                            //       if (i === index) {
                            //         return {
                            //           ...obj,
                            //           attachments: [...attachments],
                            //         };
                            //       }
                            //       return obj;
                            //     })
                            //   );
                            // });
                          }
                        }}
                      >
                        <div className="h-[120px] w-[120px] rounded-md bg-[#FEEFCC] border-[#FCAE01] border-[1px] flex justify-center items-center cursor-pointer min-w-[120px]">
                          <div className="flex flex-col justify-center items-center">
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              className={"text-[#FCAE01] text-[30px]"}
                            />
                            <p className="mt-1 mb-0">{t("COMMON.IMAGE")}</p>
                          </div>
                        </div>
                      </ImageEditor>
                    </div>
                  )}
                  <SortableList
                    onSortEnd={(oldIndex: number, newIndex: number) => {
                      // let attachments = [];
                      if (data?.attachments?.length > 0) {
                        let subCategoryDataTypeAttch = data?.attachments;
                        let dataArr = arrayMoveImmutable(
                          subCategoryDataTypeAttch,
                          oldIndex,
                          newIndex
                        );
                        dataArr.map((img, num) => {
                          dataArr[num].position = num;
                        });

                        setSubCategoryTypeData((subCategoryTypeData) =>
                          subCategoryTypeData.map((obj, i) => {
                            if (i === index) {
                              return {
                                ...obj,
                                attachments: dataArr,
                              };
                            }
                            return obj;
                          })
                        );
                      }
                      // console.log(dataArr);
                    }}
                    className="drag-list"
                    draggedItemClassName="drag-dragged"
                  >
                    {data?.attachments?.length > 0 &&
                      [...data?.attachments]?.map((image, key) => {
                        return (
                          <SortableItem key={image.imageId}>
                            <div key={key} className="drag-item">
                              <InspectionImage
                                key={key}
                                data={image}
                                onPreview={() => {
                                  setImgPreview([
                                    {
                                      status: true,
                                      imgUrl: image?.imageUrl,
                                    },
                                  ]);
                                }}
                                onEdit={() => {
                                  localStorage.setItem(
                                    "editImageId",
                                    image?.imageId
                                  );
                                  localStorage.setItem(
                                    "editImageType",
                                    "subCategoryType"
                                  );
                                  localStorage.setItem(
                                    "subCategoryTypeId",
                                    data?.subCategoryTypeId
                                  );
                                  setEditImageId(image?.imageId);
                                  setLoader(true);
                                  fetch(image?.imageUrl)
                                    .then((res) => res.blob())
                                    .then((blob) => {
                                      try {
                                        let objectURL =
                                          URL.createObjectURL(blob);
                                        setEditImageURL(objectURL);
                                        setShowImageEdit(true);
                                        setTimeout(() => {
                                          showMarkerArea();
                                        }, 1000);
                                      } catch (error) {
                                        setShowImageEdit(false);
                                        console.log(error);
                                      }
                                      setLoader(false);
                                    })
                                    .catch((err) => {
                                      setLoader(false);
                                    });
                                }}
                                onDelete={() => {
                                  let attachments = [];
                                  if (data?.attachments?.length > 0) {
                                    attachments = [...data?.attachments];
                                    attachments.splice(key, 1);
                                  }
                                  setSubCategoryTypeData(
                                    (subCategoryTypeData) =>
                                      subCategoryTypeData.map((obj, i) => {
                                        if (i === index) {
                                          attachments.map((img, sctNum) => {
                                            attachments[sctNum].position =
                                              sctNum;
                                          });
                                          return {
                                            ...obj,
                                            attachments: attachments,
                                          };
                                        }
                                        return obj;
                                      })
                                  );
                                }}
                              />
                            </div>
                          </SortableItem>
                        );
                      })}
                  </SortableList>

                  {/* {data?.attachments?.length > 0 &&
                    [...data?.attachments]?.map((image, key) => {
                      return (
                        <InspectionImage
                          key={key}
                          data={image}
                          onPreview={() => {
                            setImgPreview([
                              {
                                status: true,
                                imgUrl: image?.imageUrl,
                              },
                            ]);
                          }}
                          onEdit={() => {
                            localStorage.setItem("editImageId", image?.imageId);
                            localStorage.setItem(
                              "editImageType",
                              "subCategoryType"
                            );
                            localStorage.setItem(
                              "subCategoryTypeId",
                              data?.subCategoryTypeId
                            );
                            setEditImageId(image?.imageId);
                            setLoader(true);
                            fetch(image?.imageUrl)
                              .then((res) => res.blob())
                              .then((blob) => {
                                try {
                                  let objectURL = URL.createObjectURL(blob);
                                  setEditImageURL(objectURL);
                                  setShowImageEdit(true);
                                  setTimeout(() => {
                                    showMarkerArea();
                                  }, 1000);
                                } catch (error) {
                                  setShowImageEdit(false);
                                  console.log(error);
                                }
                                setLoader(false);
                              })
                              .catch((err) => {
                                setLoader(false);
                              });
                          }}
                          onDelete={() => {
                            let attachments = [];
                            if (data?.attachments?.length > 0) {
                              attachments = [...data?.attachments];
                              attachments.splice(key, 1);
                            }
                            setSubCategoryTypeData((subCategoryTypeData) =>
                              subCategoryTypeData.map((obj, i) => {
                                if (i === index) {
                                  return { ...obj, attachments: attachments };
                                }
                                return obj;
                              })
                            );
                          }}
                        />
                      );
                    })} */}
                </div>
              </div>
            </div>
          );
        })}

      <Image
        // width={200}
        style={{ display: "none" }}
        preview={{
          visible: imgPreview[0].status,
          src: imgPreview[0].imgUrl,
          onVisibleChange: (visible, src) => {
            setImgPreview([{ status: visible, imgUrl: src }]);
          },
        }}
        src={imgPreview[0].imgUrl}
      />

      {/* Image Edit Starts */}
      {showImageEdit && (
        <div className="bg-white absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center flex-col z-[100]">
          <p
            className="absolute top-8 right-8 text-3xl text-gray-700 cursor-pointer"
            onClick={() => setShowImageEdit(false)}
          >
            X
          </p>
          <div className="flex justify-center items-center text-2xl text-type-dark font-semibold absolute top-0 left-0 right-0 bottom-0 z-[1001] bg-white">
            <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-3" />
            Loading...
          </div>
          <img
            alt="girl"
            className="rounded-xl max-w-[80vw] max-h-[70vh] object-cover opacity-0"
            ref={imageRef}
            src={editImageURL}
          />
        </div>
      )}
      {/* Image Edit Ends */}
    </>
  );
};

const InspectionPresentational = ({
  containerRef,
  client,
  allInspectionSlugs,
  setAllInspectionSlugs,
  menus,
  inspectionData,
  materialEdit,
  setMaterialEdit,
  isTemplate,
  setIsTemplate,
  templateContent,
  templateEditorData,
  setTemplateEditorData,
  resetEditor,
  setResetEditor,
  selectedTemplate,
  setSelectedTemplate,
  uploadImage,
  imageUploadLoading,
  next,
  openMobileMenu,
  setOpenMobileMenu,
  quickNoteData,
  subCategoryData,
  setSubCategoryData,
  subCategoryTypeData,
  setSubCategoryTypeData,
  materialData,
  materialTypes,
  setMaterialTypes,
  createMaterialData,
  updateMaterialData,
  deleteMaterialData,
  total,
  setTotal,
  taxPercentage,
  saveInspection,
  setShowImageEdit,
  showImageEdit,
  imageRef,
  showMarkerArea,
  editImageURL,
  setEditImageURL,
  setEditImageId,
  showModel,
  setShowModel,
  isDescriptionRequired,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  let clientName =
    client?.name && Object?.values(client?.name)?.join(" ")?.trim();
  return (
    <div className="flex flex-col flex-1">
      <HTabs
        onClickLoad
        tabsData={[
          {
            tabTitle:
              t(`COMMON.INSPECTION`) +
              (clientName ? ` - ${clientName}` : "") +
              (client?.clientNumber ? ` (${client?.clientNumber})` : ""),
            tabContent: (
              <div className="flex flex-1 bg-white border rounded-xl">
                <div className="hidden lg:flex flex-[0.30] flex-col bg-[#F7F7F9] rounded-tl-xl rounded-bl-xl rounded-tr-md rounded-br-md py-3 px-2 pr-0">
                  {menus.map((menu) => {
                    return (
                      <InspectionMenu
                        key={menu?.categoryId}
                        data={menu}
                        inspectionData={inspectionData}
                        // allInspectionSlugs={allInspectionSlugs}
                        // setAllInspectionSlugs={setAllInspectionSlugs}
                      />
                    );
                  })}
                </div>
                <div
                  ref={containerRef}
                  className="flex flex-1 flex-col rounded-tr-xl rounded-br-xl p-4 lg:p-8 max-w-full"
                >
                  <div className="flex lg:hidden">
                    <InspectionMenuMobile
                      openMobileMenu={openMobileMenu}
                      setOpenMobileMenu={setOpenMobileMenu}
                      menus={menus}
                      inspectionData={inspectionData}
                      // allInspectionSlugs={allInspectionSlugs}
                      // setAllInspectionSlugs={setAllInspectionSlugs}
                    />
                  </div>
                  {isTemplate ? (
                    <RichTextEditor
                      resetEditor={resetEditor}
                      setResetEditor={setResetEditor}
                      selectedTemplate={selectedTemplate}
                      setSelectedTemplate={setSelectedTemplate}
                      templateContent={templateContent}
                      templateEditorData={templateEditorData}
                      setTemplateEditorData={setTemplateEditorData}
                    />
                  ) : (
                    <>
                      <MaterialData
                        materialData={materialData}
                        materialTypes={materialTypes}
                        setMaterialTypes={setMaterialTypes}
                        materialEdit={materialEdit}
                        setMaterialEdit={setMaterialEdit}
                        createMaterialData={createMaterialData}
                        updateMaterialData={updateMaterialData}
                        deleteMaterialData={deleteMaterialData}
                      />
                      <SubCategory
                        subCategoryData={subCategoryData}
                        setSubCategoryData={setSubCategoryData}
                        subCategoryTypeData={subCategoryTypeData}
                        setSubCategoryTypeData={setSubCategoryTypeData}
                        quickNoteData={quickNoteData}
                        uploadImage={uploadImage}
                        imageUploadLoading={imageUploadLoading}
                        setShowImageEdit={setShowImageEdit}
                        showImageEdit={showImageEdit}
                        imageRef={imageRef}
                        showMarkerArea={showMarkerArea}
                        editImageURL={editImageURL}
                        setEditImageURL={setEditImageURL}
                        setEditImageId={setEditImageId}
                        isDescriptionRequired={isDescriptionRequired}
                      />
                    </>
                  )}
                  <div className="flex flex-wrap items-center justify-end space-x-2">
                    {params?.submenu && (
                      <Button
                        title={t(`COMMON.ADD_NEW`)}
                        icon={
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            className={"text-white text-xl"}
                          />
                        }
                        // rounded={true}
                        // spaceXSmall={true}
                        className="text-sm my-2"
                        onClick={() => {
                          let subCategoryTypeObj = {
                            inspectionSubCategoryId: null,
                            subCategoryTypeName: "",
                            name: "",
                            description: "",
                            imageIds: [],
                          };
                          // setSubCategoryTypeData([
                          //   ...subCategoryTypeData,
                          //   subCategoryTypeObj,
                          // ]);
                          setSubCategoryTypeData((subCategoryTypeData) => [
                            ...subCategoryTypeData,
                            subCategoryTypeObj,
                          ]);
                        }}
                      />
                    )}
                    <Button
                      title={
                        menus?.length >
                        Object?.keys(allInspectionSlugs)?.indexOf(
                          params?.menu
                        ) +
                          1
                          ? t(`COMMON.NEXT`)
                          : t(`COMMON.SUBMIT`)
                      }
                      spaceXSmall
                      className="text-sm my-2"
                      onClickDisabled={500}
                      onClick={() => {
                        menus?.length >
                        Object?.keys(allInspectionSlugs)?.indexOf(
                          params?.menu
                        ) +
                          1
                          ? next()
                          : setShowModel(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            ),
          },
        ]}
      />
      {showModel && (
        <Modal
          title={t(`INSPECTION_PAGE.COMMON.INSPECTION_COST`)}
          content={
            <div>
              <table className="border-collapse border border-slate-400 w-full text-right">
                <tbody>
                  <tr>
                    <td className="w-2/4 border border-slate-300 p-2 text-slate-500">
                      {t(`COMMON.TOTAL_AMOUNT`)}
                    </td>
                    <td className="w-2/4 border border-slate-300 p-2 text-slate-500">
                      <TextField
                        placeholder={t(
                          `INSPECTION_PAGE.COMMON.INSPECTION_COST_PLACEHOLDER`
                        )}
                        type="number"
                        min="0"
                        pattern="[0-9]+"
                        value={total}
                        onChange={(e) => setTotal(e.target.value)}
                      />
                    </td>
                  </tr>
                  {total > 0 && (
                    <>
                      <tr>
                        <td className="w-2/4 border border-slate-300 p-2 text-slate-500">
                          {t(`COMMON.TAX`)}({taxPercentage}%)
                        </td>
                        <td className="w-2/4 border border-slate-300 p-2 text-slate-500 font-bold">
                          NZD {((total * taxPercentage) / 100).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-2/4 border border-slate-300 p-2 text-slate-500">
                          {t(`COMMON.NET_AMOUNT`)}
                        </td>
                        <td className="w-2/4 border border-slate-300 p-2 text-slate-500 font-bold">
                          NZD{" "}
                          {(
                            parseFloat((total * taxPercentage) / 100) +
                            parseFloat(total)
                          ).toFixed(2)}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          }
          isModalOpen={showModel}
          onOk={() => {
            saveInspection("submit");
          }}
          onCancel={() => {
            setShowModel(false);
          }}
          disabled={!total}
          footer
        />
      )}
    </div>
  );
};

export default InspectionPresentational;
