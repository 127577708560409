import React, { useEffect, useRef, useState } from "react";
import ResizeFile from "utils/image-compressor";
import common from "utils/common";
import setLoader from "utils/loader";
import { useDispatch, useSelector } from "react-redux";

const ImageUploadGallery = ({ visible = false, callBack = () => {} }) => {
  const imageInputRef = useRef(null);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.imageUploadReducer);

  useEffect(() => {
    if (state && imageInputRef.current) {
      imageInputRef.current.click();
    }
  }, [state]);

  // convert base64 to file
  const urltoFile = (url, filename) => {
    let name = filename + ".jpg";
    return fetch(url)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((buf) => {
        return new File([buf], name, { type: "image/jpeg" });
      });
  };

  const onImageChange = (e) => {
    let totalLength = e.target.files.length;
    let data = [];
    setLoader(true);
    for (const file of e.target.files) {
      ResizeFile(file).then((base64) => {
        urltoFile(base64, common.generateRandomName(30)).then((urlToFile) => {
          data.push(urlToFile);
          if (totalLength === data.length) {
            callBack(data);
            setLoader(false);
            dispatch({ type: "SET_OPEN_GALLERY_UPLOAD", payload: false });
          }
        });
      });
    }
  };

  return (
    <>
      <div onClick={() => imageInputRef.current.click()}></div>
      <input
        accept="image/*"
        multiple={true}
        className="hidden"
        type={"file"}
        ref={imageInputRef}
        onChange={onImageChange}
      />
    </>
  );
};

export default ImageUploadGallery;
