import React, { useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoginService from "services/LoginService";
import AuthService from "services/AuthService";
import UserService from "services/UserService";
import NotificationService from "services/NotificationService";
import LoginPresentational from "./login-presentational";
import notification from "utils/notification";

const LoginFunctional = () => {
  let deviceWidth = window.innerWidth;
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [btnLoading, setBtnLoading] = useState(false);
  const userLogin = (formData) => {
    let request = {
      password: btoa(formData?.password) || null, // "MTIzNDU2", // 123456
      userName: formData?.email || null, // "admin@email.com"
    };
    setBtnLoading(true);
    LoginService.login(request)
      .then((loginResponse) => {
        if (parseInt(loginResponse.status, 0) === 204) {
          notification.error(t(`COMMON.NO_DATA_FOUND`));
        } else if (loginResponse?.data?.accessToken) {
          const accessToken = loginResponse?.data?.accessToken;
          AuthService.setToken(accessToken);
          if (loginResponse?.data?.refreshToken) {
            AuthService.setRefreshToken(loginResponse?.data?.refreshToken);
          }
          let notifyCount;
          NotificationService.getNotificationCount({ type: "APP" }).then(
            (res) => {
              notifyCount = res.data?.count;
            }
          );

          UserService.currentUser().then((res) => {
            let userRole = res.data?.role;
            // if (userRole !== "EMPLOYEE" && deviceWidth <= 640) {
            //   notification.error(t(`LOGIN_PAGE.COMMON.DEVICE_ISSUE`));
            //   AuthService.logout();
            // } else {
            res.data.notifyCount = notifyCount;
            AuthService.setCurrentUser(res.data);
            navigate("/");
            // }
          });
        } else {
          notification.error(
            t(
              `TOASTR.API.${loginResponse?.data?.constraintViolations?.[0]?.message}`
            )
          );
        }
        setBtnLoading(false);
      })
      .catch((e) => {
        notification.error(t(e));
        setBtnLoading(false);
      });
  };

  const forgetPassword = (formData) => {
    const path = {
      pathname: "/login/forget-password",
    };
    if (formData?.email) {
      path["search"] = createSearchParams({
        email: formData?.email,
      }).toString();
    }
    navigate(path);
  };

  return (
    <LoginPresentational
      userLogin={userLogin}
      forgetPassword={forgetPassword}
      btnLoading={btnLoading}
    />
  );
};
export default LoginFunctional;
