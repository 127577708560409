import axios from "axios";
import { inspectionUrl } from "urls/urls";

const getInspectionMenu = (params = {}) => {
  return axios.get(inspectionUrl.EXTERIOR_CATRGORY, { params });
};
const getInspection = (id) => {
  return axios.get(inspectionUrl.INSPECTION + "/" + id);
};

const getInspectionSubMenu = (id) => {
  return axios.get(
    `${inspectionUrl.EXTERIOR_CATRGORY}/${id}/exterior-sub-category`
  );
};

const postInspectionAttachement = (domain, body) => {
  return axios.post(`${inspectionUrl.ATTACHMENT}/${domain}`, body);
};
const updateInspectionAttachement = (domain, body, id) => {
  return axios.post(`${inspectionUrl.ATTACHMENT}/${domain}/${id}`, body);
};

const postInspectionAttachements = (domain, body) => {
  return axios.post(`${inspectionUrl.ATTACHMENTS}/${domain}`, body);
};

const saveInspection = (body) => {
  return axios.post(inspectionUrl.INSPECTION, body);
};

const updateInspection = (body) => {
  return axios.put(inspectionUrl.INSPECTION + "/category", body);
};

const getQuickNote = (params) => {
  return axios.get(inspectionUrl.QUICK_NOTE, { params });
};

const getMasterData = (params) => {
  return axios.get(inspectionUrl.MASTER_DATA, { params });
};

const updateMasterData = (id, body) => {
  return axios.put(inspectionUrl.MASTER_DATA + "/" + id, body);
};

const deleteMasterData = (id) => {
  return axios.delete(inspectionUrl.MASTER_DATA + "/" + id);
};

const saveMasterData = (body) => {
  return axios.post(inspectionUrl.MASTER_DATA, body);
};

const getImageUrl = (id) => {
  return axios.get(inspectionUrl.ATTACHMENT + "/" + id);
};

const getSubCategoryType = (id, params = {}) => {
  return axios.get(
    inspectionUrl.SUB_EXTERIOR_CATRGORY +
      "/" +
      id +
      "/exterior-sub-category-type",
    params
  );
};

const getInspectionReport = (id, params = {}) => {
  return axios.get(inspectionUrl.INSPECTION_REPORT + "/" + id, {
    responseType: "blob",
    params: params,
  });
};

const getTaxPercentage = () => {
  return axios.get(inspectionUrl.TAX_PERCENTAGE);
};

const sendInspectionReportToEmail = (id) => {
  return axios.get(inspectionUrl.INSPECTION + "/" + id + "/report");
};

// Public Access
const getClientData = (code) => {
  const headers = {
    skipAuth: true,
    skipErrors: true,
  };

  return axios.get(inspectionUrl.REPORT_VERIFY + "?verificationCode=" + code, {
    headers: headers,
  });
};

const getEmailInspectionReport = (params = {}) => {
  const headers = {
    skipAuth: true,
    skipErrors: true,
  };

  return axios.get(inspectionUrl.EMAIL_INSPECTION_REPORT, {
    headers: headers,
    responseType: "blob",
    params: params,
  });
};

const InspectionService = {
  getInspection,
  getInspectionMenu,
  getInspectionSubMenu,
  postInspectionAttachement,
  saveInspection,
  updateInspection,
  getQuickNote,
  getMasterData,
  updateMasterData,
  saveMasterData,
  deleteMasterData,
  getImageUrl,
  getSubCategoryType,
  getInspectionReport,
  updateInspectionAttachement,
  getTaxPercentage,
  sendInspectionReportToEmail,
  postInspectionAttachements,
  getClientData,
  getEmailInspectionReport,
};

export default InspectionService;
