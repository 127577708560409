import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import LoginLayout from "layouts/login/login-layout";
import DesktopLayout from "layouts/desktop/desktop-layout";
import MobileLayout from "layouts/mobile/mobile-layout";
import PaymentLayout from "layouts/payment/payment-layout";
import ReportLayout from "layouts/report/report-layout";
import Login from "screens/Login/login-functional";
import ForgetPassword from "screens/ForgetPassword/forgot-password-functional";
import Payment from "screens/Payment/payment-functional";
import Report from "screens/Report/report-functional";

function Router() {
  let deviceWidth = window.innerWidth;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginLayout />}>
          <Route index element={<Login />} />
          <Route path="/login/forget-password" element={<ForgetPassword />} />
        </Route>
        <Route path="/inspection-payment" element={<PaymentLayout />}>
          <Route index element={<Payment />} />
        </Route>
        <Route path="/inspection-report" element={<ReportLayout />}>
          <Route index element={<Report />} />
        </Route>
        <Route
          path="*"
          element={
            deviceWidth <= 640 ? (
              <PrivateRoute component={<MobileLayout />} />
            ) : (
              <PrivateRoute component={<DesktopLayout />} />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
