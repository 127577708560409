import React, { useEffect, useState } from "react";
// import { useNavigate, createSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthService from "services/AuthService";
import UserService from "services/UserService";
import ResizeFile from "utils/image-compressor";
import notification from "utils/notification";
import MyprofilePresentational from "./myprofile-presentational";

const MyprofileFunctional = () => {
  // let navigate = useNavigate();
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(AuthService?.getCurrentUser());
  const [btnLoading, setBtnLoading] = useState(false);

  const updateUser = (value) => {
    setBtnLoading(true);
    let request = {
      name: {
        firstName: value.firstName || null,
        lastName: value.lastName || null,
      },
      email: currentUser.email,
      phoneNumber: value.phoneNumber,
      role: currentUser.role,
    };

    const userId = currentUser?.userId;
    UserService.updateUser(userId, request)
      .then((res) => {
        if (res.status === 204) {
          notification.error(t(`COMMON.NO_DATA_FOUND`));
        } else if (res.status === 200) {
          notification.success(t(`MYPROFILE_PAGE.COMMON.PROFILE_UPDATED`));
          UserService.currentUser().then((userResponse) => {
            AuthService.setCurrentUser(userResponse.data);
            setCurrentUser(AuthService?.getCurrentUser());
          });
        }
        setBtnLoading(false);
      })
      .catch((e) => {
        notification.error(t(e));
        setBtnLoading(false);
      });
  };

  async function imageUpload(file) {
    let imgFile = file.target.files[0];
    const image = await ResizeFile(imgFile, "file");
    let formData = new FormData();
    let domain = "honeycomb-user";
    let jsonData = '{"attachmentType":"USER_PICTURE"}';
    formData.append("file", image);
    formData.append("jsonData", jsonData);
    UserService.uploadImage(domain, formData)
      .then((res) => {
        let profileImageId = res?.data.attachmentId;
        let userId = currentUser?.userId;
        let request = {
          name: {
            firstName: currentUser?.name.firstName || null,
            lastName: currentUser?.name.lastName || null,
          },
          email: currentUser?.email,
          role: currentUser?.role,
          profileImageId: profileImageId,
        };
        UserService.updateUser(userId, request)
          .then((res) => {
            if (res.status === 204) {
              notification.error(t(`COMMON.NO_DATA_FOUND`));
            } else if (res.status === 200) {
              notification.success(t(`MYPROFILE_PAGE.COMMON.PROFILE_UPDATED`));
              UserService.currentUser().then((userResponse) => {
                AuthService.setCurrentUser(userResponse.data);
                setCurrentUser(AuthService?.getCurrentUser());
              });
            }
            setBtnLoading(false);
          })
          .catch((e) => {
            notification.error(t(e));
          });
      })
      .catch((e) => {
        // console.log(e);
      });
  }

  useEffect(() => {
    setCurrentUser(AuthService?.getCurrentUser());
  }, [currentUser]);

  return (
    <MyprofilePresentational
      currentUserData={currentUser}
      updateUser={updateUser}
      btnLoading={btnLoading}
      imageUpload={imageUpload}
    />
  );
};
export default MyprofileFunctional;
