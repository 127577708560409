import React, { forwardRef } from "react";
import Label from "./lable";
import { Input } from "antd";

const TextField = forwardRef(
  (
    {
      placeholder = "Enter your input here",
      value,
      labelStyle,
      className = "",
      defaultClassName = "",
      onChange = "",
      style,
      type = "text",
      label = null,
      labelClassName = "",
      isTooltipContent = null,
      isTooltipIcon = null,
      name,
      isEnterNext = true,
      border,
      isNext,
      prefix,
      min,
      max,
      allowClear = false,
      isRequiredClass = false,
      maxLength,
      captalize = true,
      ...rest
    },
    ref
  ) => {
    const tabNext = (e, ref) => {
      if (
        e.key === "Enter" &&
        ref?.current?.input?.attributes?.tabindex?.value
      ) {
        let curIndex = ref.current.input.attributes.tabindex.value;
        let tabbables = document.querySelectorAll("[tabindex]") || [];
        for (var i = 0; i < tabbables.length; i++) {
          // loop through each element
          if (tabbables[i].tabIndex === parseInt(curIndex) + 1) {
            // check the tabindex to see if it's the element we want
            if (tabbables[i].nodeName === "BUTTON") {
              tabbables[i].click();
            } else {
              tabbables[i].focus(); // if it's the one we want, focus it and exit the loop
            }
            break;
          }
        }
      }
    };

    if (!defaultClassName) {
      defaultClassName =
        "px-3 py-1.5 shadow-none border-typo-light focus-within:ring-1 focus-within:border-primary focus-within:ring-primary";
    }

    const getRenderer = () => {
      switch (type) {
        case "text":
          return (
            <Input
              allowClear={allowClear}
              prefix={prefix}
              style={style}
              ref={ref}
              value={value}
              name={name}
              placeholder={placeholder}
              className={
                defaultClassName +
                " " +
                className +
                " " +
                border +
                ` ${isRequiredClass ? "required" : ""}`
              }
              onChange={(e) => {
                if (captalize) {
                  let captalizedValue =
                    e?.target?.value.charAt(0).toUpperCase() +
                    e?.target?.value.slice(1);
                  e.target.value = captalizedValue;
                }
                onChange(e);
              }}
              onKeyPress={(e) => {
                if (isEnterNext) {
                  tabNext(e, ref);
                }
              }}
              {...rest}
            />
          );
        case "phoneNumber":
          return (
            <Input
              allowClear={allowClear}
              prefix={prefix}
              ref={ref}
              {...rest}
              type="number"
              pattern="[0-9]*"
              maxLength={maxLength}
              style={style}
              value={value}
              name={name}
              placeholder={placeholder}
              className={
                defaultClassName +
                " " +
                className +
                " " +
                ` ${isRequiredClass ? "required" : ""}`
              }
              onKeyPress={(e) => {
                if (isNext) {
                  tabNext(e, ref);
                }
              }}
            />
          );
        case "email":
          return (
            <Input
              allowClear={allowClear}
              prefix={prefix}
              style={style}
              {...rest}
              name={name}
              type="email"
              ref={ref}
              placeholder={placeholder}
              className={
                defaultClassName +
                " " +
                className +
                " " +
                ` ${isRequiredClass ? "required" : ""}`
              }
              onChange={onChange}
              focusd={ref?.current}
              value={value}
              onKeyPress={(e) => {
                if (isNext) {
                  tabNext(e, ref);
                }
              }}
            />
          );
        case "number":
          return (
            <Input
              allowClear={allowClear}
              prefix={prefix}
              {...rest}
              style={style}
              name={name}
              value={value}
              min={min}
              max={max}
              type="number"
              pattern={/[+-]?([0-9]*[.])?[0-9]+/}
              ref={ref}
              placeholder={placeholder}
              className={
                defaultClassName +
                " " +
                className +
                " " +
                ` ${isRequiredClass ? "required" : ""}`
              }
              onChange={onChange}
              onKeyPress={(e) => {
                if (isNext) {
                  tabNext(e, ref);
                }
              }}
            />
          );
        default:
          return (
            <Input
              allowClear={allowClear}
              {...rest}
              prefix={prefix}
              style={style}
              ref={ref}
              value={value}
              name={name}
              className={
                defaultClassName +
                " " +
                className +
                " " +
                ` ${isRequiredClass ? "required" : ""}`
              }
              onChange={onChange}
              onKeyPress={(e) => {
                if (isNext) {
                  tabNext(e, ref);
                }
              }}
            />
          );
      }
    };

    return (
      <>
        {label && (
          <Label
            style={labelStyle}
            className={labelClassName}
            title={label}
            isTooltipIcon={isTooltipIcon}
            isTooltipContent={isTooltipContent}
          />
        )}
        {getRenderer()}
      </>
    );
  }
);

export default TextField;
