import icons from "assets/icons/icons";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

const InspectionMenu = ({
  data,
  inspectionData = [],
  // allInspectionSlugs,
  // setAllInspectionSlugs,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const [tempDisable, setTempDisable] = useState(false);
  const [filled, setFilled] = useState(false);
  const [filledSub, setFilledSub] = useState([]);

  // console.log(inspectionData);
  // console.log(data);

  const findCurrentCategoryDataById = (categoryId) => {
    const catData = [...inspectionData].filter((category) => {
      return category?.categoryId === Number(categoryId);
    });
    if (catData?.length > 0) {
      return { ...catData?.[0] };
    }
    return {};
  };

  useEffect(() => {
    let currentCat = findCurrentCategoryDataById(Number(data?.categoryId));
    // console.log(currentCat);
    if (currentCat) {
      if (data?.isTemplate) {
        if (currentCat?.template?.content) {
          setFilled(true);
          setFilledSub([]);
        } else {
          setFilled(false);
          setFilledSub([]);
        }
      } else {
        if (currentCat?.subCategories?.length > 0) {
          const subCatList = currentCat?.subCategories
            .filter(({ subCategoryId }) => subCategoryId)
            .map(({ subCategoryId }) => subCategoryId);
          setFilledSub(subCatList);
          setFilled(true);
        } else {
          setFilledSub([]);
          setFilled(false);
        }
      }
    } else {
      setFilled(false);
      setFilledSub([]);
    }
  }, [data, inspectionData]);

  return (
    <>
      <div
        className={
          params?.menu == data?.categoryId
            ? "flex bg-primary-light items-center p-2 my-1 rounded-tl-full rounded-bl-full cursor-pointer"
            : "flex items-center p-2 my-1 rounded-tl-full rounded-bl-full cursor-pointer"
        }
        onClick={() => {
          if (!tempDisable) {
            setTempDisable(true);
            const subMenu =
              data?.exteriorSubCategories?.[0]?.subCategoryId || null;
            subMenu
              ? navigate(`/inspection/${data?.categoryId}/${subMenu}`)
              : navigate(`/inspection/${data?.categoryId}`);
            setTimeout(() => {
              setTempDisable(false);
            }, 300);
          }
        }}
      >
        <div>
          <FontAwesomeIcon
            icon={faFileAlt}
            className={`h-[13px] w-[13px] mr-1.5 rounded-full p-1.5 text-white align-middle ${
              filled ? "bg-green-600" : "bg-primary"
            } `}
          />
        </div>
        <span
          className={
            params?.menu == data?.categoryId
              ? "text-base text-black"
              : "text-base text-gray-400"
          }
        >
          {data?.name}
        </span>
      </div>
      <div>
        {data?.categoryId == params?.menu &&
          data?.exteriorSubCategories?.map((subMenu) => {
            // console.log(subMenu?.subCategoryId, filledSub);
            // console.log(filledSub?.includes(subMenu?.subCategoryId));
            return (
              <div
                key={subMenu?.subCategoryId}
                className={
                  params?.submenu == subMenu?.subCategoryId &&
                  params?.menu == data?.categoryId
                    ? "flex flex-1 bg-primary-light items-center p-2 rounded-tl-full rounded-bl-full ml-8 my-1 cursor-pointer"
                    : "flex flex-1 items-center p-2 rounded-tl-full rounded-bl-full ml-8 my-1 cursor-pointer"
                }
                onClick={() => {
                  if (!tempDisable) {
                    setTempDisable(true);
                    navigate(
                      `/inspection/${data?.categoryId}/${subMenu?.subCategoryId}`
                    );
                    setTimeout(() => {
                      setTempDisable(false);
                    }, 300);
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faFileAlt}
                  className={`h-[13px] w-[13px] mr-1.5 rounded-full p-1.5 text-white align-middle ${
                    filledSub?.length > 0 &&
                    filledSub?.includes(subMenu?.subCategoryId)
                      ? "bg-green-600"
                      : "bg-primary"
                  } `}
                />
                <span
                  className={
                    params?.submenu == subMenu?.subCategoryId
                      ? "text-base text-black"
                      : "text-base text-gray-400"
                  }
                >
                  {subMenu?.name}
                </span>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default InspectionMenu;
