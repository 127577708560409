import React from "react";
import { useTranslation } from "react-i18next";

const FormErrMsg = ({ errors = {} }) => {
  const { t } = useTranslation();
  return (
    <>
      {(errors && Object.keys(errors).length > 0 && (
        <div className="required text-red-500 mt-1">
          {errors["message"]
            ? `${t(`${errors["message"]}`)}`
            : t("COMMON.FIELD_REQIURED")}
        </div>
      )) ||
        null}
    </>
  );
};

export default FormErrMsg;
