import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import validator from "utils/validator";
import Button from "components/Button";
import TextField from "components/form-fields/text-fields";
import PasswordFields from "components/form-fields/password-fileds";
import FormErrMsg from "components/FormErrMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";

const LoginPresentational = ({ userLogin, forgetPassword, btnLoading }) => {
  const { t } = useTranslation();
  const {
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
    },
    mode: "all",
  });

  const onFormSubmit = () => {
    trigger().then((res) => {
      if (res) {
        const formData = getValues();
        userLogin(formData);
      }
    });
  };

  const onForgetPassword = () => {
    trigger().then(() => {
      const formData = getValues();
      forgetPassword(formData);
    });
  };

  const validatormsg = {
    email: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
      pattern: { value: validator.EMAIL, message: "VALIDATOR.PATTERN.EMAIL" },
    },
    password: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
    },
  };

  return (
    <div className="form-fields">
      <h1 className="text-3xl font-semibold text-center uppercase mb-5">
        {t(`LOGIN_PAGE.COMMON.LOGIN`)}
      </h1>
      <div className="mb-4">
        <Controller
          control={control}
          name="email"
          rules={validatormsg?.email}
          render={({ field }) => (
            <TextField
              tabIndex="1"
              {...field}
              prefix={
                <FontAwesomeIcon icon={faUser} className="mr-1 text-primary" />
              }
              name="email"
              placeholder={t(`COMMON.EMAIL`)}
              type="email"
              value={getValues("email")}
              onInput={(e) => {
                setValue("email", e.target.value);
                trigger("email");
              }}
              disabled={btnLoading}
            />
          )}
        />
        <FormErrMsg errors={errors?.email} />
      </div>
      <div className="mb-4">
        <Controller
          control={control}
          name="password"
          rules={validatormsg?.password}
          render={({ field }) => (
            <PasswordFields
              tabIndex="2"
              {...field}
              prefix={
                <FontAwesomeIcon icon={faLock} className="mr-1 text-primary" />
              }
              name="password"
              placeholder={t(`COMMON.PASSWORD`)}
              type="password"
              value={getValues("password")}
              onInput={(e) => {
                setValue("password", e.target.value);
                trigger("password");
              }}
              disabled={btnLoading}
            />
          )}
        />
        <FormErrMsg errors={errors?.password} />
      </div>
      <div className="signin-button-div flex justify-center mb-4 text-center">
        <Button
          title={t(`LOGIN_PAGE.COMMON.LOGIN_BUTTON`)}
          isLoading={btnLoading}
          onClick={() => onFormSubmit()}
        />
      </div>
      <div className="forget-pasword-div flex justify-center text-center">
        <button
          onClick={() => onForgetPassword()}
          className="text-typo-dark text-sm font-semibold"
          disabled={btnLoading}
        >
          {t(`LOGIN_PAGE.COMMON.FORGOT_PASSWORD`)}
        </button>
      </div>
    </div>
  );
};

export default LoginPresentational;
