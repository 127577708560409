import React from "react";

const ImageUploadActionMenu = ({
  visible = false,
  camera = () => {},
  gallery = () => {},
  cancel = () => {},
}) => {
  let deviceType;
  if (navigator.userAgent.match(/Android/i)) {
    deviceType = "Android";
  } else if (navigator.userAgent.match(/iPhone/i)) {
    deviceType = "iPhone";
  } else if (navigator.userAgent.match(/iPad/i)) {
    deviceType = "iPad";
  } else if (navigator.userAgent.match(/Windows Phone/i)) {
    deviceType = "Windows Phone";
  } else {
    deviceType = "Desktop";
  }

  return (
    visible && (
      <div className="fixed top-0 left-0 min-h-[100vh] min-w-[100vw] bg-[#00000030] z-10 flex flex-col-reverse">
        <div className="bg-white rounded-tl-xl rounded-tr-xl p-4 absolute bottom-0 right-0 left-0">
          {deviceType !== "iPhone" && deviceType !== "iPad" && (
            <div
              className="h-11 border-b border-gray flex items-center"
              onClick={camera}
            >
              <span>Open Camera</span>
            </div>
          )}
          <div
            className="h-11 border-b border-gray flex items-center"
            onClick={gallery}
          >
            <span>Choose from gallery</span>
          </div>
          <div className="h-11 flex items-center">
            <span className="font-medium text-red-500" onClick={cancel}>
              Cancel
            </span>
          </div>
        </div>
      </div>
    )
  );
};

export default ImageUploadActionMenu;
