import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { logoTransparent, loginPage } from "assets/images/images";
import AuthService from "services/AuthService";

const LoginLayout = () => {
  const { t } = useTranslation();

  const AccessToken = AuthService.getToken();
  if (AccessToken) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen flex items-center bg-light">
      <div className="hidden md:flex w-1/2 h-full min-h-screen items-center justify-center bg-dark">
        <div className="p-10 xl:p-16 2xl:py-16 2xl:px-32">
          <h2 className="text-4xl xl:text-4xl font-bold text-primary">
            {t(`LOGIN_PAGE.BANNER.TITLE`)}
          </h2>
          <p className="text-typo-light italic">
            {t(`LOGIN_PAGE.BANNER.DESCRIPTION`)}
          </p>
          <img src={loginPage} alt="Login House Inspection" className="w-4/5" />
        </div>
      </div>
      <div className="w-full md:w-1/2 h-full p-6 flex items-center justify-center">
        <div className="w-96 max-w-full px-8 py-12 md:px-8 md:py-14 bg-white border border-typo-light rounded">
          <div className="app-logo-div bg-dark h-full w-1/2 px-3 py-2 mx-auto mb-6">
            <img src={logoTransparent} alt={"App Logo"} />
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
