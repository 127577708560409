import React, { useState } from "react";
import { Routes, Route, Navigate, NavLink } from "react-router-dom";
import { notificationBell, user, users } from "assets/icons/icons";
import { logoTransparent } from "assets/images/images";
import AuthService from "services/AuthService";
// import Dashboard from "screens/Dashboard/dashboard-functional";
import Clients from "screens/UserManagement/Clients/clients-functional";
import MyProfile from "screens/MyProfile/myprofile-template";
import Inspection from "screens/InspectionsMobile/inspection-mobile-functional";
import Notification from "screens/Notification/notification-functional";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBell,
  faClose,
  faSignOut,
  faUserGear,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";

const MobileLayout = () => {
  const [sideMenu, setSideMenu] = useState(false);
  const navDefaultClasses =
    "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100";
  const navActiveClasses = "bg-gray-50";

  const DrawerNavigation = () => {
    return (
      <div
        className={`fixed z-40 h-screen w-full transition-transform left-0 top-0 ${
          sideMenu ? " transform-none" : " -translate-x-full"
        }`}
        tabndex="-1"
      >
        <div
          className={`sidebar-overlay absolute inset-0 bg-black/40${
            sideMenu ? "" : " hidden"
          }`}
          onClick={() => setSideMenu(false)}
        />
        <div className="sidebar-container relative h-screen p-4 overflow-y-auto bg-white w-80">
          <h5 className="text-base font-semibold text-gray-500 uppercase">
            Menu
          </h5>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center"
          >
            <FontAwesomeIcon
              icon={faClose}
              style={{ height: 24, width: 24 }}
              className="flex-shrink-0 w-5 h-5 text-dark transition duration-75 group-hover:text-gray-900"
              onClick={() => setSideMenu(false)}
            />
            <span className="sr-only">Close menu</span>
          </button>
          <div className="py-4 overflow-y-auto">
            <ul className="space-y-2">
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? `${navDefaultClasses} ${navActiveClasses}`
                      : navDefaultClasses
                  }
                  onClick={() => setSideMenu(false)}
                >
                  <FontAwesomeIcon
                    icon={faUserGroup}
                    className={
                      "flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900"
                    }
                  />
                  <span className="flex-1 ml-3 whitespace-nowrap">Clients</span>
                  {/* <span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-600 bg-blue-200 rounded-full dark:bg-blue-900 dark:text-blue-200">
                    3
                  </span> */}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/my-profile"
                  className={({ isActive }) =>
                    isActive
                      ? `${navDefaultClasses} ${navActiveClasses}`
                      : navDefaultClasses
                  }
                  onClick={() => setSideMenu(false)}
                >
                  <FontAwesomeIcon
                    icon={faUserGear}
                    className={
                      "flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900"
                    }
                  />
                  <span className="flex-1 ml-3 whitespace-nowrap">
                    My Profile
                  </span>
                  {/* <span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-600 bg-blue-200 rounded-full dark:bg-blue-900 dark:text-blue-200">
                    3
                  </span> */}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/notification"
                  className={({ isActive }) =>
                    isActive
                      ? `${navDefaultClasses} ${navActiveClasses}`
                      : navDefaultClasses
                  }
                  onClick={() => setSideMenu(false)}
                >
                  <FontAwesomeIcon
                    icon={faBell}
                    className={
                      "flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900"
                    }
                  />
                  <span className="flex-1 ml-3 whitespace-nowrap">
                    Notification
                  </span>
                  {/* <span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-600 bg-blue-200 rounded-full dark:bg-blue-900 dark:text-blue-200">
                    3
                  </span> */}
                </NavLink>
              </li>
              <li>
                <span
                  className={navDefaultClasses}
                  onClick={() => AuthService.logout()}
                >
                  <FontAwesomeIcon
                    icon={faSignOut}
                    className={
                      "flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900"
                    }
                  />
                  <span className="flex-1 ml-3 whitespace-nowrap">Logout</span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="h-screen flex flex-col">
        <div className="app-header bg-primary min-h-[64px] max-h-[64px] flex items-center justify-between">
          <div className="app-logo-div bg-dark h-full w-1/2 px-3 py-2 flex justify-center items-center">
            <img src={logoTransparent} alt={"Transparent Logo"} />
          </div>
          <div>
            <FontAwesomeIcon
              icon={faBars}
              style={{ height: 24, width: 24 }}
              className="text-white mx-6 "
              onClick={() => setSideMenu(true)}
              // onClick={() => {
              //   AuthService.logout();
              // }}
            />
          </div>
        </div>
        <div className="app-content flex-1 h-[calc(100vh-64px-12px)] overflow-hidden overflow-y-auto">
          <div className="flex flex-col flex-1">
            <div className="app-content__inner flex flex-col flex-1 p-6">
              <Routes>
                <Route path="/" element={<Clients isMobile={true} />} />
                <Route path="/my-profile" element={<MyProfile />} />
                <Route path="/notification" element={<Notification />} />
                <Route path="/inspection" element={<Inspection />}>
                  <Route path="/inspection/:menu" element={<Inspection />} />
                  <Route
                    path="/inspection/:menu/:submenu"
                    element={<Inspection />}
                  />
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </div>
          </div>
        </div>
        {/* <div className="app-footer bg-primary pb-0 drop-shadow-[0_-4px_6px_rgba(0,0,0,0.1)]">
          <div className="app-footer__left bg-dark w-1/2 pt-3"></div>
        </div> */}
        {DrawerNavigation()}
      </div>
    </>
  );
};

export default MobileLayout;
