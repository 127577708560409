import icons from "assets/icons/icons";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import InspectionMenu from "./InspectionMenu";

const InspectionMenuMobile = ({
  menus,
  openMobileMenu,
  setOpenMobileMenu,
  inspectionData = [],
  // allInspectionSlugs,
  // setAllInspectionSlugs
}) => {
  const params = useParams();

  const getSelectedText = () => {
    let name = menus.filter((data) => data?.categoryId == params.menu);
    return name[0]?.name || "";
  };

  return (
    <div className="flex min-h-[40px] flex-1 border rounded-md border-gray mb-5 p-2 cursor-pointer relative">
      <div
        className="flex flex-row flex-1 justify-between items-center"
        onClick={() => setOpenMobileMenu(!openMobileMenu)}
      >
        <div className="flex flex-row items-center">
          <img
            className="h-7 w-7 mr-2"
            src={icons.inspectionMenuYellow}
            alt="inspection_inprogress"
          />
          <span className="color-black">{getSelectedText()}</span>
        </div>
        <div>
          {openMobileMenu ? (
            <FontAwesomeIcon
              icon={faCaretUp}
              className={"text-[#FAB414] text-xl"}
            />
          ) : (
            <FontAwesomeIcon
              icon={faCaretDown}
              className={"text-[#FAB414] text-xl"}
            />
          )}
        </div>
      </div>
      {openMobileMenu && (
        <div className="absolute top-[50px] mt-4 pt-2 bg-white left-0 right-0 min-h-[100px] max-h-96 shadow-xl rounded-md overflow-scroll z-[1]">
          {menus.map((menu) => {
            return (
              <InspectionMenu
                key={menu?.categoryId}
                data={menu}
                inspectionData={inspectionData}
                // allInspectionSlugs={allInspectionSlugs}
                // setAllInspectionSlugs={setAllInspectionSlugs}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default InspectionMenuMobile;
