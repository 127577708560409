import React from "react";
import Tabs from "components/tabs/h-tabs";
import NotificationCard from "components/card/notification-card";
import { Pagination } from "antd";
import moment from "moment";

const NotificationPresentational = ({
  notificationData,
  filterForm,
  setFilterForm,
}) => {
  const toDay = moment().format("YYYY-MM-DD");
  const yeasterDay = moment().add(-1, "days").format("YYYY-MM-DD");
  let toDayArray = [];
  let yeasterDayArray = [];
  let allDayArray = [];
  notificationData.map((data) => {
    let notifyDate = moment(data.processedAt).utc().format("YYYY-MM-DD");
    if (toDay == notifyDate) {
      toDayArray.push({
        data: data,
      });
    } else if (yeasterDay == notifyDate) {
      yeasterDayArray.push({
        data: data,
      });
    } else {
      allDayArray.push({
        data: data,
      });
    }
  });

  const tabs = [
    {
      tabTitle: "Notification",
      tabContent: (
        <>
          <div className="w-full justify-center">
            {toDayArray?.length >= 1 && (
              <>
                <h1 className="font-bold text-lg">Today</h1>
                {toDayArray.map((data) => {
                  let notifyDateTime = moment(data.data.processedAt)
                    .utc()
                    .format("YYYY-MM-DD hh:mm A");
                  let notifyDate = moment(data.data.processedAt)
                    .utc()
                    .format("YYYY-MM-DD");
                  return (
                    <NotificationCard
                      title={data.data.title}
                      dateTime={notifyDateTime}
                    />
                  );
                })}
              </>
            )}
            {yeasterDayArray?.length >= 1 && (
              <>
                <h1 className="font-bold text-lg">Yeasterday</h1>
                {yeasterDayArray.map((data) => {
                  let notifyDateTime = moment(data.data.processedAt)
                    .utc()
                    .format("YYYY-MM-DD hh:mm A");
                  let notifyDate = moment(data.data.processedAt)
                    .utc()
                    .format("YYYY-MM-DD");
                  return (
                    <NotificationCard
                      title={data.data.title}
                      dateTime={notifyDateTime}
                    />
                  );
                })}
              </>
            )}
            {allDayArray?.length >= 1 && (
              <>
                <h1 className="font-bold text-lg"></h1>
                {allDayArray.map((data) => {
                  let notifyDateTime = moment(data.data.processedAt)
                    .utc()
                    .format("YYYY-MM-DD hh:mm A");
                  let notifyDate = moment(data.data.processedAt)
                    .utc()
                    .format("YYYY-MM-DD");
                  return (
                    <NotificationCard
                      title={data.data.title}
                      dateTime={notifyDateTime}
                    />
                  );
                })}
              </>
            )}
            <div className="black mt-10">
              {notificationData.length >= 1 && (
                <Pagination
                  className="text-right"
                  pageSize={filterForm && filterForm.size}
                  hideOnSinglePage={true}
                  current={filterForm && filterForm.page + 1}
                  total={filterForm && filterForm.total}
                  onChange={(page) => {
                    setFilterForm({
                      ...filterForm,
                      page: page - 1,
                      isFilter: true,
                    });
                  }}
                />
              )}
            </div>
          </div>
        </>
      ),
    },
  ];
  return <Tabs tabsData={tabs} />;
};

export default NotificationPresentational;
