import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal as AntModal } from 'antd';
import Button from './Button';

const Modal = (props) => {
  const { t } = useTranslation();
  const {
    title,
    content,
    isModalOpen = false,
    setIsModalOpen = () => {},
    onOk = () => {},
    onCancel = () => {},
    okBtnText = t(`COMMON.OK`),
    cancelBtnText = t(`COMMON.CANCEL`),
    footer = false,
    isLoading = false,
    disabled,
  } = props

  const handleOk = () => {
    onOk();
  };

  const handleCancel = () => {
    onCancel();
    setIsModalOpen(false);
  };

  return (
    <AntModal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footer ? [
        <div key='modal-footer-content' className='add-quick-note-button-div flex items-center justify-end space-x-3'>
          <Button
            title={okBtnText}
            onClick={handleOk}
            disabled={disabled}
            isLoading={isLoading}
            key="submit"
          />
          <Button
            title={cancelBtnText}
            onClick={handleCancel}
            type="outline"
            key="back"
          />
        </div>
      ] : null}
    >
      {content}
    </AntModal>
  );
}

export default Modal;