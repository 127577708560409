import axios from "axios";
import { userUrl, inspectionUrl } from "urls/urls";

const getUserList = (params = {}) => {
  return axios.get(userUrl.USER, { params });
};

const getUserClientList = (params = {}) => {
  return axios.get(userUrl.USER + "-client", { params });
};

const currentUser = () => {
  return axios.get(userUrl.CURRENT_USER);
};

const createUser = (body) => {
  return axios.post(userUrl.USER, body);
};

const updateUser = (id, body) => {
  return axios.put(userUrl.USER + `/${id}`, body);
};

const uploadImage = (domain, body) => {
  return axios.post(userUrl.ATTACHMENT + "/" + domain, body);
};

const uploadHouseImage = (id, body) => {
  return axios.patch(inspectionUrl.INSPECTION_URL + "/" + id + "/image", body);
};

const deleteUser = (id) => {
  return axios.delete(userUrl.USER + `/${id}`);
};

const getNotificationtList = (params = {}) => {
  return axios.get(userUrl.NOTIFICATION, { params });
};

const UserService = {
  getUserList,
  getUserClientList,
  currentUser,
  createUser,
  updateUser,
  uploadImage,
  deleteUser,
  getNotificationtList,
  uploadHouseImage,
};

export default UserService;
