import React, { useEffect } from "react";
import {
  $getRoot,
  $getSelection,
  $insertNodes,
  CLEAR_EDITOR_COMMAND,
} from "lexical";
import { $generateNodesFromDOM, $generateHtmlFromNodes } from "@lexical/html";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useTranslation } from "react-i18next";

export const HTMLEditorContainer = ({
  defaultValue,
  onEditorStateChange,
  resetEditor,
  setResetEditor,
}) => {
  const [editor] = useLexicalComposerContext();
  const { t } = useTranslation();

  // onChange listener
  const onChange = (editorState, editor) => {
    editor.update(() => {
      if ($getRoot().getTextContent()) {
        const html = $generateHtmlFromNodes(editor, null);
        onEditorStateChange(html);
      } else {
        onEditorStateChange("");
      }
    });
  };

  // onChange listener
  // useEffect(() => {
  //   if (!editor) {
  //     return;
  //   }

  //   editor.registerUpdateListener(() => {
  //     editor.update(() => {
  //       if($getRoot().getTextContent()) {
  //         const html = $generateHtmlFromNodes(editor, null);
  //         onEditorStateChange(html)
  //       } else {
  //         onEditorStateChange("")
  //       }
  //     });
  //   });
  // }, [editor, onEditorStateChange]);

  // set defaultValue
  useEffect(() => {
    if (!defaultValue || !editor || resetEditor !== undefined) {
      return;
    }

    editor.update(() => {
      if ($getRoot().getTextContent()) {
        return;
      }
      const parser = new DOMParser();
      const dom = parser.parseFromString(defaultValue, "text/html");
      const nodes = $generateNodesFromDOM(editor, dom);
      // $getRoot().select();
      // const selection = $getSelection();
      // // @ts-expect-error not sure...
      // selection.insertNodes(nodes);
      editor.focus();
      $getRoot().select();
      $insertNodes(nodes);
      editor.blur();
    });
  }, []);

  // reset defaultValue
  useEffect(() => {
    if (!editor && !resetEditor) {
      return;
    }

    editor.update(() => {
      if (resetEditor === "clear") {
        editor?.focus();
        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        editor.blur();
      } else if (resetEditor === "cleared" && defaultValue !== null) {
        editor?.focus();
        const parser = new DOMParser();
        const dom = parser.parseFromString(defaultValue, "text/html");
        const nodes = $generateNodesFromDOM(editor, dom);
        $getRoot().select();
        $insertNodes(nodes);
        editor.blur();
      }
    });
  }, [resetEditor]);

  return (
    <div className="relative flex-1">
      <RichTextPlugin
        contentEditable={
          <ContentEditable className="w-full h-full min-h-[300px] overflow-hidden overflow-y-auto border-2 border-typo-light rounded-lg p-3 focus-visible:outline-none focus-within:border-primary" />
        }
        placeholder={
          <div className="absolute top-3 left-3 pointer-events-none select-none">
            {t(`COMMON.INPUT_PLACEHOLDER`)}
          </div>
        }
      />
      <OnChangePlugin onChange={onChange} />
      <ClearEditorPlugin onClear={null} />
    </div>
  );
};
