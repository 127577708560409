import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import PasswordFields from "components/form-fields/password-fileds";
import FormErrMsg from "components/FormErrMsg";

const ChangePasswordPresentational = ({
  changePassword,
  btnLoading,
  resetForm,
  setResetForm,
}) => {
  const { t } = useTranslation();
  const {
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    mode: "all",
  });

  useEffect(() => {
    if (resetForm) {
      reset();
      setResetForm(false);
    }
  }, [resetForm]);

  const onFormSubmit = () => {
    trigger().then((res) => {
      if (res) {
        const formData = getValues();
        changePassword(formData);
      }
    });
  };

  const validatormsg = {
    oldPassword: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
      minLength: { value: 6, message: "VALIDATOR.MINIMUM.6" },
    },
    newPassword: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
      minLength: { value: 6, message: "VALIDATOR.MINIMUM.6" },
    },
    confirmPassword: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
      minLength: { value: 6, message: "VALIDATOR.MINIMUM.6" },
      validate: (val) => {
        if (watch("newPassword") !== val) {
          return "Your passwords doesn't match";
        }
      },
    },
  };

  return (
    <div className="w-full flex justify-center">
      <div className="form-fields bg-gray-100 w-full md:w-4/5 border-2 px-2 md:px-32 py-2 md:py-10">
        <div className="mb-4">
          <label
            className="block text-gray-700 text-{8px} mb-0"
            for="{t(`COMMON.OLD_PASSWORD`)}"
          >
            {t(`COMMON.OLD_PASSWORD`)}
          </label>
          <Controller
            control={control}
            name="oldPassword"
            rules={validatormsg?.oldPassword}
            render={({ field }) => (
              <PasswordFields
                tabIndex="1"
                {...field}
                name="oldPassword"
                placeholder={t(`COMMON.OLD_PASSWORD`)}
                type="text"
                value={getValues("oldPassword")}
                onInput={(e) => {
                  setValue("oldPassword", e.target.value);
                  trigger("oldPassword");
                }}
                disabled={btnLoading}
              />
            )}
          />
          <FormErrMsg errors={errors?.oldPassword} />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-{8px} mb-0"
            for="{t(`COMMON.NEW_PASSWORD`)}"
          >
            {t(`COMMON.NEW_PASSWORD`)}
          </label>
          <Controller
            control={control}
            name="newPassword"
            rules={validatormsg?.newPassword}
            render={({ field }) => (
              <PasswordFields
                tabIndex="1"
                {...field}
                name="newPassword"
                placeholder={t(`COMMON.NEW_PASSWORD`)}
                type="text"
                value={getValues("newPassword")}
                onInput={(e) => {
                  setValue("newPassword", e.target.value);
                  trigger("newPassword");
                }}
                disabled={btnLoading}
              />
            )}
          />
          <FormErrMsg errors={errors?.newPassword} />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-{8px} mb-0"
            for="{t(`COMMON.CONFIRM_PASSWORD`)}"
          >
            {t(`COMMON.CONFIRM_PASSWORD`)}
          </label>
          <Controller
            control={control}
            name="confirmPassword"
            rules={validatormsg?.confirmPassword}
            render={({ field }) => (
              <PasswordFields
                tabIndex="1"
                {...field}
                name="confirmPassword"
                placeholder={t(`COMMON.CONFIRM_PASSWORD`)}
                type="text"
                value={getValues("confirmPassword")}
                onInput={(e) => {
                  setValue("confirmPassword", e.target.value);
                  trigger("confirmPassword");
                }}
                disabled={btnLoading}
              />
            )}
          />
          <FormErrMsg errors={errors?.confirmPassword} />
        </div>

        <div className="signin-button-div flex justify-center mt-8 mb-4 text-center">
          <Button
            title={t(`COMMON.UPDATE`)}
            isLoading={btnLoading}
            onClick={() => onFormSubmit()}
            className="mr-2"
          />

          {/* <Button type="" title={t(`COMMON.CANCEL`)} /> */}
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordPresentational;
