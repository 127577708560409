export const logo = require("./logo.png");
export const logoDark = require("./logo-dark.png");
export const logoTransparent = require("./logo-transparent.png");
export const loginPage = require("./login-page.png");
export const propertyImg = require("./house-placeholder.png");
export const UserAvatar = require("./profile.png");
export const paystation_logo = require("./paystation_logo.png");

const images = {
  logo,
  logoDark,
  logoTransparent,
  loginPage,
  propertyImg,
  UserAvatar,
  paystation_logo,
};

export default images;
