import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { Select, Image } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faSpinner,
  faPlusCircle,
  faTrash,
  faFloppyDisk,
  faPen,
  faPlus,
  faClose,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

import InspectionImage from "components/InspectionImage";
import Checkbox from "components/checkbox";
import Button from "components/Button";
import TextField from "components/form-fields/text-fields";
import confirm from "antd/lib/modal/confirm";
import setLoader from "utils/loader";
import MultipleImageUpload from "components/MultipleImageUpload";
import ImageUploadActionMenu from "components/ImageUploadActionMenu";
import ImageUploadGallery from "components/ImageUploadGallery";
import { useDispatch } from "react-redux";

const CatMenu = ({ menu, inspectionData = [] }) => {
  const navigate = useNavigate();
  const [tempDisable, setTempDisable] = useState(false);
  const [filled, setFilled] = useState(false);

  // console.log(inspectionData);
  // console.log(menu);

  const findCurrentCategoryInspectionDataById = (categoryId) => {
    const catInsData = [...inspectionData].filter((category) => {
      return category?.categoryId === Number(categoryId);
    });
    if (catInsData?.length > 0) {
      return { ...catInsData?.[0] };
    }
    return {};
  };

  useEffect(() => {
    let currentInsCat = findCurrentCategoryInspectionDataById(
      Number(menu?.categoryId)
    );
    // console.log(currentInsCat);
    if (currentInsCat?.subCategories?.length > 0) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  }, [menu, inspectionData]);

  return (
    <>
      <div
        className={`border-2 rounded px-2 py-2 ${
          filled ? "border-green-600" : "border-primary"
        }`}
        onClick={() => {
          if (!tempDisable) {
            setTempDisable(true);
            navigate(`/inspection/${menu?.categoryId}`);
            setTimeout(() => {
              setTempDisable(false);
            }, 300);
          }
        }}
      >
        <span className={`text-base`}>{menu?.name}</span>
      </div>
    </>
  );
};

const CatSubMenu = ({ currentMenuId, menus, inspectionData = [] }) => {
  const navigate = useNavigate();
  const [tempDisable, setTempDisable] = useState(false);
  const [filledSub, setFilledSub] = useState([]);

  // console.log(inspectionData);
  // console.log(menu);

  const findCurrentCategoryDataById = (categoryId) => {
    const catData = [...menus].filter((category) => {
      return category?.categoryId === Number(categoryId);
    });
    if (catData?.length > 0) {
      return { ...catData?.[0] };
    }
    return {};
  };

  const findCurrentCategoryInspectionDataById = (categoryId) => {
    const catInsData = [...inspectionData].filter((category) => {
      return category?.categoryId === Number(categoryId);
    });
    if (catInsData?.length > 0) {
      return { ...catInsData?.[0] };
    }
    return {};
  };

  useEffect(() => {
    let currentInsCat = findCurrentCategoryInspectionDataById(
      Number(currentMenuId)
    );
    // console.log(currentInsCat);
    if (currentInsCat?.subCategories?.length > 0) {
      const filledSubCatList = currentInsCat?.subCategories
        .filter(({ subCategoryId }) => subCategoryId)
        .map(({ subCategoryId }) => subCategoryId);
      setFilledSub(filledSubCatList);
    } else {
      setFilledSub([]);
    }
  }, [currentMenuId, inspectionData]);

  let currentMenu = findCurrentCategoryDataById(currentMenuId);

  if (currentMenu?.exteriorSubCategories?.length > 0) {
    return (
      <>
        <h2 key={currentMenuId} className="text-lg">
          {currentMenu?.name}
        </h2>
        {currentMenu?.exteriorSubCategories?.map((subMenu) => {
          // console.log(subMenu?.subCategoryId, filledSub);
          // console.log(filledSub?.includes(subMenu?.subCategoryId));
          return (
            <div
              key={subMenu?.subCategoryId}
              className={`border-2 rounded px-2 py-2 ${
                filledSub?.length > 0 &&
                filledSub?.includes(subMenu?.subCategoryId)
                  ? "border-green-600"
                  : "border-primary"
              }`}
              onClick={() => {
                if (!tempDisable) {
                  setTempDisable(true);
                  navigate(
                    `/inspection/${currentMenuId}/${subMenu?.subCategoryId}`
                  );
                  setTimeout(() => {
                    setTempDisable(false);
                  }, 300);
                }
              }}
            >
              <span className={`text-base`}>{subMenu?.name}</span>
            </div>
          );
        })}
      </>
    );
  }
  return <></>;
};

const MaterialData = ({
  materialData,
  materialTypes,
  setMaterialTypes,
  materialEdit,
  setMaterialEdit,
  createMaterialData,
  updateMaterialData,
  deleteMaterialData,
}) => {
  const { t } = useTranslation();
  // const [materialEdit, setMaterialEdit] = useState(false); // Track for construction type is in edit mode
  const [newMaterial, setNewMaterial] = useState("");
  const [materialUpdate, setMaterialUpdate] = useState(-1);
  const [updateMaterial, setUpdateMaterial] = useState("");
  const showDeleteConfirm = useCallback(
    (id) => {
      confirm({
        title: t(`COMMON.CONFIRM_DELETE`),
        icon: (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size={"xl"}
            className="float-left mr-4 text-red-600"
          />
        ),
        okText: t(`COMMON.YES`),
        okButtonProps: {
          className:
            "font-medium text-gray-600 hover:text-primary border-gray-300 hover:border-primary rounded",
        },
        okType: "danger",
        cancelText: t(`COMMON.CANCEL`),
        cancelButtonProps: {
          className:
            "font-medium rounded text-white bg-primary border border-primary hover:border-primary",
        },
        className: "rounded",
        onOk() {
          deleteMaterialData(id);
        },
        onCancel() {},
      });
    },
    [t, deleteMaterialData]
  );

  return (
    <>
      <div className="bg-[#F7F7F9] rounded-md border-2 p-3 flex flex-col border-gray mb-4">
        <div className="flex flex-wrap items-center group">
          <h2 className="font-bold mb-2 group-last:mb-0">
            {t(`INSPECTION_PAGE.COMMON.TYPE`)}
          </h2>
          <div
            className="bg-[#FCAE01] rounded-full px-3 cursor-pointer ml-auto mb-2 group-last:mb-0"
            onClick={() => {
              setMaterialEdit(!materialEdit);
            }}
          >
            {/* <FontAwesomeIcon
              icon={materialEdit ? faClose : faPen}
              className={"text-white text-[14px] mr-2"}
            /> */}
            <span className="inline-block text-white text-sm">
              {materialEdit ? t("COMMON.DONE") : t("COMMON.ADD")}
            </span>
          </div>
        </div>
        {materialData?.length > 0 && (
          <div className="grid items-center grid-cols-2 gap-x-1.5">
            {materialData.map((material, index) => {
              if (materialEdit && material?.isDelete) {
                return;
              }
              return (
                <Checkbox
                  key={index}
                  hideBox={materialEdit}
                  label={material?.name}
                  value={[...materialTypes]?.includes(material?.id)}
                  onClick={() => {
                    if ([...materialTypes]?.includes(material?.id)) {
                      // let newMaterialTypes = [...materialTypes];
                      // newMaterialTypes.splice(index, 1);
                      // // console.log(newMaterialTypes);
                      // setMaterialTypes([...newMaterialTypes]);
                      setMaterialTypes((materialTypes) =>
                        materialTypes.filter((id) => {
                          return id !== material?.id;
                        })
                      );
                    } else {
                      let newMaterialTypes = [...materialTypes];
                      newMaterialTypes.push(material?.id);
                      // console.log(materialTypes);
                      setMaterialTypes([...newMaterialTypes]);
                    }
                  }}
                  columns="2"
                />
              );
            })}
          </div>
        )}
        {materialEdit && (
          <>
            {materialData.map((material) => {
              if (material?.isDelete) {
                return (
                  <>
                    <div
                      key={material?.id}
                      className="flex flex-row items-center"
                    >
                      {materialUpdate === material?.id ? (
                        <TextField
                          className="border border-gray rounded-md min-h-[40px] px-3 py-2 outline-none my-2 flex flex-1"
                          placeholder="Material"
                          value={updateMaterial}
                          onChange={(e) => {
                            setUpdateMaterial(e.target.value);
                          }}
                        />
                      ) : (
                        <div className="border border-gray rounded-md min-h-[40px] px-3 py-2 outline-none my-2 flex flex-1">
                          {material?.name}
                        </div>
                      )}
                      <div
                        className="h-[30px] w-[30px] bg-green-500 rounded-full flex justify-center items-center ml-4 cursor-pointer aria-disabled:opacity-30 aria-disabled:pointer-events-none"
                        onClick={() => {
                          if (materialUpdate === material?.id) {
                            if (
                              updateMaterial &&
                              material?.name !== updateMaterial
                            ) {
                              updateMaterialData(material?.id, updateMaterial);
                              setMaterialUpdate(-1);
                              setUpdateMaterial("");
                            }
                          } else {
                            setUpdateMaterial(material?.name);
                            setMaterialUpdate(material?.id);
                          }
                        }}
                        aria-disabled={
                          materialUpdate === material?.id &&
                          (material?.name === updateMaterial || !updateMaterial)
                        }
                      >
                        <FontAwesomeIcon
                          icon={
                            materialUpdate === material?.id
                              ? faFloppyDisk
                              : faPen
                          }
                          className={"text-white text-[14px]"}
                        />
                      </div>
                      <div
                        className="h-[30px] w-[30px] bg-red-500 rounded-full flex justify-center items-center ml-4 cursor-pointer"
                        onClick={() => {
                          if (materialUpdate === material?.id) {
                            setMaterialUpdate(-1);
                            setUpdateMaterial("");
                          } else {
                            showDeleteConfirm(material?.id);
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          icon={
                            materialUpdate === material?.id ? faClose : faTrash
                          }
                          className={"text-white text-[14px]"}
                        />
                      </div>
                    </div>
                  </>
                );
              }
            })}
            <div className="flex flex-row justify-between items-center">
              <TextField
                className="border border-gray rounded-md min-h-[40px] px-3 py-2 outline-none my-2 flex flex-1"
                placeholder="Material"
                value={newMaterial}
                onChange={(e) => {
                  setNewMaterial(e.target.value);
                }}
              />
              <div className="flex flex-row">
                <div
                  className="h-[30px] w-[30px] bg-primary rounded-full flex justify-center items-center ml-4 cursor-pointer aria-disabled:opacity-30 aria-disabled:pointer-events-none"
                  onClick={() => {
                    if (newMaterial) {
                      createMaterialData(newMaterial);
                      setNewMaterial("");
                    }
                  }}
                  aria-disabled={!newMaterial}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className={"text-white text-[14px]"}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const SubCategoryElements = ({
  activeParams,
  menus,
  allInspectionSlugs,
  materialData,
  materialTypes,
  setMaterialTypes,
  materialEdit,
  setMaterialEdit,
  createMaterialData,
  updateMaterialData,
  deleteMaterialData,
  subCategoryData,
  setSubCategoryData,
  subCategoryTypeData,
  setSubCategoryTypeData,
  quickNoteData,
  uploadImage,
  imageUploadLoading,
  setShowImageEdit,
  showImageEdit,
  imageRef,
  showMarkerArea,
  editImageURL,
  setEditImageURL,
  setEditImageId,
  next,
  saveInspection,
  visibleMobileUpload,
  setVisibleMobileUpload,
  uploadCategory,
  setUploadCategory,
  uploadSubCategoryTypeData,
  setUploadSubCategoryTypeData,
  uploadSubCategoryTypeIndex,
  setUploadSubCategoryTypeIndex,
  showCameraActionSheet,
  setShowCameraActionSheet,
  visibleGalleryUpload,
  setVisibleGalleryUpload,
  isDescriptionRequired,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();

  const [accordianOpen, setAccordianOpen] = useState(-1);
  const [imgPreview, setImgPreview] = useState([
    {
      status: false,
      imgUrl: null,
    },
  ]);
  const quickNoteOptions = useMemo(
    () =>
      quickNoteData?.map((quickNote) => {
        return {
          label: quickNote?.note,
          value: quickNote?.note,
        };
      }),
    [quickNoteData]
  );

  let isLastMenu = false;
  if (activeParams?.submenu) {
    const isLastMainMenu =
      menus?.length <=
      Object?.keys(allInspectionSlugs)?.indexOf(activeParams?.menu) + 1;
    if (isLastMainMenu) {
      const lastSubMenu = allInspectionSlugs?.[`${activeParams?.menu}`] || [];
      isLastMenu =
        lastSubMenu?.length <=
        lastSubMenu?.indexOf(Number(activeParams?.submenu)) + 1;
    }
  }

  const findCurrentCategoryDataById = (categoryId) => {
    const catData = [...menus].filter((category) => {
      return category?.categoryId === Number(categoryId);
    });
    if (catData?.length > 0) {
      return { ...catData?.[0] };
    }
    return {};
  };

  const findCurrentSubCategoryDataById = (currentCat, subCategoryId) => {
    let subCatData = [];
    if (currentCat?.exteriorSubCategories?.length > 0) {
      subCatData = [...currentCat?.exteriorSubCategories].filter(
        (subCategory) => {
          return subCategory?.subCategoryId === Number(subCategoryId);
        }
      );
    }
    if (subCatData?.length > 0) {
      return { ...subCatData?.[0] };
    }
    return {};
  };

  let currentMenu = findCurrentCategoryDataById(Number(activeParams?.menu));
  let currentSubMenu = findCurrentSubCategoryDataById(
    currentMenu,
    Number(activeParams?.submenu)
  );

  let isSubCatFilled = false;

  if (
    subCategoryData?.description ||
    materialTypes?.length > 0 ||
    subCategoryData?.attachments?.length > 0
  ) {
    isSubCatFilled = true;
  }

  return (
    <>
      <h2 className="text-xs text-typo-primary mb-5">
        <NavLink
          to={`/inspection/${activeParams?.menu}`}
          className="text-typo-primary"
        >
          {currentMenu?.name}
        </NavLink>{" "}
        / {currentSubMenu?.name}
      </h2>
      <MultipleImageUpload
        visible={visibleMobileUpload}
        cancel={() => {
          setVisibleMobileUpload(false);
        }}
        callBack={(data) => {
          if (uploadCategory === "subCategoryData") {
            let attachments = [];
            if (subCategoryData?.attachments?.length > 0) {
              attachments = [...subCategoryData?.attachments];
            }
            data.map(async (e) => {
              const image = await uploadImage(e);
              if (image?.imageId) {
                attachments?.push(image);
                setSubCategoryData({
                  ...subCategoryData,
                  attachments: [...attachments],
                });
              }
            });
          } else if (uploadCategory === "subCategoryTypeData") {
            let attachments = [];
            if (uploadSubCategoryTypeData?.attachments?.length > 0) {
              attachments = [...uploadSubCategoryTypeData?.attachments];
            }
            if (data?.length > 0) {
              data.map(async (e) => {
                const image = await uploadImage(e);
                if (image?.imageId) {
                  attachments?.push(image);
                }
                setSubCategoryTypeData((subCategoryTypeData) =>
                  subCategoryTypeData.map((obj, i) => {
                    if (i === uploadSubCategoryTypeIndex) {
                      return {
                        ...obj,
                        attachments: [...attachments],
                      };
                    }
                    return obj;
                  })
                );
              });
            }
          }
          setVisibleMobileUpload(false);
        }}
      />
      <ImageUploadActionMenu
        visible={showCameraActionSheet}
        camera={() => {
          setShowCameraActionSheet(false);
          setVisibleMobileUpload(true);
        }}
        gallery={() => {
          setShowCameraActionSheet(false);
          dispatch({ type: "SET_OPEN_GALLERY_UPLOAD", payload: true });
          setTimeout(() => {
            dispatch({ type: "SET_OPEN_GALLERY_UPLOAD", payload: false });
          }, 100);
        }}
        cancel={() => {
          setShowCameraActionSheet(false);
          setVisibleMobileUpload(false);
          setVisibleGalleryUpload(false);
        }}
      />

      <ImageUploadGallery
        visible={visibleGalleryUpload}
        cancel={() => {
          setVisibleGalleryUpload(false);
        }}
        callBack={(data) => {
          if (uploadCategory === "subCategoryData") {
            let attachments = [];
            if (subCategoryData?.attachments?.length > 0) {
              attachments = [...subCategoryData?.attachments];
            }
            data.map(async (e) => {
              const image = await uploadImage(e);
              if (image?.imageId) {
                attachments?.push(image);
                setSubCategoryData({
                  ...subCategoryData,
                  attachments: [...attachments],
                });
              }
            });
          } else if (uploadCategory === "subCategoryTypeData") {
            let attachments = [];
            if (uploadSubCategoryTypeData?.attachments?.length > 0) {
              attachments = [...uploadSubCategoryTypeData?.attachments];
            }
            if (data?.length > 0) {
              data.map(async (e) => {
                const image = await uploadImage(e);
                if (image?.imageId) {
                  attachments?.push(image);
                }
                setSubCategoryTypeData((subCategoryTypeData) =>
                  subCategoryTypeData.map((obj, i) => {
                    if (i === uploadSubCategoryTypeIndex) {
                      return {
                        ...obj,
                        attachments: [...attachments],
                      };
                    }
                    return obj;
                  })
                );
              });
            }
          }
          setVisibleMobileUpload(false);
        }}
      />

      <div className="accordions space-y-3">
        {currentSubMenu && (
          <div className="accordion-main drop-shadow-md bg-white border border-gray rounded overflow-hidden">
            <div
              className={`accordian-title flex justify-between items-center px-3 py-2 ${
                isSubCatFilled ? "bg-green-200" : "bg-light"
              }`}
              onClick={() => setAccordianOpen(-1)}
            >
              <h3 className="mb-0">{currentSubMenu?.name}</h3>
              <FontAwesomeIcon
                icon={accordianOpen === -1 ? faAngleUp : faAngleDown}
              />
            </div>
            <div
              className={`accordian-content overflow-hidden${
                accordianOpen === -1 ? "" : " h-0"
              }`}
            >
              <div className="accordian-content--inner p-3">
                <div className="w-full">
                  <div className="flex flex-wrap">
                    {imageUploadLoading ? (
                      <div className="h-[80px] w-[80px] rounded-md bg-[#FEEFCC] border-[#FCAE01] border-[1px] flex justify-center items-center cursor-pointer mr-4 mb-4">
                        <div className="flex flex-col justify-center items-center">
                          <div className="animate-spin">
                            <FontAwesomeIcon icon={faSpinner} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="mr-4 mb-4">
                        <div
                          className="h-[80px] w-[80px] rounded-md bg-[#FEEFCC] border-[#FCAE01] border-[1px] flex justify-center items-center cursor-pointer"
                          onClick={() => {
                            setShowCameraActionSheet(true);
                            setUploadCategory("subCategoryData");
                          }}
                        >
                          <div className="flex flex-col justify-center items-center">
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              className={"text-[#FCAE01] text-[30px]"}
                            />
                            <p className="mt-1 mb-0">{t("COMMON.IMAGE")}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {subCategoryData?.attachments?.length > 0 &&
                      [...subCategoryData?.attachments]?.map((image, index) => {
                        return (
                          <div key={index} className="">
                            <InspectionImage
                              key={index}
                              data={image}
                              onPreview={() => {
                                setImgPreview([
                                  {
                                    status: true,
                                    imgUrl: image?.imageUrl,
                                  },
                                ]);
                              }}
                              onEdit={() => {
                                localStorage.setItem(
                                  "editImageId",
                                  image?.imageId
                                );
                                localStorage.setItem(
                                  "editImageType",
                                  "subCategory"
                                );
                                setEditImageId(image?.imageId);
                                setLoader(true);
                                fetch(image?.imageUrl)
                                  .then((res) => res.blob())
                                  .then((blob) => {
                                    try {
                                      let objectURL = URL.createObjectURL(blob);
                                      setEditImageURL(objectURL);
                                      setShowImageEdit(true);
                                      setTimeout(() => {
                                        showMarkerArea();
                                      }, 1000);
                                    } catch (error) {
                                      setShowImageEdit(false);
                                      console.log(error);
                                    }
                                    setLoader(false);
                                  })
                                  .catch((err) => {
                                    setLoader(true);
                                  });
                              }}
                              onDelete={() => {
                                let attachments = [];
                                if (subCategoryData?.attachments?.length > 0) {
                                  attachments = [
                                    ...subCategoryData?.attachments,
                                  ];
                                }
                                attachments.splice(index, 1);
                                setSubCategoryData({
                                  ...subCategoryData,
                                  attachments: [...attachments],
                                });
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                  <MaterialData
                    materialData={materialData}
                    materialTypes={materialTypes}
                    setMaterialTypes={setMaterialTypes}
                    materialEdit={materialEdit}
                    setMaterialEdit={setMaterialEdit}
                    createMaterialData={createMaterialData}
                    updateMaterialData={updateMaterialData}
                    deleteMaterialData={deleteMaterialData}
                  />
                  {params?.submenu &&
                    isDescriptionRequired[params?.menu][0][params?.submenu] ===
                      true && (
                      <div>
                        {quickNoteData.length >= 1 && (
                          <Select
                            placeholder={t(
                              `INSPECTION_PAGE.COMMON.CHOOSE_NOTE`
                            )}
                            className="w-full mb-4"
                            options={[
                              {
                                label: t(`INSPECTION_PAGE.COMMON.CHOOSE_NOTE`),
                                value: 0,
                              },
                              ...quickNoteOptions,
                            ]}
                            onChange={(value) => {
                              setSubCategoryData({
                                ...subCategoryData,
                                description: value,
                              });
                            }}
                            value={0}
                          />
                        )}
                        <textarea
                          className="w-full border border-gray rounded-md p-2 outline-none"
                          placeholder="Take Note"
                          rows={3}
                          value={subCategoryData?.description || ""}
                          onChange={(e) => {
                            setSubCategoryData({
                              ...subCategoryData,
                              description: e.target.value,
                            });
                          }}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        )}
        {subCategoryTypeData?.length > 0 &&
          [...subCategoryTypeData]?.map((subCategoryType, index) => {
            let isTypeFilled = false;
            if (
              subCategoryType?.description ||
              subCategoryType?.attachments?.length > 0
            ) {
              isTypeFilled = true;
            }
            return (
              <div
                key={index}
                className="accordion-main drop-shadow-md bg-white border border-gray rounded overflow-hidden"
              >
                <div
                  className={`accordian-title flex justify-between items-center px-3 py-2 ${
                    isTypeFilled ? "bg-green-200" : "bg-light"
                  }`}
                  onClick={() => setAccordianOpen(index)}
                >
                  <h3 className="mb-0">
                    {subCategoryType?.subCategoryTypeName || "-"}
                  </h3>
                  <FontAwesomeIcon
                    icon={accordianOpen === index ? faAngleUp : faAngleDown}
                  />
                </div>
                <div
                  className={`accordian-content overflow-hidden${
                    accordianOpen === index ? "" : " h-0"
                  }`}
                >
                  <div className="accordian-content--inner p-3">
                    <div className="w-full" key={index}>
                      <label className="flex flex-wrap items-center text-gray-700 text-sm">
                        {!subCategoryType?.subCategoryTypeId && (
                          <>
                            <TextField
                              className="border border-gray rounded-md px-3 py-2 outline-none mb-3 flex flex-1"
                              placeholder="Name"
                              value={subCategoryType?.subCategoryTypeName}
                              onChange={(e) => {
                                // const newData = subCategoryTypeData;
                                // newData[index].subCategoryTypeName = e.target.value;
                                // setSubCategoryTypeData([...newData]);
                                setSubCategoryTypeData((subCategoryTypeData) =>
                                  subCategoryTypeData.map((obj, i) => {
                                    if (i === index) {
                                      return {
                                        ...obj,
                                        subCategoryTypeName: e.target.value,
                                      };
                                    }
                                    return obj;
                                  })
                                );
                              }}
                            />
                            <div
                              className="h-[30px] w-[30px] bg-red-500 rounded-full flex justify-center items-center cursor-pointer mb-3 mx-3 last:mr-0"
                              onClick={() => {
                                // let newData = [...subCategoryTypeData];
                                // newData.splice(index, 1);
                                // setSubCategoryTypeData([...newData]);
                                setSubCategoryTypeData((subCategoryTypeData) =>
                                  subCategoryTypeData.filter((obj, i) => {
                                    return i !== index;
                                  })
                                );
                                setAccordianOpen(index - 1);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className={"text-white text-[14px]"}
                              />
                            </div>
                          </>
                        )}
                      </label>
                      <div className="flex flex-wrap">
                        {imageUploadLoading ? (
                          <div className="h-[80px] w-[80px] rounded-md bg-[#FEEFCC] border-[#FCAE01] border-[1px] flex justify-center items-center cursor-pointer mr-4 mb-4">
                            <div className="flex flex-col justify-center items-center">
                              <div className="animate-spin">
                                <FontAwesomeIcon icon={faSpinner} />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="mr-4 mb-4">
                            <div
                              className="h-[80px] w-[80px] rounded-md bg-[#FEEFCC] border-[#FCAE01] border-[1px] flex justify-center items-center cursor-pointer"
                              onClick={() => {
                                setShowCameraActionSheet(true);
                                setUploadCategory("subCategoryTypeData");
                                setUploadSubCategoryTypeData(
                                  subCategoryTypeData
                                );
                                setUploadSubCategoryTypeIndex(index);
                              }}
                            >
                              <div className="flex flex-col justify-center items-center">
                                <FontAwesomeIcon
                                  icon={faPlusCircle}
                                  className={"text-[#FCAE01] text-[30px]"}
                                />
                                <p className="mt-1 mb-0">{t("COMMON.IMAGE")}</p>
                              </div>
                            </div>
                          </div>
                        )}
                        {subCategoryType?.attachments?.length > 0 &&
                          [...subCategoryType?.attachments]?.map(
                            (image, key) => {
                              return (
                                <InspectionImage
                                  key={key}
                                  data={image}
                                  onPreview={() => {
                                    setImgPreview([
                                      {
                                        status: true,
                                        imgUrl: image?.imageUrl,
                                      },
                                    ]);
                                  }}
                                  onEdit={() => {
                                    localStorage.setItem(
                                      "editImageId",
                                      image?.imageId
                                    );
                                    localStorage.setItem(
                                      "editImageType",
                                      "subCategoryType"
                                    );
                                    localStorage.setItem(
                                      "subCategoryTypeId",
                                      subCategoryType?.subCategoryTypeId
                                    );
                                    setEditImageId(image?.imageId);
                                    setLoader(true);
                                    fetch(image?.imageUrl)
                                      .then((res) => res.blob())
                                      .then((blob) => {
                                        try {
                                          let objectURL =
                                            URL.createObjectURL(blob);
                                          setEditImageURL(objectURL);
                                          setShowImageEdit(true);
                                          setTimeout(() => {
                                            showMarkerArea();
                                          }, 1000);
                                        } catch (error) {
                                          setShowImageEdit(false);
                                          console.log(error);
                                        }
                                        setLoader(false);
                                      })
                                      .catch((err) => {
                                        setLoader(false);
                                      });
                                  }}
                                  onDelete={() => {
                                    let attachments = [];
                                    if (
                                      subCategoryType?.attachments?.length > 0
                                    ) {
                                      attachments = [
                                        ...subCategoryType?.attachments,
                                      ];
                                      attachments.splice(key, 1);
                                    }
                                    setSubCategoryTypeData(
                                      (subCategoryTypeData) =>
                                        subCategoryTypeData.map((obj, i) => {
                                          if (i === index) {
                                            return {
                                              ...obj,
                                              attachments: attachments,
                                            };
                                          }
                                          return obj;
                                        })
                                    );
                                  }}
                                />
                              );
                            }
                          )}
                      </div>
                      {quickNoteData.length >= 1 && (
                        <Select
                          placeholder={t(`INSPECTION_PAGE.COMMON.CHOOSE_NOTE`)}
                          className="w-full mb-4"
                          options={[
                            {
                              label: t(`INSPECTION_PAGE.COMMON.CHOOSE_NOTE`),
                              value: 0,
                            },
                            ...quickNoteOptions,
                          ]}
                          onChange={(value) => {
                            // const newData = subCategoryTypeData;
                            // newData[index].description = value;
                            // setSubCategoryTypeData([...newData]);
                            setSubCategoryTypeData((subCategoryTypeData) =>
                              subCategoryTypeData.map((obj, i) => {
                                if (i === index) {
                                  return { ...obj, description: value };
                                }
                                return obj;
                              })
                            );
                          }}
                          value={0}
                        />
                      )}
                      <textarea
                        className="w-full border border-gray rounded-md p-2 outline-none"
                        placeholder="Take Note"
                        rows={3}
                        value={subCategoryType?.description || ""}
                        onChange={(e) => {
                          // const newData = subCategoryTypeData;
                          // newData[index].description = e.target.value;
                          // setSubCategoryTypeData([...newData]);
                          setSubCategoryTypeData((subCategoryTypeData) =>
                            subCategoryTypeData.map((obj, i) => {
                              if (i === index) {
                                return {
                                  ...obj,
                                  description: e.target.value,
                                };
                              }
                              return obj;
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="flex flex-wrap items-center justify-end space-x-2 mt-3">
        {activeParams?.submenu && (
          <Button
            title={t(`COMMON.ADD_NEW`)}
            icon={
              <FontAwesomeIcon
                icon={faPlusCircle}
                className={"text-white text-xl"}
              />
            }
            // rounded={true}
            // spaceXSmall={true}
            className="text-sm my-2"
            onClick={() => {
              let subCategoryTypeObj = {
                inspectionSubCategoryId: null,
                subCategoryTypeName: "",
                name: "",
                description: "",
                imageIds: [],
              };
              // setSubCategoryTypeData([
              //   ...subCategoryTypeData,
              //   subCategoryTypeObj,
              // ]);
              setSubCategoryTypeData((subCategoryTypeData) => [
                ...subCategoryTypeData,
                subCategoryTypeObj,
              ]);
              setAccordianOpen(subCategoryTypeData?.length);
            }}
          />
        )}
        <Button
          title={isLastMenu ? t(`COMMON.SUBMIT`) : t(`COMMON.NEXT`)}
          spaceXSmall
          className="text-sm my-2"
          onClickDisabled={500}
          onClick={() => {
            isLastMenu ? saveInspection("submit") : next();
          }}
        />
      </div>
      <Image
        // width={200}
        style={{ display: "none" }}
        preview={{
          visible: imgPreview[0].status,
          src: imgPreview[0].imgUrl,
          onVisibleChange: (visible, src) => {
            setImgPreview([{ status: visible, imgUrl: src }]);
          },
        }}
        src={imgPreview[0].imgUrl}
      />

      {/* Image Edit Starts */}
      {showImageEdit && (
        <div className="bg-white absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center flex-col z-[100]">
          <p
            className="absolute top-8 right-8 text-3xl text-gray-700 cursor-pointer"
            onClick={() => setShowImageEdit(false)}
          >
            X
          </p>
          <div className="flex justify-center items-center text-2xl text-type-dark font-semibold absolute top-0 left-0 right-0 bottom-0 z-[1001] bg-white">
            <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-3" />
            Loading...
          </div>
          <img
            alt="Image Preview"
            className="rounded-xl max-w-[80vw] max-h-[70vh] object-cover opacity-0"
            ref={imageRef}
            src={editImageURL}
          />
        </div>
      )}
      {/* Image Edit Ends */}
    </>
  );
};

const InspectionMobilePresentational = ({
  containerRef,
  client,
  menus,
  activeParams,
  allInspectionSlugs,
  inspectionData,
  materialEdit,
  setMaterialEdit,
  uploadImage,
  imageUploadLoading,
  next,
  saveInspection,
  quickNoteData,
  materialData,
  materialTypes,
  setMaterialTypes,
  createMaterialData,
  updateMaterialData,
  deleteMaterialData,
  subCategoryData,
  setSubCategoryData,
  subCategoryTypeData,
  setSubCategoryTypeData,
  total,
  setTotal,
  taxPercentage,
  setShowImageEdit,
  showImageEdit,
  showMarkerArea,
  imageRef,
  editImageURL,
  setEditImageURL,
  editImageId,
  setEditImageId,
  visibleMobileUpload,
  setVisibleMobileUpload,
  uploadCategory,
  setUploadCategory,
  uploadSubCategoryTypeData,
  setUploadSubCategoryTypeData,
  uploadSubCategoryTypeIndex,
  setUploadSubCategoryTypeIndex,
  showCameraActionSheet,
  setShowCameraActionSheet,
  visibleGalleryUpload,
  setVisibleGalleryUpload,
  isDescriptionRequired,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  let clientName =
    client?.name && Object?.values(client?.name)?.join(" ")?.trim();

  return (
    <div ref={containerRef} className="flex flex-col flex-1 -m-6">
      <div className="back-button-wrapper flex flex-1 justify-between sticky top-0 right-0 inset-x-0 overflow-hidden z-10 bg-light px-4">
        <button
          className="bg-light py-3"
          onClick={() => {
            if (activeParams?.submenu) {
              navigate(`/inspection/${activeParams?.menu}`);
            } else if (activeParams?.menu) {
              navigate("/inspection");
            } else {
              if (inspectionData?.length > 0) {
                navigate("/?tab=1");
              } else {
                navigate("/");
              }
            }
          }}
        >
          <FontAwesomeIcon icon={faAngleLeft} size="2xl" className="mr-2" />
          {t(`COMMON.BACK`)}
        </button>
        <div className="flex items-center text-md">{clientName}</div>
      </div>
      {/* )} */}
      <div className="inspection-wrapper p-4">
        {activeParams?.submenu ? (
          <>
            <SubCategoryElements
              activeParams={activeParams}
              menus={menus}
              allInspectionSlugs={allInspectionSlugs}
              inspectionData={inspectionData}
              materialData={materialData}
              materialTypes={materialTypes}
              setMaterialTypes={setMaterialTypes}
              materialEdit={materialEdit}
              setMaterialEdit={setMaterialEdit}
              createMaterialData={createMaterialData}
              updateMaterialData={updateMaterialData}
              deleteMaterialData={deleteMaterialData}
              subCategoryData={subCategoryData}
              setSubCategoryData={setSubCategoryData}
              subCategoryTypeData={subCategoryTypeData}
              setSubCategoryTypeData={setSubCategoryTypeData}
              quickNoteData={quickNoteData}
              uploadImage={uploadImage}
              imageUploadLoading={imageUploadLoading}
              setShowImageEdit={setShowImageEdit}
              showImageEdit={showImageEdit}
              imageRef={imageRef}
              showMarkerArea={showMarkerArea}
              editImageURL={editImageURL}
              setEditImageURL={setEditImageURL}
              setEditImageId={setEditImageId}
              next={next}
              saveInspection={saveInspection}
              visibleMobileUpload={visibleMobileUpload}
              setVisibleMobileUpload={setVisibleMobileUpload}
              uploadCategory={uploadCategory}
              setUploadCategory={setUploadCategory}
              uploadSubCategoryTypeData={uploadSubCategoryTypeData}
              setUploadSubCategoryTypeData={setUploadSubCategoryTypeData}
              uploadSubCategoryTypeIndex={uploadSubCategoryTypeIndex}
              setUploadSubCategoryTypeIndex={setUploadSubCategoryTypeIndex}
              showCameraActionSheet={showCameraActionSheet}
              setShowCameraActionSheet={setShowCameraActionSheet}
              visibleGalleryUpload={visibleGalleryUpload}
              setVisibleGalleryUpload={setVisibleGalleryUpload}
              isDescriptionRequired={isDescriptionRequired}
            />
          </>
        ) : activeParams?.menu ? (
          <div className="inspection-sub-menus space-y-3">
            <CatSubMenu
              currentMenuId={activeParams?.menu}
              menus={menus}
              inspectionData={inspectionData}
            />
          </div>
        ) : (
          menus?.length > 0 && (
            <div className="inspection-menus space-y-3">
              {menus?.map((menu) => {
                return (
                  <CatMenu
                    key={menu?.categoryId}
                    menu={menu}
                    inspectionData={inspectionData}
                  />
                );
              })}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default InspectionMobilePresentational;
