export const accountEdit = require("./edit_account.png");
export const notificationBell = require("./notification-bell.png");
export const user = require("./user.png");
export const users = require("./users.png");
const inspectionMenuYellow = require("./inspection_menu_yellow.png");
const inspectionMenuGreen = require("./inspection_menu_green.png");

const icons = {
  accountEdit,
  notificationBell,
  user,
  users,
  inspectionMenuYellow,
  inspectionMenuGreen,
};

export default icons;
