import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import notification from "utils/notification";
import QuickNoteService from "services/QuickNoteService";
import VTabs from "components/tabs/v-tabs";
import Button from "components/Button";
import Modal from "components/Modal";
import TextArea from "antd/lib/input/TextArea";
import confirm from "antd/lib/modal/confirm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faPencil,
  faPlusCircle,
  faTrashCan,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import setLoader from "utils/loader";

const TabContent = ({ pageLoader, setPageLoader, categoryId }) => {
  const { t } = useTranslation();
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [formEditData, setFormEditData] = useState(null);
  const [quickNotes, setQuickNotes] = useState([]);
  const [note, setNote] = useState("");

  const getQuickNotes = useCallback(() => {
    setPageLoader(true);
    setLoader(true);
    QuickNoteService.getQuickNotes({ categoryId: categoryId })
      .then((res) => {
        setPageLoader(false);
        setLoader(false);
        if (res?.data) {
          setQuickNotes(res?.data);
        } else if (parseInt(res.status, 0) === 204) {
          if (quickNotes?.length > 0) {
            setQuickNotes([]);
          }
          // notification.info(t(`TOASTR.API.DATA_NOT_FOUND`))
        }
      })
      .catch((e) => {
        notification.error(e);
        setPageLoader(false);
        setLoader(false);
      });
  }, [t, setPageLoader, categoryId]);

  const createQuickNote = useCallback(
    (note) => {
      setPageLoader(true);
      setLoader(true);
      const request = {
        note: note,
        categoryId: categoryId,
      };
      QuickNoteService.createQuickNote(request)
        .then((res) => {
          if (parseInt(res.status, 0) === 201) {
            notification.success(t(`TEMPLATES_PAGE.QUICK_NOTE.CREATE_SUCCESS`));
            setNote("");
            setIsFormModalOpen(false);
            getQuickNotes();
          } else if (parseInt(res.status, 0) === 204) {
            notification.info(t(`TOASTR.API.DATA_NOT_FOUND`));
          }
          setPageLoader(false);
          setLoader(false);
        })
        .catch((e) => {
          notification.error(e);
          setPageLoader(false);
          setLoader(false);
        });
    },
    [t, setPageLoader, categoryId, getQuickNotes, setNote, setIsFormModalOpen]
  );

  const updateQuickNote = useCallback(
    (id, note) => {
      setPageLoader(true);
      setLoader(true);
      const request = {
        note: note,
        categoryId: categoryId,
      };
      QuickNoteService.updateQuickNote(id, request)
        .then((res) => {
          if (parseInt(res.status, 0) === 200) {
            notification.success(t(`TEMPLATES_PAGE.QUICK_NOTE.UPDATE_SUCCESS`));
            setNote("");
            setIsFormModalOpen(false);
            getQuickNotes();
          } else if (parseInt(res.status, 0) === 204) {
            notification.info(t(`TOASTR.API.DATA_NOT_FOUND`));
          }
          setPageLoader(false);
          setLoader(false);
        })
        .catch((e) => {
          notification.error(e);
          setPageLoader(false);
          setLoader(false);
        });
    },
    [t, setPageLoader, categoryId, getQuickNotes, setNote, setIsFormModalOpen]
  );

  const deleteQuickNote = useCallback(
    (id) => {
      setPageLoader(true);
      setLoader(true);
      QuickNoteService.deleteQuickNote(id)
        .then((res) => {
          if (parseInt(res.status, 0) === 200) {
            notification.success(t(`TEMPLATES_PAGE.QUICK_NOTE.DELETE_SUCCESS`));
            getQuickNotes();
          } else if (parseInt(res.status, 0) === 204) {
            if (quickNotes?.length > 0) {
              getQuickNotes();
            }
            notification.info(t(`TOASTR.API.DATA_NOT_FOUND`));
          }
          setPageLoader(false);
          setLoader(false);
        })
        .catch((e) => {
          notification.error(e);
          setPageLoader(false);
          setLoader(false);
        });
    },
    [t, setPageLoader, getQuickNotes]
  );

  useEffect(() => {
    getQuickNotes();
  }, [getQuickNotes]);

  const showFormModal = useCallback(
    (qucikNote = {}) => {
      setFormEditData(qucikNote);
      setNote(qucikNote?.note);
      setIsFormModalOpen(true);
    },
    [setNote, setFormEditData, setIsFormModalOpen]
  );

  const showDeleteConfirm = useCallback(
    (id) => {
      confirm({
        title: t(`COMMON.CONFIRM_DELETE`),
        icon: (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size={"xl"}
            className="float-left mr-4 text-red-600"
          />
        ),
        okText: t(`COMMON.YES`),
        okButtonProps: {
          className:
            "font-medium text-gray-600 hover:text-primary border-gray-300 hover:border-primary rounded",
        },
        okType: "danger",
        cancelText: t(`COMMON.CANCEL`),
        cancelButtonProps: {
          className:
            "font-medium rounded text-white bg-primary border border-primary hover:border-primary",
        },
        className: "rounded",
        onOk() {
          deleteQuickNote(id);
        },
        onCancel() {},
      });
    },
    [t, deleteQuickNote]
  );

  const FormModal = () => {
    return (
      <Modal
        title={
          formEditData?.noteId > 0
            ? t(`TEMPLATES_PAGE.QUICK_NOTE.EDIT`)
            : t(`TEMPLATES_PAGE.QUICK_NOTE.ADD`)
        }
        content={
          <>
            <TextArea
              rows={6}
              className={
                "shadow-none border-typo-light focus-within:ring-1 focus-within:border-primary focus-within:ring-primary"
              }
              value={note}
              onChange={(e) => setNote(e?.target?.value)}
            />
          </>
        }
        okBtnText={
          formEditData?.noteId > 0 ? t(`COMMON.EDIT`) : t(`COMMON.ADD`)
        }
        isModalOpen={isFormModalOpen}
        setIsModalOpen={setIsFormModalOpen}
        onOk={() => {
          formEditData?.noteId > 0
            ? updateQuickNote(formEditData?.noteId, note)
            : createQuickNote(note);
        }}
        onCancel={() => {
          setNote("");
        }}
        footer={true}
        isLoading={pageLoader}
        disabled={!note || note === formEditData?.note}
      />
    );
  };

  return (
    <>
      <div className="qucik-notes">
        <div className="qucik-notes-add">
          <div className="add-quick-note-button-div mb-5">
            <Button
              title={t(`COMMON.ADD_NEW`)}
              icon={
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  className={"text-white text-xl"}
                />
              }
              rounded={true}
              spaceXSmall={true}
              className="ml-auto"
              onClick={() => {
                showFormModal();
              }}
            />
          </div>
        </div>
        {quickNotes?.length > 0 && (
          <div className="qucik-notes-list">
            {quickNotes.map((qucikNote) => {
              return (
                <div
                  key={qucikNote?.noteId}
                  className="qucik-note mb-4 last:mb-0"
                >
                  <div className="flex p-4 bg-primary-light rounded-lg">
                    <div className="text-sm font-medium text-typo-primary text-justify pr-3 mr-auto">
                      {qucikNote?.note}
                    </div>
                    <button
                      type="button"
                      className="justify-center items-center self-center -my-1.5 text-typo-primary rounded-lg focus-within:ring-2 focus-within:ring-primary p-1.5 hover:bg-primary/70 hover:text-white inline-flex h-8 w-8"
                      aria-label={t(`COMMON.EDIT`)}
                      onClick={() => {
                        showFormModal(qucikNote);
                      }}
                    >
                      <span className="sr-only">{t(`COMMON.EDIT`)}</span>
                      <FontAwesomeIcon icon={faPencil} size={"lg"} />
                    </button>
                    <button
                      type="button"
                      className="justify-center items-center self-center -my-1.5 ml-1 text-red-500 rounded-lg focus-within:ring-2 focus-within:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8"
                      aria-label={t(`COMMON.DELETE`)}
                      onClick={() => {
                        showDeleteConfirm(qucikNote?.noteId);
                      }}
                    >
                      <span className="sr-only">{t(`COMMON.DELETE`)}</span>
                      <FontAwesomeIcon icon={faTrashCan} size={"lg"} />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {isFormModalOpen && FormModal()}
      </div>
    </>
  );
};

const QucikNotesPresentational = ({
  pageLoader,
  setPageLoader,
  currentTab,
  setCurrentTab,
  inspaectionCategory,
}) => {
  const tabsData = inspaectionCategory?.map((template) => {
    return {
      tabTitle: template?.name,
      tabContent: (
        <TabContent
          pageLoader={pageLoader}
          setPageLoader={setPageLoader}
          categoryId={template?.categoryId}
          currentTab={currentTab}
        />
      ),
      data: template,
      icon: (
        <FontAwesomeIcon
          icon={faFileAlt}
          size={"xs"}
          className={"text-white"}
        />
      ),
    };
  });

  const onTabChange = useCallback(
    (key, tabData) => {
      setCurrentTab({
        key: key,
        data: tabData?.data,
      });
    },
    [setCurrentTab]
  );

  return (
    <>
      <VTabs tabsData={tabsData} onTabChange={onTabChange} onClickLoad />
    </>
  );
};

export default QucikNotesPresentational;
