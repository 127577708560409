import { useRef } from "react";
import { useTranslation } from "react-i18next";
import AuthService from "services/AuthService";
import hasPermissions from "utils/permissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faLocationPin,
  faEllipsisVertical,
  faEdit,
  faTrash,
  faFileEdit,
  faIdCard,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import Images from "assets/images/images";
const ClientCard = ({
  key,
  data,
  onEditClient,
  onDeleteClient,
  isMobile,
  onUploadImage,
}) => {
  const { t } = useTranslation();
  const imgUpload = useRef(null);
  const navigation = useNavigate();
  const CurrentUser = AuthService?.getCurrentUser();

  return (
    <>
      <div
        className="flex flex-col w-full rounded overflow-hidden shadow-lg border-2 pb-2 mb-4"
        key={key}
      >
        <div className="relative">
          <img
            className="w-full bg-gray-400 aspect-video object-cover"
            src={data?.inspectionImageUrl?.url || Images.propertyImg}
            alt={
              data?.client?.name?.firstName + " " + data?.client?.name?.lastName
            }
          />
          {/* {data?.state && (
            <div className="absolute top-3 left-3 bg-orange-100 px-3 py-0.5 rounded-full text-[10px] font-semibold">
              {data?.state && data?.state.replaceAll("_", " ")}
            </div>
          )} */}
          <div className="absolute bottom-3 left-3 bg-gray-300 px-3 py-0.5 rounded-full text-[10px] font-semibold">
            {data?.client?.propertyType || "House"}
          </div>
          <div className="absolute bottom-3 right-3 px-3 py-0.5 text-sm font-semibold text-white">
            {data?.client?.clientNumber && "#" + data?.client?.clientNumber}
          </div>
          <div className="absolute top-3 right-3">
            {hasPermissions.admin(CurrentUser?.role) && (
              <div className="group inline-block relative">
                <button className="text-white" type="button">
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    className="w-5 h-5"
                  />
                </button>
                <ul className="absolute hidden group-hover:block right-0 mb-0 text-sm text-gray-700 w-36 bg-white">
                  <li className="w-full">
                    <a
                      className="block py-2 px-4 text-black hover:bg-gray-100"
                      onClick={() => onEditClient(data)}
                    >
                      <FontAwesomeIcon icon={faEdit} className="mr-2" />
                      {t(`COMMON.EDIT_INFO`)}
                    </a>
                  </li>
                  {data?.state === "COMPLETED" && (
                    <li className="w-full">
                      <a
                        className="block py-2 px-4 text-black hover:bg-gray-100"
                        onClick={() => {
                          localStorage.setItem(
                            "client",
                            JSON.stringify(data?.client)
                          );
                          localStorage.setItem(
                            "inspectionId",
                            data?.inpsectionId || null
                          );
                          navigation("/inspection/1");
                        }}
                      >
                        <FontAwesomeIcon icon={faFileEdit} className="mr-2" />
                        {t(`COMMON.EDIT`)}
                      </a>
                    </li>
                  )}
                  {(data?.state === "IN_PROGRESS" ||
                    data?.state === "COMPLETED") &&
                    data?.inpsectionId && (
                      <li className="w-full">
                        <input
                          type="file"
                          ref={imgUpload}
                          accept="image/*"
                          className="hidden"
                          onChange={(e) => onUploadImage(e, data?.inpsectionId)}
                        />
                        <a
                          className="block py-2 px-4 text-black hover:bg-gray-100"
                          onClick={() => imgUpload.current.click()}
                        >
                          <FontAwesomeIcon icon={faUpload} className="mr-2" />
                          {t(`COMMON.UPLOAD_IMAGE`)}
                        </a>
                      </li>
                    )}
                  <li className="w-full">
                    <a
                      className="block py-2 px-4 text-black hover:bg-gray-100"
                      onClick={() => onDeleteClient(data?.client?.userId)}
                    >
                      <FontAwesomeIcon icon={faTrash} className="mr-2" />
                      {t(`COMMON.DELETE`)}
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col flex-1 p-4 w-full">
          <h2 className="text-gray-800 font-bold text-md mb-2 capitalize">
            {data?.client?.name?.firstName + " " + data?.client?.name?.lastName}
          </h2>
          {data?.client?.phoneNumber && (
            <p className="text-gray-400 text-sm">
              <FontAwesomeIcon icon={faPhone} className="mr-2" />
              {data?.client?.phoneNumber}
            </p>
          )}

          <p className="text-gray-400 text-sm">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
            {data?.client?.email}
          </p>
          <p className="text-gray-400 text-sm capitalize">
            <FontAwesomeIcon icon={faLocationPin} className="mr-2" />
            {(data?.client?.address?.addressLine || "") +
              ", " +
              (data?.client?.address?.landMark || "") +
              ", " +
              (data?.client?.address?.city || "") +
              " - " +
              (data?.client?.address?.zipCode || "")}
          </p>
          {hasPermissions.admin(CurrentUser?.role) && (
            <p className="text-gray-400 text-sm">
              <FontAwesomeIcon icon={faIdCard} className="mr-2" />
              {data?.employee?.name?.firstName +
                " " +
                data?.employee?.name?.lastName}
            </p>
          )}
          <div className="flex justify-center mt-auto">
            {data?.state === "COMPLETED" ? (
              <Button
                title={t(`CLIENTS_PAGE.COMMON.PREVIEW`)}
                onClick={() => {
                  navigation(`/inspection-preview/${data?.inpsectionId}`);
                }}
              />
            ) : (
              <Button
                title={t(`CLIENTS_PAGE.COMMON.EDIT_INSPECTION`)}
                onClick={() => {
                  localStorage.setItem("client", JSON.stringify(data?.client));
                  localStorage.setItem(
                    "inspectionId",
                    data?.inpsectionId || null
                  );
                  if (isMobile) {
                    navigation("/inspection");
                  } else {
                    navigation("/inspection/1");
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientCard;
