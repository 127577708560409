import {
  faSquare,
  faCircle,
  faCircleDot,
  faSquareCheck,
} from "@fortawesome/free-regular-svg-icons";
// import { faCheck, faCircle, faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Checkbox = ({
  label = "",
  value = false,
  onClick = () => {},
  hideBox = false,
  type = "checkbox",
}) => {
  let icon = type === "radio" ? faCircle : faSquare;
  let iconSelected = type === "radio" ? faCircleDot : faSquareCheck;
  return (
    <div className="flex items-center mb-1">
      {!hideBox && (
        <FontAwesomeIcon
          icon={value ? iconSelected : icon}
          className={`text-base ${value ? `text-[#3FC871]` : `text-[#787676]`}`}
          onClick={onClick}
        />
        // <>
        //   <div
        //     className={`h-4 w-4 border rounded flex items-center justify-center cursor-pointer ${
        //       value ? "border-[#3FC871]" : "border-[#787676]"
        //     }`}
        //     onClick={onClick}
        //   >
        //     {value ? (
        //       <FontAwesomeIcon
        //         icon={faCheck}
        //         className={"text-[#3FC871] text-sm"}
        //       />
        //     ) : (

        //     )}
        //   </div>
        // </>
      )}
      <span
        className={`inline-block color-black text-base font-medium ml-2${
          !hideBox ? " cursor-pointer" : ""
        }`}
        onClick={!hideBox ? onClick : () => {}}
      >
        {label}
      </span>
    </div>
  );
};

export default Checkbox;
