import React, { useState } from "react";

const HTabs = ({
  tabsData,
  defaultIndex = 0,
  onTabChange = () => {},
  onClickLoad = false,
  tabTittleSize = "text-lg",
  tabTitleEnd = false,
  tabContentCSSClasses = '',
}) => {
  const [activeTab, setActiveTab] = useState(defaultIndex);
  const [loadedContentKeys, setLoadedContentKeys] = useState([]);

  if (!(tabsData?.length > 0)) return "";

  return (
    <div className="tabs-group flex flex-col flex-1 w-full">
      <div className="mb-5 border-b border-gray-200">
        <ul
          className={`flex flex-wrap -mb-px font-semibold text-center ${
            tabTittleSize ? tabTittleSize : "text-base"
          }`}
        >
          {tabsData?.map((tabData, key) => {
            return (
              <li
                key={key}
                role="tab"
                className="inline-flex items-center justify-center mx-4 py-1 first:ml-0 last:mr-0 rounded-t-lg border-b-2 border-transparent outline-none cursor-pointer hover:text-gray-600 hover:border-gray-300 aria-selected:border-primary aria-selected:font-bold"
                aria-selected={key === activeTab || false}
                onClick={() => {
                  const previousTabKey = activeTab || 0;
                  const previousTabData = tabsData[previousTabKey];
                  onTabChange(key, tabData, previousTabKey, previousTabData);
                  setActiveTab(key);
                }}
              >
                {tabData?.icon && (
                  <span className="inline-flex items-center justify-center mr-2 w-5 h-5">
                    {tabData?.icon}
                  </span>
                )}
                {tabData?.tabTitle}
              </li>
            );
          })}
          {tabTitleEnd && (
            <li className="inline-flex items-center ml-auto">{tabTitleEnd}</li>
          )}
        </ul>
      </div>
      <div className={`tab-content flex flex-col${tabContentCSSClasses ? ` ${tabContentCSSClasses}` : ''}`}>
        {tabsData?.map((tabData, key) => {
          if (
            onClickLoad &&
            !loadedContentKeys.includes(key) &&
            key !== activeTab
          ) {
            return "";
          }
          if (onClickLoad && !loadedContentKeys.includes(key)) {
            setLoadedContentKeys([...loadedContentKeys, key]);
          }
          return (
            <div
              key={key}
              className={`${
                key === activeTab ? "flex flex-col flex-1" : "hidden"
              }`}
              role="tabpanel"
            >
              {tabData?.tabContent}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HTabs;