import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoginService from "services/LoginService";
import ForgetPasswordPresentational from "./forgot-password-presentational";
import notification from "utils/notification";

const ForgetPasswordFunctional = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);

  const sendEmail = (formData) => {
    setBtnLoading(true);
    let request = {
      email: formData?.email,
    };
    LoginService.forgetPassword(request)
      .then((res) => {
        if (res.status === 204) {
          notification.error(t(`TOASTR.API.INVALID_EMAIL`));
        } else if (res.status === 200) {
          notification.success(t(`COMMON.EMAIL_SEND_SUCCESSFULLY`));
          backToLogin();
        }
        setBtnLoading(false);
      })
      .catch((e) => {
        notification.error(t(e));
        setBtnLoading(false);
      });
  };

  const backToLogin = () => {
    navigate("/login");
  };

  return (
    <ForgetPasswordPresentational
      sendEmail={sendEmail}
      btnLoading={btnLoading}
      backToLogin={backToLogin}
    />
  );
};
export default ForgetPasswordFunctional;
