import { combineReducers } from "redux";
import account from "./account-reducer";
import authReducer from "./auth-reducer";
import loaderReducer from "./loader-reducer";
import templateKeysReducer from "./template-keys-reducer";
import imageUploadReducer from "./image-upload-reducer";

export default combineReducers({
  account,
  authReducer,
  loaderReducer,
  templateKeysReducer,
  imageUploadReducer,
});
