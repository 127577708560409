const defaultTheme = {
  rtl: 'rtl',
  ltr: 'ltr',
  placeholder: 'editor-placeholder',
  paragraph: 'mb-1',
  quote: 'editor-quote p-4 my-4 bg-gray-50 border-l-4 border-gray-300',
  heading: {
    h1: 'h1 text-5xl font-extrabold',
    h2: 'h2 text-4xl font-bold',
    h3: 'h3 text-3xl font-bold',
    h4: 'h4 text-2xl font-bold',
    h5: 'h5 text-xl font-bold',
    h6: 'h6 text-lg font-bold',
  },
  list: {
    nested: {
      listitem: 'editor-nested-listitem',
    },
    ol: 'list-decimal pl-4',
    ul: 'list-disc pl-4',
    listitem: 'editor-listitem marker:font-semibold',
  },
  image: 'editor-image',
  link: 'editor-link',
  text: {
    bold: 'editor-text-bold font-bold',
    italic: 'editor-text-italic italic',
    overflowed: 'editor-text-overflowed',
    hashtag: 'editor-text-hashtag',
    underline: 'editor-text-underline underline',
    strikethrough: 'editor-text-strikethrough line-through',
    underlineStrikethrough: 'editor-text-underlineStrikethrough underline line-through',
    code: 'editor-text-code',
  },
  code: 'editor-code',
  codeHighlight: {
    atrule: 'editor-tokenAttr',
    attr: 'editor-tokenAttr',
    boolean: 'editor-tokenProperty',
    builtin: 'editor-tokenSelector',
    cdata: 'editor-tokenComment',
    char: 'editor-tokenSelector',
    class: 'editor-tokenFunction',
    'class-name': 'editor-tokenFunction',
    comment: 'editor-tokenComment',
    constant: 'editor-tokenProperty',
    deleted: 'editor-tokenProperty',
    doctype: 'editor-tokenComment',
    entity: 'editor-tokenOperator',
    function: 'editor-tokenFunction',
    important: 'editor-tokenVariable',
    inserted: 'editor-tokenSelector',
    keyword: 'editor-tokenAttr',
    namespace: 'editor-tokenVariable',
    number: 'editor-tokenProperty',
    operator: 'editor-tokenOperator',
    prolog: 'editor-tokenComment',
    property: 'editor-tokenProperty',
    punctuation: 'editor-tokenPunctuation',
    regex: 'editor-tokenVariable',
    selector: 'editor-tokenSelector',
    string: 'editor-tokenSelector',
    symbol: 'editor-tokenProperty',
    tag: 'editor-tokenProperty',
    url: 'editor-tokenOperator',
    variable: 'editor-tokenVariable',
  },
};

export default defaultTheme