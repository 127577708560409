import roles from "./roles";

const hasPermissions = {
  superAdmin: (role) => [roles.SUPER_ADMIN].includes(role),
  admin: (role) => [roles.SUPER_ADMIN, roles.ADMIN].includes(role),
  employee: (role) =>
    [roles.SUPER_ADMIN, roles.ADMIN, roles.EMPLOYEE].includes(role),
  client: (role) =>
    [roles.SUPER_ADMIN, roles.ADMIN, roles.EMPLOYEE, roles.CLIENT].includes(
      role
    ),
};

export default hasPermissions;
