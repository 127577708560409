import React from "react";
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  NavLink,
} from "react-router-dom";
import { user } from "assets/icons/icons";
import { logoTransparent } from "assets/images/images";
import { Dropdown } from "antd";
import AuthService from "services/AuthService";
import Templates from "screens/Templates/templates-functional";
import MyProfile from "screens/MyProfile/myprofile-template";
import Dashbaord from "screens/Dashboard/dashboard-functional";
import Employee from "screens/UserManagement/Employee/employee-functional";
import Clients from "screens/UserManagement/Clients/clients-functional";
import Inspection from "screens/Inspections/inspection-functional";
import InspectionPreview from "screens/Inspections/inspection-preview";
import Notification from "screens/Notification/notification-functional";
import hasPermissions from "utils/permissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCaretDown,
  faFileAlt,
  faHome,
  faIdCard,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

const DesktopLayout = () => {
  const navigate = useNavigate();
  const navDefaultClasses =
    "p-2 flex items-center align-middle cursor-pointer ml-5 text-typo-primary font-semibold";
  const navActiveClasses =
    " bg-[#FFEFCC] rounded-tl-full rounded-bl-full font-bold";

  const CurrentUser = AuthService?.getCurrentUser();
  const Name =
    CurrentUser?.name && Object?.values(CurrentUser?.name)?.join(" ")?.trim();
  const ProfileImageURL = CurrentUser?.profileImage?.url || user;

  const navbarDropdownItems = [
    {
      label: "My Profile",
      key: "my-profile",
    },
    {
      label: "Logout",
      key: "logout",
    },
  ];

  const navbarDropdownItemClick = ({ key }) => {
    if (key === "logout") {
      AuthService.logout();
    } else {
      navigate(`/${key}`);
    }
  };

  return (
    <div className="min-h-[100vh] bg-slate-400 flex flex-row">
      <div className="min-w-[220px] min-h-[100vh] bg-white border-r-[1px]">
        <div className="min-h-[75px] bg-dark flex justify-center items-center px-3 py-2 mb-4">
          <img src={logoTransparent} alt={"Transparent Logo"} />
        </div>
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive
              ? `${navDefaultClasses} ${navActiveClasses}`
              : navDefaultClasses
          }
        >
          <div>
            <FontAwesomeIcon
              icon={faHome}
              className={
                "h-[16px] w-[16px] rounded-full p-1.5 text-white bg-primary align-middle"
              }
            />
          </div>
          <span className="ml-[10px] c-black">Dashboard</span>
        </NavLink>
        <NavLink
          to="/clients"
          className={({ isActive }) =>
            isActive
              ? `${navDefaultClasses} ${navActiveClasses}`
              : navDefaultClasses
          }
        >
          <div>
            <FontAwesomeIcon
              icon={faUsers}
              className={
                "h-[16px] w-[16px] rounded-full p-1.5 text-white bg-primary align-middle"
              }
            />
          </div>
          <span className="ml-[10px] c-black">Clients</span>
        </NavLink>
        {hasPermissions.admin(CurrentUser?.role) && (
          <>
            <NavLink
              to="/employee"
              className={({ isActive }) =>
                isActive
                  ? `${navDefaultClasses} ${navActiveClasses}`
                  : navDefaultClasses
              }
            >
              <div>
                <FontAwesomeIcon
                  icon={faIdCard}
                  className={
                    "h-[16px] w-[16px] rounded-full p-1.5 text-white bg-primary align-middle"
                  }
                />
              </div>
              <span className="ml-[10px] c-black">Employee</span>
            </NavLink>
            <NavLink
              to="/templates"
              className={({ isActive }) =>
                isActive
                  ? `${navDefaultClasses} ${navActiveClasses}`
                  : navDefaultClasses
              }
            >
              <div>
                <FontAwesomeIcon
                  icon={faFileAlt}
                  className={
                    "h-[16px] w-[16px] rounded-full p-1.5 text-white bg-primary align-middle"
                  }
                />
              </div>
              <span className="ml-[10px] c-black">Templates</span>
            </NavLink>
          </>
        )}
      </div>
      <div className="flex flex-col flex-1">
        <div className="min-h-[80px] max-h-[80px] bg-white flex flex-1 p-4 items-center justify-end">
          {/* <div className="relative">
            <FontAwesomeIcon
              icon={faBell}
              color="#828181"
              style={{ height: 24, width: 24 }}
              className="mx-6 "
              onClick={() => navigate("/notification")}
            />
            <div className="inline-flex absolute top-0 left-11 justify-center items-center w-[17px] h-[17px] text-[9px] font-bold text-white bg-red-500 rounded-full border-2 border-white">
              {CurrentUser?.notifyCount || 0}
            </div>
          </div> */}
          <Dropdown
            menu={{
              items: navbarDropdownItems,
              onClick: navbarDropdownItemClick,
            }}
            trigger={["click"]}
          >
            <div className="flex items-center cursor-pointer">
              <img
                src={ProfileImageURL}
                alt={"User Avatar"}
                className="h-[30px] w-[30px] rounded-full aspect-square object-cover ml-2"
              />
              <span className="text-[#252525] ml-1.5 text=[18px] capitalize">
                {Name}
              </span>
              <FontAwesomeIcon
                icon={faCaretDown}
                color="#787676"
                className="ml-2"
              />
            </div>
          </Dropdown>
        </div>
        <div className="flex flex-col h-[calc(100vh-80px)] bg-[#F7F7F9] overflow-hidden">
          <div className="flex flex-col flex-1 overflow-hidden overflow-y-auto">
            <div className="p-8 bg-white m-10 border-[1pt] border-[#D1D1D1] rounded-[7px] flex flex-1">
              <Routes>
                <Route path="/" element={<Dashbaord />} />
                <Route path="/my-profile" element={<MyProfile />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/notification" element={<Notification />} />
                {hasPermissions.admin(CurrentUser?.role) && (
                  <>
                    <Route path="/employee" element={<Employee />} />
                    <Route path="/templates" element={<Templates />} />
                  </>
                )}
                <Route path="/inspection" element={<Inspection />}>
                  <Route path="/inspection/:menu" element={<Inspection />} />
                  <Route
                    path="/inspection/:menu/:submenu"
                    element={<Inspection />}
                  />
                </Route>
                <Route
                  path="/inspection-preview"
                  element={<InspectionPreview />}
                >
                  <Route
                    path="/inspection-preview/:id"
                    element={<InspectionPreview />}
                  />
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopLayout;
