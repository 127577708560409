import React from "react";
import { Outlet } from "react-router-dom";
import { logoTransparent } from "assets/images/images";

const BlankLayout = () => {
  return (
    <div className="w-full">
      <div className="app-logo-div bg-dark h-full  px-3 py-2 mx-auto mb-6">
        <img src={logoTransparent} alt={"App Logo"} />
      </div>
      <Outlet />
    </div>
  );
};

export default BlankLayout;
