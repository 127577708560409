import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthService from "services/AuthService";
import UserService from "services/UserService";
import ClientsPresentational from "./clients-presentational";
import ResizeFile from "utils/image-compressor";
import notification from "utils/notification";
import setLoader from "utils/loader";
import PaymentService from "services/PaymentService";

const ClientsFunctional = ({ isMobile = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const CurrentUser = AuthService?.getCurrentUser();
  const [btnLoading, setBtnLoading] = useState(false);
  const [clientDataSource, setClientDataSource] = useState([]);
  const [empDataSource, setEmpDataSource] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Start Create Or Update Client
  const submitClient = (value) => {
    setBtnLoading(true);
    let request = {
      name: {
        firstName: value?.firstName || null,
        lastName: value?.lastName || null,
      },
      email: value?.email || null,
      phoneNumber: value?.phoneNumber || null,
      address: {
        addressLine: value?.addressLine || null,
        landMark: value?.landMark || null,
        city: value?.city || null,
        country: value?.country || "New Zealand",
        zipCode: value?.zipCode || null,
      },
      role: value?.role || "CLIENT",
      propertyType: value?.propertyType || "HOUSE",
      employeeId: value?.employeeId || CurrentUser?.userId,
    };

    let userId = value?.id;
    if (userId === null) {
      UserService.createUser(request)
        .then((res) => {
          if (res.status === 204) {
            notification.error(t(`TOASTR.API.FAILED_TO_CREATE`));
          } else if (res.status === 400) {
            notification.error(t(`TOASTR.API.FAILED_TO_CREATE`));
          } else if (res.status === 201) {
            notification.success(t(`COMMON.CREATE_SUCCESS`));
            getClientList();
            setIsModalOpen(false);
            if (isMobile) {
              navigate("/");
            }
          }
          setBtnLoading(false);
        })
        .catch((e) => {
          notification.error(t(e));
          setBtnLoading(false);
        });
    } else {
      UserService.updateUser(userId, request)
        .then((res) => {
          if (res.status === 204) {
            notification.error(t(`TOASTR.API.FAILED_TO_UPDATE`));
          } else if (res.status === 200) {
            notification.success(t(`COMMON.UPDATE_SUCCESS`));
            getClientList();
            setIsModalOpen(false);
          }
          setBtnLoading(false);
        })
        .catch((e) => {
          notification.error(t(e));
          setBtnLoading(false);
        });
    }
  };

  // Start Delete Client
  const deleteClient = (id) => {
    UserService.deleteUser(id)
      .then((res) => {
        if (parseInt(res.status, 0) === 204) {
          notification.error(t(`TOASTR.API.FAILED_TO_DELETE`));
        } else {
          notification.success(t(`COMMON.DELETE_SUCCESS`));
          getClientList();
        }
      })
      .catch((e) => {
        notification.error(t(e));
      });
  };

  const paymentChange = (inspectionId) => {
    let body = {
      inpsectionId: inspectionId,
      state: "PAID",
    };
    setLoader(true);
    PaymentService.paymentChange(body)
      .then(() => {
        setLoader(false);
        getClientList();
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  // Get Client List
  const [filterForm, setFilterForm] = useState({
    size: 8,
    page: 0,
    total: 0,
    search: "",
    isFilter: true,
    inspectionState: "ASSIGNED",
    sort: "createdAt,desc",
  });
  useEffect(() => {
    if (filterForm && filterForm.isFilter) {
      getClientList();
    }
  }, [filterForm]);

  const getClientList = (key = "") => {
    setLoader(true);
    let params = {
      ...filterForm,
    };
    if (filterForm.inspectionState === "COMPLETED") {
      params.sort = "modifiedAt,desc";
    }
    UserService.getUserClientList(params)
      .then((res) => {
        setLoader(false);
        if (res?.data?.content) {
          setClientDataSource(res.data.content);
          setFilterForm({
            ...filterForm,
            page: res.data.page.number,
            total: res.data.page.totalElements,
            isFilter: false,
          });
        } else {
          setClientDataSource([]);
        }
      })
      .catch(() => {
        setLoader(false);
        setFilterForm({
          ...filterForm,
          isFilter: false,
        });
      });
  };

  // Get Employee List
  const [empFilterForm, setEmpFilterForm] = useState({
    size: 50,
    page: 0,
    total: 0,
    search: "",
    isFilter: true,
    role: "EMPLOYEE",
    sort: "firstName,asc",
  });

  useEffect(() => {
    if (empFilterForm && empFilterForm.isFilter) {
      getEmployeeList();
    }
  }, [empFilterForm]);

  const getEmployeeList = () => {
    setLoader(true);
    let params = {
      ...empFilterForm,
    };
    UserService.getUserList(params)
      .then((res) => {
        setLoader(false);
        if (res?.data?.content) {
          setEmpDataSource(res.data.content);
          setEmpFilterForm({
            ...empFilterForm,
            page: res.data.page.number,
            total: res.data.page.totalElements,
            isFilter: false,
          });
        } else {
          setEmpDataSource([]);
        }
      })
      .catch(() => {
        setLoader(false);
        setEmpFilterForm({
          ...empFilterForm,
          isFilter: false,
        });
      });
  };

  // Upload Inspection Image
  async function onUploadInspectionImage(file, inspectionId) {
    setLoader(true);
    let imgFile = file.target.files[0];
    const image = await ResizeFile(imgFile, "file");
    let formData = new FormData();
    let jsonData = '{"attachmentType":"INSPECTION_PICTURE"}';
    formData.append("file", image);
    formData.append("jsonData", jsonData);
    UserService.uploadHouseImage(inspectionId, formData)
      .then((res) => {
        if (res.status === 204) {
          notification.error(t(`COMMON.NO_DATA_FOUND`));
        } else if (res.status === 200) {
          notification.success(t(`COMMON.UPLOAD_IMAGE_SUCCESS`));
          getClientList();
        }
        setLoader(false);
      })
      .catch((e) => {
        console.log(e);
        setLoader(false);
      });
  }

  return (
    <ClientsPresentational
      clientDataSource={clientDataSource}
      empDataSource={empDataSource}
      submitClient={submitClient}
      filterForm={filterForm}
      setFilterForm={setFilterForm}
      btnLoading={btnLoading}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      deleteClient={deleteClient}
      isMobile={isMobile}
      onUploadInspectionImage={onUploadInspectionImage}
      paymentChange={paymentChange}
    />
  );
};
export default ClientsFunctional;
