import axios from "axios";
import AuthService from "services/AuthService";

const ErrorInterceptor = async () => {
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.validateStatus = () => true;
  axios.interceptors.response.use(
    (res) => {
      if (res?.config?.headers?.skipErrors) {
        res["orgStatus"] = res?.status;
        res["status"] = 200;
        return res;
      }
      if (res?.status && parseInt(res?.status, 0) > 205) {
        const resMessage = res?.data?.constraintViolations?.[0]?.message
          ? `TOASTR.API.${res?.data?.constraintViolations?.[0]?.message}`
          : `TOASTR.COMMON.SOMETHING_WENT_WRONG`;
        switch (res?.status) {
          case 400:
            let msg = res?.data?.constraintViolations?.[0]?.message;
            if (msg) {
              return Promise.reject(`TOASTR.API.${msg}`);
            } else {
              return Promise.reject(`TOASTR.API.BAD_REQUEST_SORT_VALUES`);
            }
          case 401:
            if (
              AuthService?.getToken() &&
              !AuthService?.verifyActiveSession()
            ) {
              const config = res?.config;
              return AuthService?.updateToken()?.then(() => {
                config.headers = JSON.parse(
                  JSON.stringify(config.headers || {})
                );
                return axios(config);
              });
            }
            return Promise.reject(`TOASTR.API.UNAUTHORIZED_USER`);
          case 403:
            return Promise.reject(`TOASTR.API.ACCESS_DENIED`);
          case 422:
            AuthService?.clearSecureLocalStorage();
            return Promise.reject(`TOASTR.API.UNAUTHORIZED_USER`);
          case 500:
            return Promise.reject(`TOASTR.API.SERVER_ERROR`);
          default:
            return Promise.reject(resMessage);
        }
      } else {
        return res;
      }
    },
    (error) => Promise.reject(error)
  );
  return {};
};

export default ErrorInterceptor;
