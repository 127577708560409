import axios from "axios";
import { dashboardUrl } from "urls/urls";

const getDashboardData = (params = {}) => {
  return axios.get(dashboardUrl.DATE_TYPE + "/" + params + "/dashboard");
};

const DashboardService = {
  getDashboardData,
};

export default DashboardService;
