import React from "react";

const NotificationCard = ({ title, dateTime }) => {
  return (
    <>
      <div className="w-full justify-center mb-4">
        <div className="w-full block p-2 rounded-lg shadow-md bg-gray-100 border border-gray-200  hover:bg-white">
          <p className="p-2 font-normal text-gray-600 mb-0">{title}</p>
          <small className="w-full block text-right text-gray-600 ">
            {dateTime}
          </small>
        </div>
      </div>
    </>
  );
};

export default NotificationCard;
