import axios from "axios";
import AuthService from "services/AuthService";

const TokenInterceptor = async () => {
  // Default settings for axios request
  axios.interceptors.request.use(
    (config) => {
      if (AuthService.getToken() && !config?.headers?.skipAuth) {
        config.headers.Authorization = `Bearer ${AuthService.getToken()}`;
      }
      if (localStorage.getItem("lng")) {
        config.headers["Accept-Language"] =
          `${localStorage.getItem("lng") === "it" ? "IT" : "EN"}` || "EN";
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};
export default TokenInterceptor;

