import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";
import Button from "components/Button";

const EmployeeCard = ({ data, onEditEmployee, onDeleteEmployee }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col w-full rounded overflow-hidden shadow-lg border-2 py-2 mb-4">
        <div className="w-full flex justify-center p-2">
          {(data?.profileImage?.url && (
            <img
              className="bg-gray-400 w-[100px] rounded-full aspect-square object-cover"
              src={data?.profileImage?.url}
              alt={
                data?.client?.name?.firstName +
                " " +
                data?.client?.name?.lastName
              }
            />
          )) || (
            <FontAwesomeIcon
              icon={faUser}
              className="w-16 h-16 rounded-full p-4 border-solid border-2 border-gray-300 text-gray-300"
            />
          )}
        </div>
        <div className="flex flex-col flex-1 px-6 pt-0 pb-2 w-full">
          <h2 className="text-gray-800 font-bold text-md mb-2 text-center pb-2 capitalize">
            {data.name?.firstName + " " + data.name?.lastName}
          </h2>
          {data?.phoneNumber && (
            <p className="text-gray-400 text-sm">
              <FontAwesomeIcon icon={faPhone} className="mr-2" />
              {data?.phoneNumber}
            </p>
          )}

          {data?.email && (
            <p className="text-gray-400 text-sm break-all">
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              {data?.email}
            </p>
          )}
          <div className="flex justify-center mt-auto">
            <div className="mr-2">
              <Button
                title={t(`COMMON.EDIT`)}
                onClick={() => onEditEmployee(data)}
              />
            </div>
            <div>
              <Button
                title={t(`COMMON.DELETE`)}
                type=""
                onClick={() => onDeleteEmployee(data.userId)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeCard;
