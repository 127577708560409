import React from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Space, Table, Tag } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";

const DashboardPresentational = ({
  dashboardData,
  filterForm,
  setFilterForm,
  clientDataSource,
  clientFilterForm,
  setClinetFilterForm,
  btnLoading,
}) => {
  const { t } = useTranslation();
  const filterDashboard = [
    {
      label: t(`DASHBOARD_PAGE.FILTER.TODAY`),
      key: "TODAY",
    },
    {
      label: t(`DASHBOARD_PAGE.FILTER.THIS_MONTH`),
      key: "MONTHLY",
    },
    {
      label: t(`DASHBOARD_PAGE.FILTER.LAST_3_MONTH`),
      key: "QUARTERLY",
    },
    {
      label: t(`DASHBOARD_PAGE.FILTER.LAST_6_MONTH`),
      key: "HALF_YEARLY",
    },
    {
      label: t(`DASHBOARD_PAGE.FILTER.THIS_YEAR`),
      key: "YEARLY",
    },
  ];

  const filterDashboardClick = ({ key }) => {
    setFilterForm({
      dateType: key,
      isFilter: true,
    });
  };

  const getDateTypeLable = (dayType) => {
    let filterDashboardKey = filterDashboard.findIndex((a) => a.key == dayType);
    return filterDashboard[filterDashboardKey].label;
  };

  const columns = [
    {
      title: t(`COMMON.ID`),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t(`COMMON.NAME`),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t(`COMMON.PHONE`),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: t(`COMMON.EMAIL`),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t(`COMMON.ADDRESS`),
      dataIndex: "address",
      key: "address",
    },
  ];

  const dataArray = [];
  clientDataSource.map((data) => {
    if (data?.client?.clientNumber) {
      dataArray.push({
        id: "#" + data?.client?.clientNumber,
        name:
          data?.client?.name?.firstName + " " + data?.client?.name?.lastName,
        phone: data?.client?.phoneNumber,
        email: data?.client?.email,
        address:
          (data?.client?.address?.addressLine || "") +
          " " +
          (data?.client?.address?.city || ""),
      });
    }
  });

  const dashboardCard = (count, title) => {
    return (
      <div className="max-w-sm rounded overflow-hidden border-[1pt] border-[#D1D1D1] shadow-lg mb-4">
        <div className="p-4">
          <div className="font-semibold md:text-[80px] text-[40px] text-[#40C872] leading-none">
            {count}
            <FontAwesomeIcon
              icon={count > 0 ? faArrowUp : faArrowDown}
              className="md:text-[24px] text-[12px] text-[#40C872] ml-2"
            />
          </div>
          <div className="font-semibold md:text-[20px] text-[10px] text-gray-500">
            {title}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full justify-center">
      <div className="mb-6">
        <div className="mb-2 flex align-right text-right justify-end">
          <Dropdown
            menu={{
              items: filterDashboard,
              onClick: filterDashboardClick,
            }}
            trigger={["click"]}
          >
            <div className="flex items-center cursor-pointer">
              <span className="text-[#252525] ml-1.5 text=[18px]">
                {getDateTypeLable(filterForm?.dateType)}
              </span>
              <FontAwesomeIcon
                icon={faCaretDown}
                color="#787676"
                className="ml-2"
              />
            </div>
          </Dropdown>
        </div>
        <div className="md:grid md:grid-cols-3 md:gap-5">
          {dashboardCard(
            dashboardData?.newInspectionCount || 0,
            t(`DASHBOARD_PAGE.COMMON.NEW_INSPECTION`)
          )}

          {dashboardCard(
            dashboardData?.inprogressInspectionCount || 0,
            t(`DASHBOARD_PAGE.COMMON.INPROGRESS`)
          )}

          {dashboardCard(
            dashboardData?.completedInspectionCount || 0,
            t(`DASHBOARD_PAGE.COMMON.COMPLETED`)
          )}
        </div>
      </div>
      <div className="mb-6">
        <div className="text-sm font-bold text-gray-500 mb-2">
          {t(`DASHBOARD_PAGE.COMMON.NEW_CLIENTS`)}
        </div>
        <div className="w-full p-2 rounded overflow-hidden border-[1pt] border-[#D1D1D1] shadow-lg mb-4">
          <Table
            columns={columns}
            dataSource={dataArray}
            // scroll={{ x: true }}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardPresentational;
