import "antd/dist/antd.min.css";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import store from "store/createStore";
import ErrorInterceptor from "interceptors/error-interceptor";
import TokenInterceptor from "interceptors/token-interceptor";
import Router from "routes/router";
import 'i18n/i18n';
import PageLoader from "components/PageLoader";

function App() {
  ErrorInterceptor();
  TokenInterceptor();

  return (
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <div className="App">
          <Router />
        </div>
        <PageLoader />
      </Suspense>
    </Provider>
  );
}

export default App;
