import React from "react";
import HTabs from "components/tabs/h-tabs";
import Myprofile from "screens/MyProfile/myprofile-functional";
import ChangePassword from "screens/ChangePassword/change-password-functional";

const MyprofileTemplate = () => {
  const tabs = [
    {
      tabTitle: "My Profile",
      tabContent: <Myprofile />,
    },
    {
      tabTitle: "Change Password",
      tabContent: <ChangePassword />,
    },
  ];
  return <HTabs tabsData={tabs} />;
};

export default MyprofileTemplate;
