import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import notification from "utils/notification";
import setLoader from "utils/loader";
import ReportPresentational from "./report-presentational";
import InspectionService from "services/InspectionService";

const ReportFunctional = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [clientData, setClientData] = useState([]);
  const [inspectionReportData, setInspectionReportData] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code") || null;
    if (!code) {
      navigate(`/login`);
    }

    fetchClientData(code);
  }, []);

  // fetch inspection data if inspection id available
  const fetchClientData = (code) => {
    setLoader(true);
    InspectionService.getClientData(code).then((res) => {
      if (res?.data?.client) {
        setClientData(res?.data);
        fetchInspectionPdfData(code);
      } else {
        setClientData([]);
      }
    });
  };

  // fetch inspection preview data if inspection id available
  const fetchInspectionPdfData = async (code) => {
    const params = {
      code: code,
      contentDisposition: "INLINE",
    };
    await InspectionService.getEmailInspectionReport(params)
      .then(async (res) => {
        if (res?.data) {
          const blob = res?.data;
          if (blob) {
            const urlObject = URL.createObjectURL(blob);
            setInspectionReportData(urlObject);
          }
        }
      })
      .catch((e) => {
        notification.error(t(`${e}`));
      });
    setLoader(false);
  };

  return (
    <ReportPresentational
      clientData={clientData}
      inspectionReportData={inspectionReportData}
    />
  );
};
export default ReportFunctional;
