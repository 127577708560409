import React from "react";
import { Empty } from "antd";

const NoDataFound = () => {
  return (
    <div className="m-auto">
      <div className="tab-content flex flex-col flex-1">
        <div className="flex flex-col flex-1" role="tabpanel">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      </div>
    </div>
  );
};

export default NoDataFound;
