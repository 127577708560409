import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faEye,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

const InspectionImage = ({
  data = {},
  onDelete = () => {},
  onEdit = () => {},
  onPreview = () => {},
}) => {
  const { confirm } = Modal;
  const { t } = useTranslation();

  const onDeleteClick = () => {
    confirm({
      title: "Are you sure delete this image?",
      icon: (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size={"xl"}
          className="float-left mr-4 text-red-600"
        />
      ),
      okText: t(`COMMON.YES`),
      okButtonProps: {
        className:
          "font-medium text-gray-600 hover:text-primary border-gray-300 hover:border-primary rounded",
      },
      okType: "danger",
      cancelText: t(`COMMON.CANCEL`),
      cancelButtonProps: {
        className:
          "font-medium rounded text-white bg-primary border border-primary hover:border-primary",
      },
      className: "rounded",
      onOk() {
        onDelete();
      },
      onCancel() {},
    });
  };

  return (
    <div className="h-[80px] w-[80px] md:h-[116px] md:w-[116px] rounded-md bg-[#FEEFCC] border-[#FCAE01] border-[1px] flex justify-center items-center mr-4 mb-4 relative">
      <div className="absolute top-2 right-2 flex">
        <div
          className="h-[15px] w-[15px] md:h-[20px] md:w-[20px] bg-white rounded-full flex justify-center items-center cursor-pointer ml-2"
          onClick={() => onPreview()}
        >
          <FontAwesomeIcon
            icon={faEye}
            className={"text-[#FCAE01] text-[9px]"}
          />
        </div>
        <div
          className="h-[15px] w-[15px] md:h-[20px] md:w-[20px] bg-white rounded-full flex justify-center items-center cursor-pointer ml-2"
          onClick={() => onEdit()}
        >
          <FontAwesomeIcon
            icon={faPen}
            className={"text-[#FCAE01] text-[9px]"}
          />
        </div>
        <div
          className="h-[15px] w-[15px] md:h-[20px] md:w-[20px] bg-white rounded-full flex justify-center items-center cursor-pointer ml-2"
          onClick={() => onDeleteClick()}
        >
          <FontAwesomeIcon
            icon={faTrash}
            className={"text-[#FCAE01] text-[9px]"}
          />
        </div>
      </div>
      <img
        alt="property"
        src={data?.imageUrl}
        className="h-[80px] w-[80px] md:h-[120px] md:w-[120px] rounded-md object-cover"
      />
    </div>
  );
};

export default InspectionImage;
