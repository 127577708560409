import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CONTROLLED_TEXT_INSERTION_COMMAND } from "lexical";
import DocTemplateService from "services/DocTemplateService";
import notification from "utils/notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";

const TemplateKeywords = ({
  editor,
  toolbarRef,
  Divider,
}) => {
  const dropDownRef = useRef(null);
  const dispatch = useDispatch()
  const [templateKeywords, setTemplateKeywords] = useState(useSelector(state=> state?.templateKeysReducer) || []);
  const [showTemplateKeywordsDropDown, setShowTemplateKeywordsDropDown] = useState(false);

  useEffect(() => {
    const dropDown = dropDownRef.current;
    const toolbar = toolbarRef.current;

    if (toolbar !== null) {
      const handle = (event) => {
        const target = event.target;

        if (!dropDown?.contains(target) && !toolbar?.contains(target)) {
          setShowTemplateKeywordsDropDown(false);
        }
      };
      document.addEventListener("click", handle);

      return () => {
        document.removeEventListener("click", handle);
      };
    }
  }, [dropDownRef, setShowTemplateKeywordsDropDown, toolbarRef]);

  useEffect(() => {
    if(!(templateKeywords?.length > 0)) {
      DocTemplateService.getTemplateKeywords()
        .then((res) => {
          if (res?.data?.content) {
            dispatch({ type: 'SET_TEMPLATE_KEYS', payload: res?.data?.content })
            setTemplateKeywords(res?.data?.content)
          }
        })
        .catch((e) => {
          notification.error(e)
        })
    }
  }, [dispatch, templateKeywords, setTemplateKeywords]);

  if(!(templateKeywords?.length > 0)) { return "" }

  return (
    <>
      <Divider />
      <div className="relative">
        <button
          className="toolbar-item block-controls"
          onClick={() =>
            setShowTemplateKeywordsDropDown(!showTemplateKeywordsDropDown)
          }
          aria-label="Template Placeholder Options"
        >
          <span className="text mr-2">Template Keys</span>
          <FontAwesomeIcon icon={showTemplateKeywordsDropDown ? faChevronUp : faChevronDown} />
        </button>
        {showTemplateKeywordsDropDown && (
          <div ref={dropDownRef} className="dropdown absolute top-full left-0 z-10 max-w-xs w-52 mt-4 py-2 bg-white border border-gray-100 rounded-md shadow-lg">
            {templateKeywords?.map((placeholder, index) => (
              <button key={index} className="item flex w-full py-1 px-4 hover:bg-gray-100" onClick={() => editor.dispatchCommand(CONTROLLED_TEXT_INSERTION_COMMAND, `${placeholder?.value} `)}>
                <span className="text">{placeholder?.key}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
 
export default TemplateKeywords;