import React from 'react';
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const PageLoader = () => {
  const loader = useSelector((state) => state.loaderReducer)
  if(!loader) return
  return (
    <div className="bg-white fixed inset-0 opacity-70 flex items-center justify-center z-50 cursor-progress">
      <div className='flex items-center text-2xl text-type-dark font-semibold'>
        <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-3" />
        Loading...
      </div>
    </div>
  );
}
 
export default PageLoader;