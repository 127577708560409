import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PaymentService from "services/PaymentService";
import setLoader from "utils/loader";
import PaymentPresentational from "./payment-presentational";
import notification from "utils/notification";

const PaymentFunctional = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [clientCode, setClientCode] = useState([]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const client = queryParams.get("client") || null;
    const code = queryParams.get("code") || null;
    const ec = queryParams.get("ec") || null;
    const ti = queryParams.get("ti") || null;
    setClientCode({
      client: client,
      code: code,
      ec: ec,
      ti: ti,
    });
    if (!client || !code) {
      navigate(`/login`);
    }
    fetchClientData(client, code, ec, ti);
  }, []);

  // fetch inspection data if inspection id available
  const fetchClientData = (client, code, ec, ti) => {
    setBtnLoading(true);
    setLoader(true);
    let params = {
      clientNumber: client,
      verificationCode: code,
    };
    PaymentService.getPaymentData(params).then((res) => {
      if (res?.data?.client) {
        setClientData(res?.data);
        let paymentState = res?.data?.paymentState;
        let inspectionId = res?.data?.inspectionId;
        let amount = res?.data?.netTotal;
        let transactionId = ti;
        if (ec == 0 && paymentState == "PENDING" && inspectionId) {
          paymentStateUpdate(inspectionId, transactionId, amount, client, code);
        }
      } else {
        setClientData([]);
      }
      setBtnLoading(false);
      setLoader(false);
    });
  };

  // Create Payment Token
  const paymentCreateToken = () => {
    setBtnLoading(true);
    setLoader(true);

    let request = {
      inspection_id: clientData?.inspectionId,
      amount: clientData?.netTotal,
      client: clientCode?.client,
      code: clientCode?.code,
    };

    PaymentService.createPaymentToken(request)
      .then((paymentResponse) => {
        if (
          parseInt(paymentResponse.status, 0) === 204 ||
          parseInt(paymentResponse.status, 0) === 400
        ) {
          notification.error(t(`COMMON.NO_DATA_FOUND`));
        } else if (paymentResponse?.data?.data?.DigitalOrder) {
          window.open(paymentResponse?.data?.data?.DigitalOrder, "_self");
        } else {
          notification.error(t(`TOASTR.API.${paymentResponse?.data?.alert}`));
        }
        setBtnLoading(false);
        setLoader(false);
      })
      .catch((e) => {
        notification.error(t(e));
        setBtnLoading(false);
        setLoader(false);
      });
  };

  // Update Payment State
  const paymentStateUpdate = (
    inspectionId,
    transactionId,
    amount,
    client,
    code
  ) => {
    setBtnLoading(true);
    setLoader(true);
    if (inspectionId) {
      let request = {
        amount: amount,
        inspectionId: inspectionId,
        paymentState: "PAID",
        transactionId: transactionId,
        verificationCode: code,
      };
      PaymentService.paymentStateUpdateService(request)
        .then((res) => {
          if (
            parseInt(res.status, 0) === 204 ||
            parseInt(res.status, 0) === 400
          ) {
            notification.error(t(`COMMON.NO_DATA_FOUND`));
            setBtnLoading(false);
            setLoader(false);
          } else {
            notification.success(t(`COMMON.REPORT_SEND_EMAIL`));
            fetchClientData(client, code);
          }
        })
        .catch((e) => {
          notification.error(t(e));
          setBtnLoading(false);
          setLoader(false);
        });
    }
  };

  return (
    <PaymentPresentational
      clientData={clientData}
      paymentCreateToken={paymentCreateToken}
      btnLoading={btnLoading}
      clientCode={clientCode}
    />
  );
};
export default PaymentFunctional;
