import React from "react";
import { Popover } from "antd";
// import SVG from "assets/image/svg";

const Label = ({
  className = "block w-full",
  title = "",
  children = "",
  isTooltipIcon = null,
  isTooltipContent = null,
  onClick,
  style,
}) => {
  return (
    <div style={style}>
      <label className={className} onClick={onClick}>
        {title ? title : children}
      </label>
      {isTooltipContent && (
        <Popover content={isTooltipContent}>
          <span className="isTooltipContent">
            {/* <img
              height="14"
              width="14"
              src={isTooltipIcon ? isTooltipIcon : SVG.vt_question_circle}
            /> */}
          </span>
        </Popover>
      )}
    </div>
  );
};
export default Label;
