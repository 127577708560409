import axios from "axios";
import { templateUrl } from "urls/urls";

const getTemplateKeywords = (params = {}) => {
  return axios.get(templateUrl?.TEMPLATE_KEY, { params });
};

const getDocTemplateContent = (params = {}) => {
  return axios.get(templateUrl?.TEMPLATE_CONTENT, { params });
};

const createDocTemplateContent = (body) => {
  return axios.post(templateUrl?.TEMPLATE_CONTENT, body);
};

const updateDocTemplateContent = (id, body) => {
  return axios.put(templateUrl?.TEMPLATE_CONTENT + `/${id}`, body);
};

const deleteDocTemplateContent = (id) => {
  return axios.delete(templateUrl.TEMPLATE_CONTENT + `/${id}`);
};

const DocTemplateService = {
  getTemplateKeywords,
  getDocTemplateContent,
  createDocTemplateContent,
  updateDocTemplateContent,
  deleteDocTemplateContent,
};

export default DocTemplateService;
