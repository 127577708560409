import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LoginService from "services/LoginService";
import ChangePasswordPresentational from "./change-password-presentational";
import notification from "utils/notification";

const ChangePasswordFunctional = () => {
  const { t } = useTranslation();

  const [btnLoading, setBtnLoading] = useState(false);
  const [resetForm, setResetForm] = useState(false);

  const changePassword = (value) => {
    setBtnLoading(true);
    let request = {
      newPassword: btoa(value.newPassword) || null,
      oldPassword: btoa(value.oldPassword) || null,
    };
    LoginService.resetPassword(request)
      .then((res) => {
        if (res.status === 204) {
          notification.error(t(`TOASTR.API.OLD_PASSWORD_INVALID`));
        } else if (res.status === 200) {
          setResetForm(true);
          notification.success(t(`TOASTR.API.PASSWORD_RESET_SUCCESS`));
        }
        setBtnLoading(false);
      })
      .catch((e) => {
        notification.error(e);
        setBtnLoading(false);
      });
  };

  return (
    <ChangePasswordPresentational
      changePassword={changePassword}
      btnLoading={btnLoading}
      resetForm={resetForm}
      setResetForm={setResetForm}
    />
  );
};
export default ChangePasswordFunctional;
