import React from 'react';
import { useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import validator from 'utils/validator';
import Button from 'components/Button';
import TextField from 'components/form-fields/text-fields';
import FormErrMsg from 'components/FormErrMsg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const ForgetPasswordPresentational = ({
  sendEmail,
  btnLoading,
  backToLogin,
}) => {
  const { t } = useTranslation();
  const [ searchParams ] = useSearchParams();
  const {
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      email: searchParams?.get('email') || '',
    },
    mode: "all",
  });

  const onFormSubmit = () => {
    trigger().then((res) => {
      if (res) {
        const formData = getValues();
        sendEmail(formData);
      }
    });
  };

  const validatormsg = {
    email: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
      pattern: { value: validator.EMAIL, message: "VALIDATOR.PATTERN.EMAIL" }
    },
  };

  return (
    <div className="form-fields">
      <h1 className="text-3xl font-semibold text-center uppercase mb-4">{t(`FORGOT_PAGE.COMMON.FORGOT_PASSWORD`)}</h1>
      <p className="text-xs font-semibold text-red-600 text-center mb-5">{t(`FORGOT_PAGE.COMMON.INSTRUCTIONS`)}</p>
      <div className="mb-4">
        <Controller
          control={control}
          name="email"
          rules={validatormsg?.email}
          render={({ field }) => (
            <TextField
              tabIndex="1"
              {...field}
              prefix={<FontAwesomeIcon icon={faUser} className="mr-1 text-primary" />}
              name="email"
              placeholder={t(`COMMON.EMAIL`)}
              type="email"
              value={getValues("email")}
              onInput={(e) => {
                setValue("email", e.target.value);
                trigger("email");
              }}
              disabled={btnLoading}
            />
          )}
        />
        <FormErrMsg errors={errors?.email} />
      </div>
      <div className="forget-password-button-div flex justify-center mb-4 text-center">
        <Button
          title={t(`FORGOT_PAGE.COMMON.SEND_EMAIL`)}
          isLoading={btnLoading}
          onClick={() => onFormSubmit()}
        />
      </div>
      <div className="forget-pasword-div flex justify-center text-center">
        <button
          onClick={() => backToLogin()}
          className="text-typo-dark text-sm font-semibold"
          disabled={btnLoading}
        >
          {t(`FORGOT_PAGE.COMMON.BACK_TO_LOGIN`)}
        </button>
      </div>
    </div>
  )
};

export default ForgetPasswordPresentational;