function account(state = null, action) {
  switch (action.type) {
    case "SET_ACCOUNT":
      return action.payload;
    case "RESET_ACCOUNT":
      return null;
    default:
      return state;
  }
}

export default account;
