import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import notification from 'utils/notification';
import InspectionService from 'services/InspectionService';
import QucikNotesPresentational from './quick-notes-presentational';
import setLoader from 'utils/loader';

const QucikNotes = () => {
  const { t } = useTranslation();
  const [pageLoader, setPageLoader] = useState(true);
  const [currentTab, setCurrentTab] = useState({});
  const [inspaectionCategory, setInspaectionCategory] = useState([]);

  const [filterForm] = useState({
    page: 0,
    size: 0,
    isTemplateCategory: false,
  });

  const getInspectionMenu = useCallback(() => {
    setPageLoader(true);
    setLoader(true);
    InspectionService.getInspectionMenu(filterForm)
      .then((res) => {
        setPageLoader(false);
        setLoader(false);
        if (res?.data?.content) {
          setInspaectionCategory(res?.data?.content)
        } else if( parseInt(res.status, 0) === 204 ) {
          notification.info(t(`TOASTR.API.DATA_NOT_FOUND`))
        }
      })
      .catch((e) => {
        notification.error(e)
        setPageLoader(false);
        setLoader(false);
      })
  }, [t, filterForm]);

  useEffect(() => {
    getInspectionMenu();
  }, [getInspectionMenu]);

  return (
    <QucikNotesPresentational
      pageLoader={pageLoader}
      setPageLoader={setPageLoader}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      inspaectionCategory={inspaectionCategory}
    />
  )
}
 
export default QucikNotes;