import URL from "utils/config";

export const authUrl = {
  LOGIN: URL?.apiUserUrl + "/authenticate",
  LOGOUT: URL?.apiUserUrl + "/logout",
  REFRESH_TOKEN: URL?.apiUserUrl + "/newAccessToken",
  FORGET_PASSWORD: URL?.apiUserUrl + "/password",
  RESET_PASSWORD: URL?.apiUserUrl + "/password/reset",
};

export const userUrl = {
  CURRENT_USER: URL?.apiSessionUrl,
  USER: URL?.apiUserUrl,
  ATTACHMENT: URL?.attachmentUrl,
  ATTACHMENTS: URL?.attachmentUrls,
  NOTIFICATION: URL?.baseURL + "/notification",
};

export const inspectionUrl = {
  INSPECTION_URL: URL?.apiInspectionUrl,
  QUICK_NOTE: URL?.apiInspectionUrl + "/quick-note",
  EXTERIOR_CATRGORY: URL?.apiExteriorCategory,
  SUB_EXTERIOR_CATRGORY: URL?.apiExteriorSubCategory,
  INSPECTION: URL?.apiInspectionUrl,
  INSPECTION_REPORT: URL?.apiInspectionReportUrl,
  ATTACHMENT: URL?.attachmentUrl,
  ATTACHMENTS: URL?.attachmentUrls,
  MASTER_DATA: URL?.apiMasterData,
  TAX_PERCENTAGE: URL?.baseURL + "/tax-percentage",
  REPORT_VERIFY: URL?.baseURL + "/report-verify-verification-token",
  EMAIL_INSPECTION_REPORT: URL?.baseURL + "/email-inspection-report",
};

export const templateUrl = {
  TEMPLATE_KEY: URL?.baseURL + "/template-key",
  TEMPLATE_CONTENT: URL?.baseURL + "/template-content",
};

export const notificationUrl = {
  NOTIFICATION: URL?.baseURL + "/notification",
};

export const paymentUrl = {
  payment: URL?.baseURL + "/inspection-payment-state",
  CREATE_PAYMENT_TOKEN: URL?.paymentBaseURL + "/create-token",
  PAYMENT_CUS_DATA_URL: URL?.baseURL + "/verify-verification-token",
  INSPECTION_PAYMENT_URL: URL?.baseURL + "/inspection-payment",
};

export const dashboardUrl = {
  DATE_TYPE: URL?.baseURL + "/date-type",
};
