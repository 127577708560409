import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import NotificationService from "services/NotificationService";
import NotificationPresentational from "./notification-presentational";
import setLoader from "utils/loader";

const NotificationFunctional = () => {
  const { t } = useTranslation();
  const [notificationData, setNotificationData] = useState([]);

  // Start Get Client List
  const [filterForm, setFilterForm] = useState({
    size: 10,
    page: 0,
    total: 0,
    search: "",
    sort: "createdAt,desc",
    isFilter: true,
  });

  useEffect(() => {
    if (filterForm && filterForm.isFilter) {
      getNotificationList();
    }
  }, [filterForm]);

  const getNotificationList = (key = "") => {
    setLoader(true);
    let params = {
      ...filterForm,
    };
    NotificationService.getNotificationList(params)
      .then((res) => {
        setLoader(false);
        if (res?.data?.content) {
          setNotificationData(res.data.content);
          setFilterForm({
            ...filterForm,
            page: res.data.page.number,
            total: res.data.page.totalElements,
            isFilter: false,
          });
        }
      })
      .catch(() => {
        setLoader(false);
        setFilterForm({
          ...filterForm,
          isFilter: false,
        });
      });
  };

  return (
    <NotificationPresentational
      notificationData={notificationData}
      filterForm={filterForm}
      setFilterForm={setFilterForm}
    />
  );
};

export default NotificationFunctional;
