import store from "store/createStore";

const setLoader = (state = false) => {
  if (state === false) {
    setTimeout(() => {
      store?.dispatch({ type: "SET_LOADER", payload: state });
    }, 300);
  } else {
    store?.dispatch({ type: "SET_LOADER", payload: state });
  }
};
export default setLoader;
