import React from "react";
import { useTranslation } from "react-i18next";
import HTabs from "components/tabs/h-tabs";
import Button from "components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillTrendUp } from "@fortawesome/free-solid-svg-icons";
import NoDataFound from "components/NoDataFound";
import { paystation_logo } from "assets/images/images";

const PaymentPresentational = ({
  clientData,
  paymentCreateToken,
  btnLoading,
  clientCode,
}) => {
  const { t } = useTranslation();

  // Payment Create Token
  const paymentCreateTokenSubmit = () => {
    paymentCreateToken();
  };

  const tabs = [
    {
      tabTitle: t(`PAYMENT_PAGE.PAYMENT_DETAILS`),
      tabContent: (
        <div>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <div>
                <span className="font-bold">{t(`COMMON.NAME`)} :</span>{" "}
                {clientData?.client?.name?.firstName +
                  " " +
                  clientData?.client?.name?.lastName}
              </div>
              <div>
                <span className="font-bold">{t(`COMMON.EMAIL`)} :</span>{" "}
                {clientData?.client?.email}
              </div>
              <div>
                <span className="font-bold">{t(`COMMON.PHONE`)} :</span>{" "}
                {clientData?.client?.phoneNumber}
              </div>
              <div>
                <span className="font-bold">{t(`COMMON.ADDRESS`)} :</span>{" "}
                {clientData?.client?.address?.addressLine}
                {", "}
                {clientData?.client?.address?.landMark}
                {", "}
                {clientData?.client?.address?.city}
                {", "}
                {clientData?.client?.address?.zipCode}
                {"."}
              </div>
            </div>
            <div className="text-right">
              <div>
                <span className="font-bold">{t(`COMMON.INSPECTION_NO`)} :</span>{" "}
                {clientData?.inspectionNumber}
              </div>
              <div>
                <span className="font-bold">{t(`COMMON.CUSTOMER_NO`)} :</span>{" "}
                {clientData?.client?.clientNumber}
              </div>
              <div>
                <span className="font-bold">{t(`COMMON.AMOUNT`)} :</span> NZD{" "}
                {clientData?.netTotal}
              </div>
              <div>
                <span className="font-bold">
                  {t(`COMMON.PAYMENT_STATUS`)} :
                </span>{" "}
                {clientData?.paymentState}
              </div>
            </div>
          </div>
          <table className="border-collapse border border-slate-400 w-full text-right">
            <tbody>
              <tr>
                <td className="w-2/4 border border-slate-300 p-2 text-slate-500">
                  {t(`COMMON.TOTAL_AMOUNT`)}
                </td>
                <td className="w-2/4 border border-slate-300 p-2 text-slate-500 font-bold">
                  NZD {clientData?.total}
                </td>
              </tr>
              <tr>
                <td className="w-2/4 border border-slate-300 p-2 text-slate-500">
                  {t(`COMMON.TAX`)}({clientData?.taxPercent}%)
                </td>
                <td className="w-2/4 border border-slate-300 p-2 text-slate-500 font-bold">
                  NZD
                  {((clientData?.total * clientData?.taxPercent) / 100).toFixed(
                    2
                  )}
                </td>
              </tr>
              <tr>
                <td className="w-2/4 border border-slate-300 p-2 text-slate-500">
                  {t(`COMMON.GRAND_TOTAL`)}
                </td>
                <td className="w-2/4 border border-slate-300 p-2 text-slate-500 font-bold">
                  NZD {clientData?.netTotal}
                </td>
              </tr>
            </tbody>
          </table>
          {clientData?.paymentState != "PAID" && (
            <div className="grid justify-items-center text-center mt-5">
              <p className="font-bold">
                By clicking on Pay Now, you will be transferred to a secured
                payment gateway powered by{" "}
              </p>
              <img
                src={paystation_logo}
                alt={"Paystation Logo"}
                className="w-1/4"
              />
            </div>
          )}
        </div>
      ),
    },
  ];
  return (
    <>
      {clientData?.paymentState == "PAID" && (
        <div className="grid justify-items-center mb-5">
          <span class="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded border border-green-400 w-11/12 text-center">
            {t(`COMMON.REPORT_SEND_EMAIL`)}
          </span>
        </div>
      )}
      <div className="px-10 pb-10">
        {(clientData?.netTotal && (
          <HTabs
            tabsData={tabs}
            tabContentCSSClasses=""
            tabTitleEnd={
              (clientData?.paymentState != "PAID" && clientCode?.ec != 0 && (
                <Button
                  title={
                    (clientData?.netTotal >= 1 && t(`PAYMENT_PAGE.PAY_NOW`)) ||
                    "The minimum payment is NZ$1"
                  }
                  icon={
                    <FontAwesomeIcon
                      icon={faMoneyBillTrendUp}
                      className={"text-white text-xl"}
                    />
                  }
                  rounded={true}
                  spaceXSmall={true}
                  className="ml-auto text-sm mb-2"
                  isLoading={btnLoading}
                  onClick={() => {
                    clientData?.netTotal >= 1 && paymentCreateTokenSubmit();
                  }}
                />
              )) || (
                <Button
                  title={
                    clientData?.paymentState == "PAID"
                      ? clientData?.paymentState
                      : "Processing..."
                  }
                  rounded={true}
                  spaceXSmall={true}
                  className="ml-auto text-sm mb-2 bg-green-600 border-green-600"
                />
              )
            }
          />
        )) || (
          <div>
            <NoDataFound />
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentPresentational;
