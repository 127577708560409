import axios from "axios";
import { inspectionUrl } from "urls/urls";

const getQuickNotes = (params = {}) => {
  return axios.get(inspectionUrl?.QUICK_NOTE, { params });
};

const createQuickNote = (body) => {
  return axios.post(inspectionUrl?.QUICK_NOTE, body);
};

const updateQuickNote = (id, body) => {
  return axios.put(inspectionUrl?.QUICK_NOTE + `/${id}`, body);
};

const deleteQuickNote = (id) => {
  return axios.delete(inspectionUrl?.QUICK_NOTE + `/${id}`);
};

const QuickNoteService = {
  getQuickNotes,
  createQuickNote,
  updateQuickNote,
  deleteQuickNote,
};

export default QuickNoteService;
