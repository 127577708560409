import { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import validator from "utils/validator";
import Button from "components/Button";
import TextField from "components/form-fields/text-fields";
import FormErrMsg from "components/FormErrMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import Images from "assets/images/images";

const MyprofilePresentational = ({
  currentUserData,
  updateUser,
  btnLoading = false,
  imageUpload,
}) => {
  const imgUpload = useRef(null);
  const { t } = useTranslation();
  const {
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      firstName: currentUserData.name.firstName,
      lastName: currentUserData.name.lastName,
      email: currentUserData.email,
      phoneNumber: currentUserData.phoneNumber,
    },
    mode: "all",
  });

  const onFormSubmit = () => {
    trigger().then((res) => {
      if (res) {
        const formData = getValues();
        updateUser(formData);
      }
    });
  };

  const validatormsg = {
    firstName: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
    },
    lastName: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
    },
    email: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
      pattern: { value: validator.EMAIL, message: "VALIDATOR.PATTERN.EMAIL" },
    },
    phoneNumber: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
      minLength: { value: 8, message: "VALIDATOR.MINIMUM.8" },
    },
  };

  return (
    <div className="w-full flex justify-center">
      <div className="form-fields bg-gray-100 w-full md:w-4/5 border-2 px-2 md:px-32 py-2 md:py-10">
        <div className="relative flex justify-center">
          <input
            type="file"
            ref={imgUpload}
            accept="image/*"
            className="hidden"
            onChange={(e) => imageUpload(e)}
          />
          <img
            onClick={() => imgUpload.current.click()}
            className="w-20 md:w-28 rounded-full aspect-square object-cover cursor-pointer"
            src={currentUserData?.profileImage?.url || Images.UserAvatar}
            alt=""
          />
          <FontAwesomeIcon
            icon={faCamera}
            onClick={() => imgUpload.current.click()}
            className="text-[10px] md:text-[15px] p-1 md:p-1.5 bg-primary rounded-full text-white absolute bottom-0 right-[38%] md:right-[43%] cursor-pointer"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-{8px} mb-0"
            for="{t(`COMMON.FIRST_NAME`)}"
          >
            {t(`COMMON.FIRST_NAME`)}
          </label>
          <Controller
            control={control}
            name="firstName"
            rules={validatormsg?.firstName}
            render={({ field }) => (
              <TextField
                tabIndex="1"
                {...field}
                name="firstName"
                placeholder={t(`COMMON.FIRST_NAME`)}
                type="text"
                value={getValues("firstName")}
                onInput={(e) => {
                  setValue("firstName", e.target.value);
                  trigger("firstName");
                }}
                disabled={btnLoading}
              />
            )}
          />
          <FormErrMsg errors={errors?.firstName} />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-{8px} mb-0"
            for="{t(`COMMON.LAST_NAME`)}"
          >
            {t(`COMMON.LAST_NAME`)}
          </label>
          <Controller
            control={control}
            name="lastName"
            rules={validatormsg?.lastName}
            render={({ field }) => (
              <TextField
                tabIndex="1"
                {...field}
                name="lastName"
                placeholder={t(`COMMON.LAST_NAME`)}
                type="text"
                value={getValues("lastName")}
                onInput={(e) => {
                  setValue("lastName", e.target.value);
                  trigger("lastName");
                }}
                disabled={btnLoading}
              />
            )}
          />
          <FormErrMsg errors={errors?.lastName} />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-{8px} mb-0"
            for="{t(`COMMON.EMAIL`)}"
          >
            {t(`COMMON.EMAIL`)}
          </label>
          <Controller
            control={control}
            name="email"
            rules={validatormsg?.email}
            render={({ field }) => (
              <TextField
                tabIndex="1"
                {...field}
                name="email"
                placeholder={t(`COMMON.EMAIL`)}
                type="text"
                value={getValues("email")}
                onInput={(e) => {
                  setValue("email", e.target.value);
                  trigger("email");
                }}
                disabled={btnLoading}
              />
            )}
          />
          <FormErrMsg errors={errors?.email} />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-{8px} mb-0"
            for="{t(`COMMON.PHONE`)}"
          >
            {t(`COMMON.PHONE`)}
          </label>
          <Controller
            control={control}
            name="phoneNumber"
            rules={validatormsg?.phoneNumber}
            render={({ field }) => (
              <TextField
                tabIndex="1"
                {...field}
                name="phoneNumber"
                placeholder={t(`COMMON.PHONE`)}
                type="phoneNumber"
                value={getValues("phoneNumber")}
                onInput={(e) => {
                  setValue("phoneNumber", e.target.value);
                  trigger("phoneNumber");
                }}
                disabled={btnLoading}
              />
            )}
          />
          <FormErrMsg errors={errors?.phoneNumber} />
        </div>
        <div className="signin-button-div flex justify-center mt-8 mb-4 text-center">
          <Button
            title={t(`COMMON.UPDATE`)}
            isLoading={btnLoading}
            onClick={() => onFormSubmit()}
            className="mr-2"
          />
          {/* <Button type="" title={t(`COMMON.CANCEL`)} /> */}
        </div>
      </div>
    </div>
  );
};

export default MyprofilePresentational;
