import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InspectionService from "services/InspectionService";
import HTabs from "components/tabs/h-tabs";
import Button from "components/Button";
import notification from "utils/notification";
import setLoader from "utils/loader";

const InspectionPreview = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [inspectionReportData, setInspectionReportData] = useState("");
  const [clientData, setClientData] = useState([]);

  useEffect(() => {
    if (!params?.id) {
      navigate(`/clients`);
    }
    fetchInspectionPreviewData(params.id);
  }, []);

  // fetch inspection preview data if inspection id available
  const fetchInspectionPreviewData = (id) => {
    setLoader(true);
    const query = {
      contentDisposition: "INLINE",
    };
    InspectionService.getInspectionReport(id, query)
      .then(async (res) => {
        if (res?.data) {
          const blob = res?.data;
          if (blob) {
            fetchClientData(id);
            const urlObject = URL.createObjectURL(blob);
            setInspectionReportData(urlObject);
          }
          setLoader(false);
        }
      })
      .catch((e) => {
        notification.error(t(`${e}`));
        setLoader(false);
      });
  };

  // fetch inspection data if inspection id available
  const fetchClientData = async (id) => {
    setLoader(true);
    await InspectionService.getInspection(id).then((res) => {
      if (res?.data?.client) {
        let cdata = res?.data?.client;
        cdata.paymentState = res?.data?.paymentState;
        setClientData(cdata);
        setLoader(false);
      } else {
        setClientData([]);
        setLoader(false);
      }
    });
  };

  // Send report to email
  const sendReportToEmail = () => {
    setLoader(true);
    InspectionService.sendInspectionReportToEmail(params?.id)
      .then(async (res) => {
        // console.log(res);
        if (parseInt(res.status, 0) === 200) {
          notification.success(t(`COMMON.INSPECTION_SEND_TO_EMAIL`));
        }
        setLoader(false);
      })
      .catch((e) => {
        notification.error(t(`${e}`));
        setLoader(false);
      });
  };

  const clientName =
    clientData?.name && Object?.values(clientData?.name)?.join(" ")?.trim();
  // console.log("clientData", clientData);

  return (
    <>
      <HTabs
        tabsData={[
          {
            tabTitle:
              t(`INSPECTION_PAGE.PREVIEW.TITLE`) +
              " - " +
              (clientName ? clientName : " ") +
              (clientData?.clientNumber
                ? ` (${clientData?.clientNumber})`
                : " "),
            tabContent: (
              <iframe
                src={
                  inspectionReportData
                  // + (inspectionReportData ? "#toolbar=0" : "")
                }
                title={t(`INSPECTION_PAGE.PREVIEW.TITLE`)}
                className="w-full h-full"
              />
            ),
          },
        ]}
        tabTitleEnd={
          <div className="flex items-center ml-auto text-sm space-x-2">
            <a
              href={inspectionReportData}
              target="_blank"
              className="flex cursor-pointer py-1.5 disabled:cursor-not-allowed disabled:opacity-25 items-center justify-center w-auto bg-primary border border-primary text-white px-3 rounded mb-2"
            >
              {t(`COMMON.FULL_SCREEN`)}
            </a>
            <Button
              title={t(`COMMON.BACK`)}
              className="mb-2"
              spaceXSmall={true}
              onClick={() => navigate("/clients?tab=2")}
            />
            {clientData?.paymentState == "PAID" && (
              <Button
                title={t(`INSPECTION_PAGE.PREVIEW.REPORT_BUTTON`)}
                className="mb-2"
                spaceXSmall={true}
                onClick={() => {
                  sendReportToEmail();
                }}
              />
            )}
          </div>
        }
        tabContentCSSClasses="flex-1"
      />
    </>
  );
};

export default InspectionPreview;
