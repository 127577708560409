import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Button = ({
  type = "primary",
  title = "Add",
  icon = null,
  block = false,
  rounded = false,
  onClick = () => {},
  isLoading = false,
  className = "",
  spaceXSmall = false,
  disableBtnDefaultClasses = false,
  disabled = false,
  onClickDisabled = false,
  ...rest
}) => {
  const [tempDisable, setTempDisable] = useState(false);

  const onBtnClick = () => {
    if (!tempDisable) {
      if (onClickDisabled > 0) {
        setTempDisable(true);
        setTimeout(() => {
          setTempDisable(false);
        }, onClickDisabled);
      }
      onClick();
    }
  };

  let btnClasses =
    "flex cursor-pointer py-1.5 disabled:cursor-not-allowed disabled:opacity-25";

  if (block) {
    btnClasses += " flex flex-1 items-center justify-center";
  } else {
    btnClasses += " items-center justify-center w-auto";
  }

  if (type === "primary") {
    btnClasses += " bg-primary border border-primary text-white";
  } else {
    btnClasses += " bg-white border border-primary text-primary";
  }

  if (spaceXSmall) {
    btnClasses += " px-3";
  } else {
    btnClasses += " px-4 md:px-6";
  }

  if (rounded) {
    btnClasses += " rounded-full";
  } else {
    btnClasses += " rounded";
  }

  if (disableBtnDefaultClasses) {
    btnClasses = "";
  }

  return (
    <button
      {...rest}
      className={`${btnClasses} ${className}`}
      onClick={() => onBtnClick()}
      disabled={disabled || isLoading || tempDisable}
    >
      {isLoading ? (
        <div className="animate-spin">
          <FontAwesomeIcon icon={faSpinner} />
        </div>
      ) : (
        <div className="flex flex-row">
          {icon && <div className="flex mr-2 self-center">{icon}</div>}
          <span className={" block font-semibold"}>{title}</span>
        </div>
      )}
    </button>
  );
};

export default Button;
