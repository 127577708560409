import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import validator from "utils/validator";
import EmployeeCard from "components/card/employee-card";
import { Modal, Pagination } from "antd";
import HTabs from "components/tabs/h-tabs";
import Button from "components/Button";
import NoDataFound from "components/NoDataFound";
import TextField from "components/form-fields/text-fields";
import FormErrMsg from "components/FormErrMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";

const EmployeePresentational = ({
  employeeDataSource,
  filterForm,
  setFilterForm,
  submitEmployee,
  btnLoading,
  isModalOpen,
  setIsModalOpen,
  deleteEmployee,
}) => {
  const { t } = useTranslation();

  const {
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      id: null,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      role: "EMPLOYEE",
    },
    mode: "all",
  });

  const onFormSubmit = () => {
    trigger().then((res) => {
      if (res) {
        const formData = getValues();
        submitEmployee(formData);
      }
    });
  };

  const validatormsg = {
    firstName: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
    },
    lastName: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
    },
    email: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
      pattern: { value: validator.EMAIL, message: "VALIDATOR.PATTERN.EMAIL" },
    },
    phoneNumber: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
      minLength: { value: 8, message: "VALIDATOR.MINIMUM.8" },
    },
  };

  const onEditClick = (data) => {
    setValue("id", data?.userId);
    setValue("firstName", data?.name.firstName);
    setValue("lastName", data?.name.lastName);
    setValue("email", data?.email);
    setValue("phoneNumber", data?.phoneNumber);
    setValue("role", "EMPLOYEE");
    setIsModalOpen(true);
  };

  const { confirm } = Modal;
  const onDeleteClick = (userId) => {
    confirm({
      title: "Are you sure delete this employee?",
      icon: (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size={"xl"}
          className="float-left mr-4 text-red-600"
        />
      ),
      okText: t(`COMMON.YES`),
      okButtonProps: {
        className:
          "font-medium text-gray-600 hover:text-primary border-gray-300 hover:border-primary rounded",
      },
      okType: "danger",
      cancelText: t(`COMMON.CANCEL`),
      cancelButtonProps: {
        className:
          "font-medium rounded text-white bg-primary border border-primary hover:border-primary",
      },
      className: "rounded",
      onOk() {
        deleteEmployee(userId);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  const tabs = [
    {
      tabTitle: t(`COMMON.EMPLOYEE`),
      tabContent: (
        <>
          {(employeeDataSource.length >= 1 && (
            <div className="w-full justify-center">
              <div className="md:grid md:grid-cols-4 md:gap-4">
                {employeeDataSource.map((data) => {
                  return (
                    <EmployeeCard
                      key={data?.userId}
                      data={data}
                      onEditEmployee={onEditClick}
                      onDeleteEmployee={onDeleteClick}
                    />
                  );
                })}
                <div className="col-span-4 mt-10">
                  <Pagination
                    className="text-right"
                    pageSize={filterForm && filterForm.size}
                    hideOnSinglePage={true}
                    current={filterForm && filterForm.page + 1}
                    total={filterForm && filterForm.total}
                    onChange={(page) => {
                      setFilterForm({
                        ...filterForm,
                        page: page - 1,
                        isFilter: true,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )) || <NoDataFound />}
          {/* Start Modal */}
          <Modal
            title={t(`COMMON.EMPLOYEE`)}
            open={isModalOpen}
            okText="Submit"
            onOk={() => onFormSubmit()}
            onCancel={() => {
              setIsModalOpen(false);
              reset();
            }}
            okButtonProps={{
              disabled: btnLoading,
              className:
                "font-medium text-white hover:border-primary rounded bg-primary border border-primary",
            }}
            cancelButtonProps={{
              className:
                "font-medium hover:text-primary hover:border-primary rounded",
            }}
            centered
            width={600}
            isLoading={btnLoading}
          >
            <div className="grid gap-2 md:gap-4">
              <div className="">
                <label
                  className="block text-gray-700 text-{8px} mb-1"
                  for="{t(`COMMON.FIRST_NAME`)}"
                >
                  {t(`COMMON.FIRST_NAME`)}
                </label>
                <Controller
                  control={control}
                  name="firstName"
                  rules={validatormsg?.firstName}
                  render={({ field }) => (
                    <TextField
                      tabIndex="1"
                      {...field}
                      name="firstName"
                      placeholder={t(`COMMON.FIRST_NAME`)}
                      type="text"
                      value={getValues("firstName")}
                      onInput={(e) => {
                        setValue("firstName", e.target.value);
                        trigger("firstName");
                      }}
                      disabled={btnLoading}
                    />
                  )}
                />
                <FormErrMsg errors={errors?.firstName} />
              </div>

              <div className="">
                <label
                  className="block text-gray-700 text-{8px} mb-1"
                  for="{t(`COMMON.LAST_NAME`)}"
                >
                  {t(`COMMON.LAST_NAME`)}
                </label>
                <Controller
                  control={control}
                  name="lastName"
                  rules={validatormsg?.lastName}
                  render={({ field }) => (
                    <TextField
                      tabIndex="1"
                      {...field}
                      name="lastName"
                      placeholder={t(`COMMON.LAST_NAME`)}
                      type="text"
                      value={getValues("lastName")}
                      onInput={(e) => {
                        setValue("lastName", e.target.value);
                        trigger("lastName");
                      }}
                      disabled={btnLoading}
                    />
                  )}
                />
                <FormErrMsg errors={errors?.lastName} />
              </div>

              <div className="">
                <label
                  className="block text-gray-700 text-{8px} mb-1"
                  for="{t(`COMMON.EMAIL`)}"
                >
                  {t(`COMMON.EMAIL`)}
                </label>
                <Controller
                  control={control}
                  name="email"
                  rules={validatormsg?.email}
                  render={({ field }) => (
                    <TextField
                      tabIndex="1"
                      {...field}
                      name="email"
                      placeholder={t(`COMMON.EMAIL`)}
                      type="email"
                      value={getValues("email")}
                      onInput={(e) => {
                        setValue("email", e.target.value);
                        trigger("email");
                      }}
                      disabled={btnLoading}
                    />
                  )}
                />
                <FormErrMsg errors={errors?.email} />
              </div>

              <div className="">
                <label
                  className="block text-gray-700 text-{8px} mb-1"
                  for="{t(`COMMON.PHONE`)}"
                >
                  {t(`COMMON.PHONE`)}
                </label>
                <Controller
                  control={control}
                  name="phoneNumber"
                  rules={validatormsg?.phoneNumber}
                  render={({ field }) => (
                    <TextField
                      tabIndex="1"
                      {...field}
                      name="phoneNumber"
                      placeholder={t(`COMMON.PHONE`)}
                      type="phoneNumber"
                      value={getValues("phoneNumber")}
                      onInput={(e) => {
                        setValue("phoneNumber", e.target.value);
                        trigger("phoneNumber");
                      }}
                      disabled={btnLoading}
                    />
                  )}
                />
                <FormErrMsg errors={errors?.phoneNumber} />
              </div>
            </div>
          </Modal>
        </>
      ),
    },
  ];
  return (
    <HTabs
      tabsData={tabs}
      tabContentCSSClasses="flex-1"
      tabTitleEnd={
        <Button
          title={t(`COMMON.ADD`) + " " + t(`COMMON.EMPLOYEE`)}
          icon={
            <FontAwesomeIcon
              icon={faPlusCircle}
              className={"text-white text-xl"}
            />
          }
          rounded={true}
          spaceXSmall={true}
          className="ml-auto text-sm mb-2"
          onClick={() => {
            setIsModalOpen(true);
            reset();
          }}
        />
      }
    />
  );
};

export default EmployeePresentational;
