import React from "react";
import { useTranslation } from "react-i18next";
import HTabs from "components/tabs/h-tabs";

const ReportPresentational = ({ clientData, inspectionReportData }) => {
  const { t } = useTranslation();
  const deviceWidth = window.innerWidth;
  const clientName =
    clientData?.client?.name &&
    Object?.values(clientData?.client?.name)?.join(" ")?.trim();

  return (
    <>
      <div className="flex flex-col h-[calc(100vh-80px)] bg-[#F7F7F9] overflow-hidden px-10 pb-10">
        <HTabs
          tabsData={[
            {
              tabTitle:
                t(`COMMON.INSPECTION`) +
                " - " +
                (clientName ? clientName : " ") +
                (clientData?.client?.clientNumber
                  ? ` (${clientData?.client?.clientNumber})`
                  : " "),
              tabContent: (
                <>
                  {deviceWidth <= 640 ? (
                    <>
                      {clientData?.client?.clientNumber ? (
                        <a
                          href={inspectionReportData}
                          target="_blank"
                          class="block"
                        >
                          <div class="bg-gray-100 p-4 rounded-lg text-center shadow-md">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 inline-block mr-2 text-primary"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                              <polyline points="7 10 12 15 17 10"></polyline>
                              <line x1="12" y1="15" x2="12" y2="3"></line>
                            </svg>

                            <span class="text-gray-700 block font-bold mt-2 ">
                              {t(`COMMON.DOWNLOAD_INSPECTION`)}
                            </span>
                          </div>
                        </a>
                      ) : (
                        <div class="bg-gray-100 p-4 rounded-lg text-center shadow-md">
                          <span class="text-gray-700 block font-bold mt-2 ">
                            {t(`COMMON.NO_DATA_FOUND`)}
                          </span>
                        </div>
                      )}
                    </>
                  ) : (
                    <iframe
                      src={inspectionReportData}
                      className="w-full h-full"
                    />
                  )}
                </>
              ),
            },
          ]}
          // tabTitleEnd={
          //   <div className="flex items-center ml-auto text-sm space-x-2">
          //     <a
          //       href={inspectionReportData}
          //       target="_blank"
          //       className="flex cursor-pointer py-1.5 disabled:cursor-not-allowed disabled:opacity-25 items-center justify-center w-auto bg-primary border border-primary text-white px-3 rounded mb-2"
          //     >
          //       {t(`COMMON.DOWNLOAD`)}
          //     </a>
          //   </div>
          // }
          tabContentCSSClasses="flex-1"
        />
      </div>
    </>
  );
};

export default ReportPresentational;
