import React from "react";
import { useTranslation } from "react-i18next";
import HTabs from "components/tabs/h-tabs";
import DocumentTemplates from "./DocumentTemaples/document-templates-functional";
import QucikNotes from "./QuickNotes/quick-notes-functional";
import MaterialTypes from "./MaterialTypes/material-types-functional";

const TemplatesPresentational = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      tabTitle: t(`TEMPLATES_PAGE.DOCUMENT_TEMPLATE.TITLE`),
      tabContent: <DocumentTemplates />,
    },
    {
      tabTitle: t(`TEMPLATES_PAGE.QUICK_NOTE.TITLE`),
      tabContent: <QucikNotes />,
    },
    {
      tabTitle: t(`TEMPLATES_PAGE.MATERIAL_TYPE.TITLE`),
      tabContent: <MaterialTypes />,
    },
  ];

  return <HTabs tabsData={tabs} onClickLoad={true} />;
};

export default TemplatesPresentational;
