import { Input } from "antd";
import React, { forwardRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const PasswordFields = forwardRef(
  (
    {
      className = "form-control",
      defaultClassName = "",
      value,
      placeholder = "Enter Password...",
      name,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    const [hidden, setHidden] = useState(true);
    const toggleShow = () => {
      setHidden(!hidden);
    };

    if( ! defaultClassName ) {
      defaultClassName = "px-3 py-1.5 shadow-none border-typo-light focus-within:ring-1 focus-within:border-primary focus-within:ring-primary"
    }

    return (
      <>
        <Input
          ref={ref}
          {...rest}
          name={name}
          type={hidden === true ? "password" : "text"}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          className={
            defaultClassName +
            " " +
            className
          }
          suffix={
            disabled === true ? (
              <></>
            ) : (
              <span className="password__show" onClick={toggleShow}>
                {hidden === true ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </span>
            )
          }
        />
      </>
    );
  }
);
export default PasswordFields;
