let baseURL =
  "https://inspection.honeycombconstruction.co.nz/honeycomb-inspection/api/v1";
let paymentBaseURL =
  "https://inspection.honeycombconstruction.co.nz/index.php/api";

if (process.env.BASE_URL) {
  baseURL = process.env.BASE_URL;
}

const apiUserUrl = `${baseURL}/user`;
const apiSessionUrl = `${baseURL}/session/user`;
const attachmentUrl = `${baseURL}/attachment`;
const attachmentUrls = `${baseURL}/attachments`;
const apiInspectionUrl = `${baseURL}/inspection`;
const apiInspectionReportUrl = `${baseURL}/inspection-report`;
const apiExteriorCategory = `${baseURL}/exterior-category`;
const apiExteriorSubCategory = `${baseURL}/exterior-sub-category`;
const apiMasterData = `${baseURL}/master-data`;
const dateTypeUrl = `${baseURL}/date-type`;

const URL = {
  baseURL,
  paymentBaseURL,
  apiUserUrl,
  apiSessionUrl,
  attachmentUrl,
  apiInspectionUrl,
  apiInspectionReportUrl,
  apiExteriorCategory,
  apiExteriorSubCategory,
  apiMasterData,
  dateTypeUrl,
  attachmentUrls,
};
export default URL;
